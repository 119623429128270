define("ember-cli-sketch/util/math", ["exports", "ember-cli-sketch/-private/util/math"], function (_exports, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "millimetersToPixels", {
    enumerable: true,
    get: function get() {
      return _math.millimetersToPixels;
    }
  });
  Object.defineProperty(_exports, "round", {
    enumerable: true,
    get: function get() {
      return _math.round;
    }
  });
});