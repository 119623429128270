define("ember-cli-zuglet/-private/util/destroyable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.cacheFor = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var __key__ = '__zuglet_destroyable_computed__';

  var __get = function __get(owner) {
    return owner.willDestroy[__key__];
  };

  var _cache = function _cache(owner, create) {
    if (!owner) {
      return;
    }

    if (!owner.willDestroy) {
      return;
    }

    var object = __get(owner);

    if (!object && create) {
      object = Object.create(null);
      var willDestroy = owner.willDestroy;

      owner.willDestroy = function () {
        var object = __get(owner);

        for (var key in object) {
          var _object$key = object[key],
              value = _object$key.value,
              destroy = _object$key.destroy;
          destroy(value);
        }

        willDestroy.apply(owner, arguments);
      };

      owner.willDestroy[__key__] = object;
    }

    return object;
  };

  var _retrieve = function _retrieve(owner, key) {
    var cache = _cache(owner, false);

    if (!cache) {
      return {};
    }

    var value = cache[key];

    if (!value) {
      value = {};
    }

    return value;
  };

  var _store = function _store(owner, key, value, destroy) {
    var cache = _cache(owner, true);

    cache[key] = {
      value: value,
      destroy: destroy
    };
  };

  var _destroy = function _destroy(owner, key, value, destroy) {
    var cache = _cache(owner);

    delete cache[key];
    destroy(value);
  };

  var _get = function _get(owner, key, opts) {
    var _retrieve2 = _retrieve(owner, key),
        value = _retrieve2.value,
        destroy = _retrieve2.destroy;

    if (value && !opts.reusable.call(owner, value, key)) {
      _destroy(owner, key, value, destroy);

      value = null;
    }

    if (!value) {
      value = opts.create.call(owner, key);

      if (value) {
        _store(owner, key, value, opts.destroy);
      }
    } else {
      opts.reuse.call(owner, value, key);
    }

    return value;
  };

  var cacheFor = function cacheFor(owner, key) {
    return _retrieve(owner, key).value;
  };

  _exports.cacheFor = cacheFor;

  var defaultReuse = function defaultReuse() {};

  var defaultGet = function defaultGet(internal) {
    return internal;
  };

  var defaultDestroy = function defaultDestroy(internal) {
    return internal.destroy();
  }; // { ...keys, opts: { reusable, create, get, set, destroy } }


  var _default = function _default() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var opts = args.pop();
    var keys = Ember.A(args).compact();
    opts.get = opts.get || defaultGet;
    opts.destroy = opts.destroy || defaultDestroy;
    opts.reuse = opts.reuse || defaultReuse;

    var get = function get(key) {
      var internal = _get(this, key, opts);

      if (!internal) {
        return;
      }

      return opts.get.call(this, internal, key);
    };

    var set;

    if (opts.set) {
      set = function set(key, value) {
        var internal = _get(this, key, opts);

        if (!internal) {
          return;
        }

        return opts.set.call(this, internal, value, key);
      };
    }

    var prop = (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(keys).concat([{
      get: get,
      set: set
    }]));

    if (!set) {
      prop = prop.readOnly();
    }

    return prop;
  };

  _exports.default = _default;
});