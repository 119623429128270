define("ember-cli-sketch/-private/node/edge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var calculate = function calculate(local, bounds, zoom) {
    var edge = {};
    var offset = 15 / zoom;

    if (local.x > -offset && local.x < offset) {
      edge.horizontal = 'left';
    } else if (local.x > bounds.width - offset && local.x < bounds.width + offset) {
      edge.horizontal = 'right';
    } else if (local.x > bounds.width / 2 - offset && local.x < bounds.width / 2 + offset) {
      edge.horizontal = 'middle';
    }

    if (local.y > -offset && local.y < offset) {
      edge.vertical = 'top';
    } else if (local.y > bounds.height - offset && local.y < bounds.height + offset) {
      edge.vertical = 'bottom';
    } else if (local.y > bounds.height / 2 - offset && local.y < bounds.height / 2 + offset) {
      edge.vertical = 'middle';
    }

    if (!edge.horizontal || !edge.vertical) {
      return;
    }

    if (edge.horizontal === 'middle' && edge.vertical === 'middle') {
      return;
    }

    return edge;
  };

  var prop = function prop(key) {
    return Ember.computed.readOnly("serialized.".concat(key));
  };

  var _default = Ember.Object.extend({
    point: Ember.computed.readOnly('node.stage.interactions.mouse.absolute'),
    zoom: Ember.computed.readOnly('node.stage.zoom'),
    serialized: Ember.computed('point', 'node.frame.absolute', 'zoom', function () {
      var point = this.point;

      if (!point) {
        return;
      }

      var zoom = this.zoom,
          _this$node = this.node,
          frame = _this$node.frame,
          absolute = _this$node.frame.absolute;

      if (!absolute) {
        return;
      }

      var rotated = frame.rotatedPosition(point, 'absolute');
      var local = frame.convertPoint(rotated, 'absolute');
      return calculate(local, absolute, zoom);
    }).readOnly(),
    has: Ember.computed.bool('serialized'),
    horizontal: prop('horizontal'),
    vertical: prop('vertical')
  });

  _exports.default = _default;
});