define("design/components/ui-input/text/component", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    classNameBindings: [':ui-input-text', '_size', '_color', '_valid', '_border', 'disabled:disabled:enabled'],
    attributeBindings: ['placeholder', 'value', 'autofocus', 'spellcheck', 'autocapitalize', 'autocorrect', 'type', 'disabled', 'autocomplete'],
    placeholder: null,
    value: null,
    autofocus: false,
    spellcheck: false,
    autocapitalize: 'off',
    autocorrect: 'off',
    autocomplete: 'off',
    type: 'text',
    disabled: false,
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    _border: (0, _computed.className)({
      key: 'border',
      value: 'regular'
    }),
    // `type` is taken
    _valid: (0, _computed.validationClassName)(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.focus) {
        this.element.focus();
      }
    },
    input: function input(e) {
      this._update(e, false);
    },
    focusOut: function focusOut(e) {
      this._update(e, true);
    },
    keyPress: function keyPress(e) {
      if (e.keyCode == 13 || e.which == 13) {
        this.enter && this.enter();
      }
    },
    _update: function _update(e, focus) {
      var value = e.target.value;

      if (value === String(this.value)) {
        return;
      }

      this.update && this.update(value, focus);
    }
  });

  _exports.default = _default;
});