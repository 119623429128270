define("design/models/validation/validator/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Object.extend({
    validation: null,
    object: Ember.computed.readOnly('validation.owner'),
    key: null,
    opts: null,
    value: null,
    isRequired: Ember.computed.readOnly('opts.required'),
    isValid: Ember.computed.readOnly('_result.valid'),
    isBlank: Ember.computed.readOnly('_result.blank'),
    error: Ember.computed.readOnly('_result.error'),
    isTouched: false,
    actions: {
      update: function update(value) {
        this.update(value);
      }
    },
    update: function update(value) {
      var key = this.key,
          object = this.object;
      object.set(key, value);
      this.touch();
    },
    touch: function touch() {
      this.set('isTouched', true);
    },
    validate: function validate() {},
    _validate: function _validate(value) {
      var opts = this.opts,
          validate = this.opts.validate,
          object = this.object;
      var result = Ember.assign({
        valid: false,
        blank: false,
        error: null
      }, this.validate(value, opts));

      if (result.valid && validate) {
        result = Ember.assign(result, validate.call(object, value, object, opts));
      }

      return result;
    },
    prepare: function prepare(_ref) {
      var _Ember;

      var validation = _ref.validation,
          key = _ref.key,
          opts = _ref.opts;
      this.setProperties({
        validation: validation,
        key: key,
        opts: opts
      });
      Ember.defineProperty(this, 'value', Ember.computed.readOnly("object.".concat(key)));
      Ember.defineProperty(this, '_result', (_Ember = Ember).computed.apply(_Ember, ['value'].concat(_toConsumableArray(Ember.A(opts.deps).map(function (key) {
        return "object.".concat(key);
      })), [function () {
        return this._validate(this.value);
      }])).readOnly());
    },
    toStringExtension: function toStringExtension() {
      var key = this.key;
      return "".concat(key);
    }
  });

  _exports.default = _default;
});