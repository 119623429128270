define("ember-cli-zuglet/-private/observers/observers", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, Ember.Array, {
    length: Ember.computed.readOnly('_internal.observers.length'),
    objectAt: function objectAt(idx) {
      var internal = this._internal.get('observers').objectAt(idx);

      return internal && internal.model(true);
    },
    promise: Ember.computed.readOnly('_internal.promise')
  });

  _exports.default = _default;
});