define("ember-cli-zuglet/-private/queue/operation/promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    queue: null,
    owner: null,
    opts: null,
    promise: Ember.computed(function () {
      return Ember.RSVP.resolve(this.get('opts.promise'));
    }).readOnly(),
    invoke: function invoke() {
      return this.get('promise');
    }
  });

  _exports.default = _default;
});