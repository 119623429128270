define("ember-cli-zuglet/-private/data/timestamp/timestamp", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/data/internal/model-mixin"], function (_exports, _modelMixin, _modelMixin2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, _modelMixin2.default, {
    isTimestamp: Ember.computed.readOnly('_internal.isTimestamp'),
    isServerTimestamp: Ember.computed.readOnly('_internal.isServerTimestamp'),
    date: Ember.computed.readOnly('_internal.date'),
    dateTime: Ember.computed.readOnly('_internal.dateTime')
  });

  _exports.default = _default;
});