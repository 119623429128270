define("ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65vnnded",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"top\",\"left\",[24,[\"edge\"]],[24,[\"isBothDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"top\",\"right\",[24,[\"edge\"]],[24,[\"isBothDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"bottom\",\"left\",[24,[\"edge\"]],[24,[\"isBothDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"bottom\",\"right\",[24,[\"edge\"]],[24,[\"isBothDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"top\",\"middle\",[24,[\"edge\"]],[24,[\"isHeightDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"bottom\",\"middle\",[24,[\"edge\"]],[24,[\"isHeightDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"middle\",\"left\",[24,[\"edge\"]],[24,[\"isWidthDisabled\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections/selection/marker\",null,[[\"vertical\",\"horizontal\",\"edge\",\"disabled\"],[\"middle\",\"right\",[24,[\"edge\"]],[24,[\"isWidthDisabled\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isEditableVisible\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/stage/selections/selection/edit\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/template.hbs"
    }
  });

  _exports.default = _default;
});