define("ember-cli-sketch/-private/node/nodes", ["exports", "ember-cli-sketch/-private/node/frame/-base", "ember-cli-sketch/-private/util/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typed = _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var typed = function typed(type) {
    return (0, _computed.factory)(function (factory, nodes) {
      return factory.typedNodes(type, nodes);
    });
  };

  _exports.typed = typed;

  var nodes = function nodes(key) {
    return Ember.computed.map(key, function (model) {
      return model.node;
    });
  };

  var _default = Ember.Object.extend({
    parent: null,
    stage: Ember.computed.readOnly('parent.stage'),
    all: nodes('parent._models'),
    recursive: Ember.computed('all.@each.recursive', function () {
      return this.all.reduce(function (nested, node) {
        nested.push.apply(nested, _toConsumableArray(node.recursive));
        return nested;
      }, Ember.A());
    }).readOnly(),
    visible: Ember.computed.filterBy('all', 'isVisible', true),
    selectable: Ember.computed.filterBy('all', 'isSelectable', true),
    frame: (0, _base.frame)('nodes'),
    containers: typed('containers'),
    nodesForAbsolutePosition: function nodesForAbsolutePosition(position) {
      return this.selectable.reduce(function (nodes, node) {
        var rotated = node.frame.rotatedPosition(position, 'absolute');

        if (node.frame.containsPosition(rotated, 'absolute')) {
          nodes.push(node);
        }

        nodes.push.apply(nodes, _toConsumableArray(node.nodes.nodesForAbsolutePosition(position)));
        return nodes;
      }, Ember.A());
    },
    containsNode: function containsNode(node) {
      return this.all.find(function (child) {
        return child === node || child.containsNode(node);
      });
    }
  });

  _exports.default = _default;
});