define("ember-cli-sketch/-private/node/guidelines", ["exports", "ember-cli-sketch/-private/util/sketches", "ember-cli-sketch/-private/util/array"], function (_exports, _sketches, _array2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.points = _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _array = function _array(owner, key) {
    var array = owner[key];

    if (!array) {
      array = Ember.A();
      owner[key] = array;
    }

    return array;
  };

  var points = function points() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var fn = args.pop();
    return (_Ember = Ember).computed.apply(_Ember, ['frame'].concat(args, [function () {
      return fn.call(this, this.frame);
    }])).readOnly();
  };

  _exports.points = points;

  var _default = Ember.Object.extend({
    approx: 5,
    node: null,
    opts: null,
    stage: Ember.computed.readOnly('node.stage'),
    zoom: Ember.computed.readOnly('stage.zoom'),
    frame: Ember.computed.readOnly("node.frame.guidelines"),
    pairs: Ember.computed('node', 'node.stage.recursive.@each.isVisible', function () {
      var _this = this;

      var source = this.node;
      var objects = source.stage.recursive.filter(function (target) {
        return target !== source && target.isVisible;
      });

      var array = _array(this, '_pairs');

      var factory = (0, _sketches.default)(this).factory;
      (0, _array2.diff)({
        array: array,
        objects: objects,
        find: function find(target) {
          return array.findBy('target', target);
        },
        create: function create(target) {
          return factory.guidelinesPair(_this, source, target);
        },
        destroy: function destroy(pair) {
          return pair.destroy();
        }
      });
      return array;
    }).readOnly(),
    matched: Ember.computed('pairs.@each.matched', function () {
      var array = [];
      this.pairs.forEach(function (pair) {
        var matched = pair.matched;

        if (!matched) {
          return;
        }

        array.push.apply(array, _toConsumableArray(matched));
      });
      return array;
    }).readOnly(),
    snapping: function snapping() {
      var matched = this.matched;
      var resolved = {
        horizontal: 0,
        vertical: 0
      };
      matched.forEach(function (guideline) {
        var direction = guideline.direction,
            approx = guideline.approx,
            delta = guideline.delta;

        if (approx) {
          resolved[direction] = Math.min(resolved[direction], delta);
        } else {
          resolved[direction] = 0;
        }
      });
      var props;

      var snap = function snap(direction, prop) {
        var delta = resolved[direction];

        if (delta) {
          props = props || {};
          props[prop] = delta;
        }
      };

      snap('horizontal', 'y');
      snap('vertical', 'x');
      return props;
    }
  });

  _exports.default = _default;
});