define("ember-cli-sketch/components/ui-block/sketch/stage/content/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/content/template", "ember-cli-sketch/components/ui-block/sketch/stage/-computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-sketch-stage-content'],
    attributeBindings: ['style'],
    frame: (0, _computed.frame)('stage', 'rounded'),
    style: (0, _computed.style)('frame', function (_ref) {
      var frame = _ref.frame;
      return frame;
    })
  });

  _exports.default = _default;
});