define("ember-cli-sketch/-private/node/-base", ["exports", "ember-cli-sketch/-private/node/frame/-base", "ember-cli-sketch/-private/util/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "frame", {
    enumerable: true,
    get: function get() {
      return _base.frame;
    }
  });

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var keys = Object.keys;

  var factory = function factory(name, opts) {
    return function () {
      return (0, _computed.factory)(function (factory, node) {
        return factory[name].call(factory, node, opts);
      });
    };
  };

  var nodes = factory('nodes');
  var edge = factory('edge');

  var parent = function parent(prop, key) {
    return Ember.computed("parent.{".concat(prop, ",").concat(key, "}"), function () {
      var parent = this.get('parent');

      if (!parent) {
        return;
      }

      if (parent[prop]) {
        return parent;
      }

      return parent[key];
    }).readOnly();
  };

  var prop = function prop(key, defaultValue) {
    return Ember.computed(function () {
      var value = this.opts[key];

      if (value === undefined) {
        value = defaultValue;
      }

      return value;
    }).readOnly();
  };

  var included = function included(arrayKey) {
    return Ember.computed("".concat(arrayKey, ".[]"), function () {
      var array = this.get(arrayKey);

      if (!array) {
        return;
      }

      return array.includes(this);
    }).readOnly();
  };

  var _default = function _default(opts) {
    var attributes = function attributes() {
      return (0, _computed.factory)(function (factory, node) {
        var identifier = opts.identifier;
        var type = node.type;
        return factory.attributes(node, {
          identifier: identifier,
          type: type
        });
      });
    };

    var value = function value(key, defaultValue) {
      var path = "model.".concat(opts.properties[key]);
      return Ember.computed(path, function () {
        var value = this.get(path);

        if (value === undefined) {
          value = defaultValue;
        }

        return value;
      }).readOnly();
    };

    var isEditing = function isEditing() {
      return Ember.computed('stage.tools.selected.{type,node}', function () {
        var tool = this.stage.tools.selected;
        return tool.type === 'node/edit' && tool.node === this;
      }).readOnly();
    };

    return Ember.Object.extend({
      model: null,
      _stage: value('stage'),
      _parent: value('parent'),
      _models: value('nodes'),
      _visible: value('visible'),
      _selectable: value('selectable', true),
      type: value('type'),
      aspect: Ember.computed.readOnly('model.aspect'),
      accessories: Ember.computed.readOnly('model.accessories'),
      guidelines: Ember.computed.readOnly('model.guidelines'),
      isContainer: prop('container', false),
      isAttached: Ember.computed.bool('parent'),
      isVisible: Ember.computed.and('isAttached', '_visible', 'parent.isVisible'),
      isEditing: isEditing(),
      isNotEditing: Ember.computed.not('isEditing'),
      isSelectable: Ember.computed.and('isAttached', 'isVisible', 'isNotEditing', '_selectable', 'parent.isSelectable'),
      isEditable: Ember.computed.and('model.isEditable', 'isSelectable'),
      parent: Ember.computed.readOnly('_parent.node'),
      stage: parent('isStage', 'stage'),
      container: parent('isContainer', 'container'),
      nodes: nodes(),
      attributes: attributes(),
      edge: edge(),
      _guidelines: Ember.computed.readOnly('guidelines.matched'),
      _rotatedFrame: Ember.computed.readOnly('frame.rotated'),
      _hasEdge: Ember.computed.readOnly('edge.has'),
      recursive: Ember.computed('nodes.recursive', function () {
        var recursive = this.nodes.recursive;
        return [this].concat(_toConsumableArray(recursive));
      }).readOnly(),
      index: Ember.computed('parent.nodes.all.[]', function () {
        var nodes = this.get('parent.nodes.all');

        if (!nodes) {
          return;
        }

        return nodes.indexOf(this) + 1;
      }).readOnly(),
      isSelected: included('stage.selection.all'),
      isHovered: included('stage.hover.all'),
      containsNode: function containsNode(node) {
        return this.nodes.containsNode(node);
      },
      _update: function _update(props) {
        (false && !(!!this.model.update) && Ember.assert("update is required for ".concat(this.model), !!this.model.update));
        this.model.update(props);
      },
      update: function update(props, opts) {
        if (!props) {
          return;
        }

        var _Ember$assign = Ember.assign({
          delta: false
        }, opts),
            delta = _Ember$assign.delta;

        if (delta) {
          props = this.frame.deltaToFrame(props);
        }

        props = this.attributes.changes(props);

        if (!keys(props)) {
          return;
        }

        this._update(props);
      },
      action: function action(name) {
        return this.sketches.actions.action(this, name);
      },
      perform: function perform(name) {
        var _this$sketches$action;

        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return (_this$sketches$action = this.sketches.actions).perform.apply(_this$sketches$action, [this, name].concat(args));
      },
      hide: function hide() {
        this.update({
          visible: false
        });
      },
      show: function show() {
        this.update({
          visible: true
        });
      },
      remove: function remove() {
        (false && !(!!this.model.remove) && Ember.assert("remove is required for ".concat(this.model), !!this.model.remove));
        this.model.remove();
      },
      //
      deselect: function deselect() {
        this.stage.selection.removeNode(this);
      },
      select: function select(opts) {
        var _this = this;

        var _Ember$assign2 = Ember.assign({
          replace: true,
          toggle: false
        }, opts),
            replace = _Ember$assign2.replace,
            toggle = _Ember$assign2.toggle;

        var selection = this.stage.selection;
        var selected = this.isSelected;

        if (replace) {
          if (toggle && selected) {
            selection.clear();
          } else {
            selection.replace([this]);
          }
        } else {
          if (toggle && selected) {
            selection.removeNode(this);
          } else {
            var _nodes = [].concat(_toConsumableArray(selection.filter(function (sel) {
              return _this.containsNode(sel);
            })), _toConsumableArray(selection.filter(function (sel) {
              return sel.containsNode(_this);
            })));

            selection.removeNodes(_nodes);
            selection.addNode(this);
          }
        }

        this.stage.focus();
      },
      hover: function hover() {
        var _Ember$assign3 = Ember.assign({
          replace: true
        }, opts),
            replace = _Ember$assign3.replace;

        var hover = this.stage.hover;

        if (replace) {
          hover.replace([this]);
        } else {
          hover.addNode(this);
        }
      },
      //
      _withMoveToParent: function _withMoveToParent(parent, cb) {
        var absolute = this.frame.absolute;
        var frame = parent.frame.convertFrameFromAbsolute(absolute);
        cb();
        this.update(frame);
      },
      moveTo: function moveTo(target) {
        var _this2 = this;

        (false && !(!!this.model.move) && Ember.assert("move is required for ".concat(this.model), !!this.model.move));
        var model = null;

        if (target && !target.isStage) {
          model = target.model;
        }

        this._withMoveToParent(target, function () {
          _this2.model.move(model);
        });
      },
      _replace: function _replace(next) {
        if (!next || next === this) {
          return;
        }

        var parent = this.parent;

        if (!parent) {
          return;
        }

        (false && !(!!parent.model.replace) && Ember.assert("replace is required for ".concat(parent), !!parent.model.replace));
        parent.model.replace(this.model, next.model);
      },
      moveToTop: function moveToTop() {
        var last = this.parent.nodes.all.lastObject;

        this._replace(last);
      },
      _moveWithDelta: function _moveWithDelta(delta) {
        var nodes = this.parent.nodes.all;
        var index = nodes.indexOf(this);
        var next = nodes.objectAt(index + delta);

        this._replace(next);
      },
      moveDown: function moveDown() {
        this._moveWithDelta(-1);
      },
      moveUp: function moveUp() {
        this._moveWithDelta(+1);
      }
    });
  };

  _exports.default = _default;
});