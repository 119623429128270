define("design/components/ui-input/dropdown/component", ["exports", "design/components/ui-input/dropdown/template", "design/util/computed", "design/mixins/click-outside"], function (_exports, _template, _computed, _clickOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutside.default, {
    layout: _template.default,
    classNameBindings: [':ui-input-dropdown', '_size', '_type', 'disabled:disabled'],
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    expanded: false,
    disabled: false,
    visibleItems: Ember.computed('items.@each.hidden', function () {
      var items = this.items;
      return Ember.A(Ember.A(items).filter(function (item) {
        return item.hidden !== true;
      }));
    }).readOnly(),
    actions: {
      toggle: function toggle() {
        if (this.disabled) {
          return;
        }

        this.toggleProperty('expanded');
      },
      select: function select(item) {
        if (this.disabled) {
          return;
        }

        if (this.selected !== item && this.select) {
          this.select(item);
        }

        this.set('expanded', false);
      }
    },
    onClickOutside: function onClickOutside() {
      this.set('expanded', false);
    }
  });

  _exports.default = _default;
});