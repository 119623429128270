define("ember-cli-zuglet/-private/reference/query/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/reference/queryable/internal-mixin"], function (_exports, _internal, _internalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend(_internalMixin.default, {
    store: null,
    ref: null,
    _parent: null,
    info: null,
    parent: Ember.computed.readOnly('_parent'),
    type: Ember.computed.readOnly('info.name'),
    args: Ember.computed.readOnly('info.args'),
    string: Ember.computed(function () {
      var parent = this.get('parent.string');
      var info = this.get('info');
      var string = info.formatter.string(info);

      if (parent) {
        return "".concat(parent, ".").concat(string);
      }

      return string;
    }).readOnly(),
    serialized: Ember.computed(function () {
      var arr = this.get('parent.serialized') || [];
      var info = this.get('info');
      arr.push(info.formatter.object(info));
      return arr;
    }),
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:reference/query').create({
        _internal: this
      });
    }
  });

  _exports.default = _default;
});