define("design/components/ui-block/dialogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tjEG3M3t",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui-block-dialogs\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"background\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"dialog\"],[8],[0,\"\\n        \"],[7,\"div\",false],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"ui-block/icon\",[\"times-circle\"],null],false],[9],[0,\"\\n        \"],[1,[28,\"component\",[[24,[\"model\",\"componentName\"]]],[[\"dialog\"],[[24,[\"model\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/dialogs/template.hbs"
    }
  });

  _exports.default = _default;
});