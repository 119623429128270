define("ember-cli-sketch/components/ui-block/sketch/stage/node/image/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/node/-component", "ember-cli-sketch/components/ui-block/sketch/stage/node/image/template", "ember-cli-sketch/components/ui-block/sketch/stage/node/-computed"], function (_exports, _component, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNameBindings: ['task.status', 'content'],
    content: Ember.computed('model.fit', function () {
      var fit = this.model.fit;

      if (fit) {
        return 'content-fit';
      } else {
        return 'content-fill';
      }
    }).readOnly(),
    style: (0, _computed.style)('model.opacity', function () {
      var opacity = this.model.opacity;
      return {
        opacity: opacity
      };
    }),
    task: Ember.computed.readOnly('model.task')
  });

  _exports.default = _default;
});