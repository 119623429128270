define("ember-cli-zuglet/-private/data/array/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer", "ember-cli-zuglet/-private/data/internal/util"], function (_exports, _serializer, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isArray = function isArray(value) {
    return Ember.typeOf(value) === 'array';
  };

  var _default = _serializer.default.extend({
    supports: function supports(value) {
      return isArray(value);
    },
    matches: function matches(internal, value) {
      return isArray(value);
    },
    createInternal: function createInternal(props) {
      var internal = this.factoryFor('zuglet:data/array/internal').create({
        serializer: this
      });
      this.deserialize(internal, props);
      return internal;
    },
    deserialize: function deserialize(internal, array) {
      array = Ember.A(array);
      var manager = this.manager;
      var content = internal.content;
      var remaining = Ember.A(content.slice());

      var reusable = function reusable(item) {
        var found = remaining.find(function (value) {
          return value.serializer.matches(value, item);
        });

        if (found) {
          remaining.removeObject(found);
        }

        return found;
      };

      var internals = Ember.A(array.map(function (item) {
        var nested = reusable(item);

        if (nested) {
          var result = nested.serializer.deserialize(nested, item);
          nested = result.internal;
        } else {
          nested = manager.createInternal(item);
        }

        if (nested) {
          nested.attach(internal);
        }

        return nested;
      }));
      var addAmt = internals.get('length');
      var removeAmt = content.get('length');
      var remainingAmt = remaining.get('length');
      remaining.map(function (item) {
        return item.detach();
      });

      if (remainingAmt > 0 || addAmt !== removeAmt) {
        // TODO: this can be improved. Instead of replace all which makes all lifecycle models to be recreated, remove add one by one.
        internal.withArrayContentChanges(true, function (builder) {
          return builder(0, addAmt, removeAmt, function () {
            content.replace(0, removeAmt, internals);
          });
        });
      }

      return {
        replace: false,
        internal: internal
      };
    },
    getModelValue: function getModelValue(internal, idx) {
      internal = internal.content.objectAt(idx);
      return (0, _util.toModel)(internal);
    },
    replaceModelValues: function replaceModelValues(internal, idx, removeAmt, array) {
      var manager = this.manager;
      var content = internal.content;
      var addAmt = Ember.A(array).get('length');
      internal.withArrayContentChanges(true, function (builder) {
        return builder(idx, addAmt, removeAmt, function () {
          var removing = content.slice(idx, removeAmt);
          removing.map(function (item) {
            return item.detach();
          });
          var adding = array.map(function (item) {
            var created = manager.createInternal(item);

            if (created) {
              created.attach(internal);
            }

            return created;
          });
          content.replace(idx, removeAmt, adding);
        });
      });
    },
    serialize: function serialize(internal, type) {
      return internal.content.map(function (value) {
        return value.serialize(type);
      });
    },
    isDirty: function isDirty(internal, value) {
      var values = internal.content;
      value = Ember.A(value);

      if (values.get('length') !== value.get('length')) {
        return true;
      }

      return values.find(function (internal, idx) {
        return internal.serializer.isDirty(internal, value.objectAt(idx));
      });
    }
  });

  _exports.default = _default;
});