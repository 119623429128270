define("ember-cli-sketch/components/ui-block/sketch/stage/node/accessories/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/node/accessories/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default
  });

  _exports.default = _default;
});