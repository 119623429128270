define("ember-cli-zuglet/-private/lifecycle/observed/internal/writable", ["exports", "ember-cli-zuglet/-private/lifecycle/observed/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({});

  _exports.default = _default;
});