define("ember-cli-zuglet/-private/queue/computed", ["exports", "ember-cli-zuglet/-private/util/destroyable"], function (_exports, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(type, parentKey) {
    return (0, _destroyable.default)({
      create: function create() {
        var parent;

        if (parentKey) {
          parent = this.get(parentKey);
          (false && !(!!parent) && Ember.assert("parent ".concat(parentKey, " is not set"), !!parent));
        }

        return Ember.getOwner(this).factoryFor("zuglet:queue/".concat(type)).create({
          owner: this,
          parent: parent
        });
      }
    });
  };

  _exports.default = _default;
});