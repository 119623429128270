define("ember-cli-sketch/components/ui-block/sketch/stage/hovers/hover/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/-computed", "ember-cli-sketch/components/ui-block/sketch/stage/-show-mixin"], function (_exports, _computed, _showMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showMixin.default, {
    classNameBindings: [':ui-block-sketch-stage-hovers-hover'],
    attributeBindings: ['style'],
    frame: (0, _computed.frame)('model', 'hover', {
      inset: -1,
      index: false
    }),
    style: (0, _computed.style)('frame', function (_ref) {
      var frame = _ref.frame;
      return frame;
    })
  });

  _exports.default = _default;
});