define("ember-cli-sketch/-private/stage/hover", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    all: (0, _computed.array)(),
    attached: Ember.computed.filterBy('all', 'isAttached', true),
    selectable: Ember.computed.filterBy('all', 'isSelectable', true),
    deselected: Ember.computed.filterBy('selectable', 'isSelected', false),
    last: Ember.computed.readOnly('all.lastObject'),
    any: Ember.computed.gt('all.length', 0),
    replace: function replace(nodes) {
      var all = this.all;
      all.replace(0, all.length, nodes);
    },
    find: function find() {
      var _this$attached;

      return (_this$attached = this.attached).find.apply(_this$attached, arguments);
    },
    reset: function reset() {
      this.replace();
    }
  });

  _exports.default = _default;
});