define("ember-cli-zuglet/-private/data/object/object", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/data/internal/model-mixin"], function (_exports, _modelMixin, _modelMixin2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, _modelMixin2.default, {
    unknownProperty: function unknownProperty(key) {
      return this._internal.getModelValue(key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      return this._internal.setModelValue(key, value);
    }
  });

  _exports.default = _default;
});