define("design/components/ui-block/background-image/component", ["exports", "design/components/ui-block/background-image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-background-image', 'url::blank'],
    attributeBindings: ['style'],
    url: null,
    style: Ember.computed('url', function () {
      var url = this.url;

      if (!url) {
        return;
      }

      return Ember.String.htmlSafe("background-image: url(\"".concat(url, "\")"));
    }).readOnly()
  });

  _exports.default = _default;
});