define("ember-cli-zuglet/-private/data/manager", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/data/internal/util", "ember-cli-zuglet/-private/util/firestore-types"], function (_exports, _internal, _util, _firestoreTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serializers = ['reference', 'timestamp', 'array', 'blob', 'object', 'primitive'];

  var _default = _internal.default.extend({
    store: null,
    factoryFor: function factoryFor(name) {
      return this.store.factoryFor(name);
    },
    serializersByName: Ember.computed(function () {
      var _this = this;

      var store = this.store;
      return serializers.reduce(function (hash, name) {
        var value = _this.factoryFor("zuglet:data/".concat(name, "/serializer")).create({
          manager: _this,
          store: store
        });

        hash[name] = value;
        return hash;
      }, {});
    }).readOnly(),
    serializers: Ember.computed(function () {
      var serializers = this.get('serializersByName');
      var values = Ember.A();

      for (var key in serializers) {
        values.push(serializers[key]);
      }

      return values;
    }).readOnly(),
    serializerForName: function serializerForName(name) {
      return this.get('serializersByName')[name];
    },
    findSerializer: function findSerializer(cb) {
      return this.get('serializers').find(cb);
    },
    serializerForPrimitive: function serializerForPrimitive(value) {
      return this.findSerializer(function (serializer) {
        return serializer.supports(value);
      });
    },
    //
    createInternal: function createInternal(value) {
      if (value === undefined) {
        return;
      }

      var internal = (0, _util.toInternal)(value);

      if ((0, _util.isInternal)(internal)) {
        if (internal.isAttached()) {
          throw new Error('attached internal: not implemented');
        } else if (internal.root) {
          throw new Error('root internal: not implemented');
        }
      } else {
        var serializer = this.serializerForPrimitive(value);
        internal = serializer.createInternal(value);
      }

      return internal;
    },
    //
    createInternalServerTimestamp: function createInternalServerTimestamp() {
      var serializer = this.serializerForName('timestamp');
      return serializer.createInternal(_firestoreTypes.serverTimestamp, 'model');
    },
    createInternalArray: function createInternalArray(value) {
      var serializer = this.serializerForName('array');
      return serializer.createInternal(value);
    },
    createInternalObject: function createInternalObject(value) {
      var serializer = this.serializerForName('object');
      return serializer.createInternal(value);
    },
    createInternalRoot: function createInternalRoot(internal) {
      return this.factoryFor('zuglet:data/root').create({
        internal: internal
      });
    },
    createRootInternalObject: function createRootInternalObject(value) {
      var internal = this.createInternalObject(value);
      return this.createInternalRoot(internal);
    }
  });

  _exports.default = _default;
});