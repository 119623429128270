define("ember-cli-zuglet/-private/storage/reference/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/util/destroy-cached", "firebase", "ember-cli-zuglet/-private/queue/computed"], function (_exports, _internal, _destroyCached, _firebase, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var StringFormat = _firebase.default.storage.StringFormat;
  var stringFormats = {
    'raw': StringFormat.RAW,
    'base64': StringFormat.BASE64,
    'base64-url': StringFormat.BASE64URL,
    'data-url': StringFormat.DATA_URL
  };

  var _default = _internal.default.extend({
    storage: null,
    ref: null,
    queue: (0, _computed.default)('serialized', 'storage.queue'),
    factoryFor: function factoryFor(name) {
      return this.storage.factoryFor(name);
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:storage/reference').create({
        _internal: this
      });
    },
    metadata: Ember.computed(function () {
      return this.factoryFor('zuglet:storage/reference/metadata/internal').create({
        ref: this
      });
    }).readOnly(),
    url: Ember.computed(function () {
      return this.factoryFor('zuglet:storage/reference/url/internal').create({
        ref: this
      });
    }).readOnly(),
    load: function load() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      opts = Ember.assign({
        url: false,
        metadata: false
      }, opts);

      if (!opts.url && !opts.metadata) {
        opts.url = true;
        opts.metadata = true;
      }

      var tasks = [];

      if (opts.metadata) {
        tasks.push(this.get('metadata'));
      }

      if (opts.url) {
        tasks.push(this.get('url'));
      }

      return Ember.RSVP.all(tasks.map(function (task) {
        return task._load(opts);
      }));
    },
    onDeleted: function onDeleted() {
      var _this$getProperties = this.getProperties('metadata', 'url'),
          metadata = _this$getProperties.metadata,
          url = _this$getProperties.url;

      metadata.onDeleted();
      url.onDeleted();
    },
    onDeleteDidFail: function onDeleteDidFail(opts, err) {
      if (err.code === 'storage/object-not-found') {
        this.onDeleted();

        if (opts.optional) {
          return;
        }
      }

      return Ember.RSVP.reject(err);
    },
    delete: function _delete() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      opts = Ember.assign({
        optional: false
      }, opts);
      return this.get('queue').schedule({
        name: 'storage/ref/delete',
        invoke: function invoke() {
          return _this.ref.delete();
        },
        didResolve: function didResolve() {
          return _this.onDeleted();
        },
        didReject: function didReject(err) {
          return _this.onDeleteDidFail(opts, err);
        }
      });
    },
    createStorageTask: function createStorageTask(opts) {
      var type = opts.type,
          data = opts.data,
          format = opts.format,
          metadata = opts.metadata;
      (false && !(_typeof(metadata) === 'object') && Ember.assert("opts.metadata must be object", _typeof(metadata) === 'object'));
      var task;

      if (type === 'string') {
        var format_ = stringFormats[format];
        (false && !(format_) && Ember.assert("opts.format can be one of the following [ ".concat(Object.keys(stringFormats).join(', '), " ]"), format_));
        task = this.ref.putString(data, format_, metadata);
      } else if (type === 'data') {
        task = this.ref.put(data, metadata);
      } else {
        (false && !(false) && Ember.assert("opts.type must be string or data", false));
      }

      return {
        type: type,
        task: task
      };
    },
    createInternalTask: function createInternalTask(type, task) {
      return this.factoryFor('zuglet:storage/task/internal').create({
        ref: this,
        type: type,
        task: task
      });
    },
    put: function put(opts) {
      var _this$createStorageTa = this.createStorageTask(opts),
          task = _this$createStorageTa.task,
          type = _this$createStorageTa.type;

      var internal = this.createInternalTask(type, task);
      this.get('queue').schedule({
        name: 'storage/task/put',
        task: internal,
        promise: internal.get('promise')
      });
      return internal;
    },
    //
    child: function child(path) {
      var ref = this.ref.child(path);
      return this.storage.createInternalReference(ref);
    },
    parent: Ember.computed(function () {
      var ref = this.ref.parent;

      if (!ref) {
        return;
      }

      return this.storage.createInternalReference(ref);
    }).readOnly(),
    //
    onTaskCompleted: function onTaskCompleted(task) {
      this.get('metadata').onMetadata(task.get('snapshot').metadata);
    },
    //
    willDestroy: function willDestroy() {
      (0, _destroyCached.default)(this, 'metadata');
      (0, _destroyCached.default)(this, 'url');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});