define("design/components/ui-block/highlight/component", ["exports", "design/components/ui-block/highlight/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    blocks: (0, _computed.array)(),
    offset: null,
    isVisible: false,
    current: Ember.computed('blocks.@each.hasLabel', function () {
      return this.blocks.findBy('hasLabel', true);
    }).readOnly(),
    marker: Ember.computed('isVisible', 'offset', 'current.{label,offset}', function () {
      var isVisible = this.isVisible,
          current = this.current;

      if (!isVisible) {
        return;
      }

      if (!current) {
        return;
      }

      var target = current.target,
          offset = current.offset,
          label = current.label;

      if (!target) {
        return;
      }

      var element = target.element;

      var _element$getBoundingC = element.getBoundingClientRect(),
          left = _element$getBoundingC.left,
          top = _element$getBoundingC.top,
          width = _element$getBoundingC.width,
          height = _element$getBoundingC.height;

      top += window.scrollY; // eslint-disable-line no-undef

      var position = {
        top: Math.floor(top + height / 2),
        left: Math.ceil(left + width)
      };

      var addOffsets = function addOffsets(source) {
        if (!source) {
          return;
        }

        var add = function add(key) {
          var value = source[key];

          if (!value) {
            return;
          }

          position[key] = position[key] + value;
        };

        add('top');
        add('left');
      };

      addOffsets(this.offset);
      addOffsets(offset);
      return {
        position: position,
        label: label
      };
    }).readOnly(),
    actions: {
      present: function present() {
        this.setProperties({
          isVisible: true
        });
      },
      dismiss: function dismiss() {
        this.dismiss();
      }
    },
    dismiss: function dismiss() {
      if (this.isDestroying) {
        return;
      }

      this.setProperties({
        isVisible: false
      });
    },
    registerBlock: function registerBlock(block) {
      this.blocks.addObject(block);
    },
    unregisterBlock: function unregisterBlock(block) {
      this.blocks.removeObject(block);
    }
  });

  _exports.default = _default;
});