define("ember-cli-sketch/-private/sketches", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaults = _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var defaults = {
    interactions: ['tools'],
    tools: ['selection', 'stage/drag', 'stage/zoom', 'node/add', 'node/resize', 'node/move', 'node/drag', 'node/remove', 'node/edit'],
    actions: ['stage/zoom', 'stage/reset', 'stage/center', 'stage/fit', 'stage/selectable', 'node/aspect-fit', 'node/aspect-update', 'node/move-to-container'],
    fonts: {}
  };
  _exports.defaults = defaults;

  var create = function create() {
    return Ember.computed(function () {
      return Ember.getOwner(this).factoryFor("sketch:sketches/factory").create({
        sketches: this,
        opts: this.mergedOptions
      });
    }).readOnly();
  };

  var model = function model(name) {
    return (0, _computed.factory)(function (factory, sketches) {
      return factory[name].call(factory, sketches);
    });
  };

  var _default = Ember.Object.extend({
    options: null,
    registrations: null,
    factory: create(),
    fonts: model('fonts'),
    actions: model('actions'),
    mergedOptions: Ember.computed(function () {
      var options = this.options,
          registrations = this.registrations;
      (false && !(!!options) && Ember.assert("sketches.options are required", !!options));

      if (registrations) {
        var merge = function merge(key) {
          var arr = registrations[key];

          if (arr) {
            options[key] = [].concat(_toConsumableArray(options[key]), _toConsumableArray(arr));
          }
        };

        merge('interactions');
        merge('actions');
        merge('tools');
        options.fonts = Ember.assign({}, options.fonts, registrations.fonts);
      }

      return options;
    }).readOnly()
  });

  _exports.default = _default;
});