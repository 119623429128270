define("ember-cli-zuglet/-private/functions/functions/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var hack = function hack(functions) {
    // https://github.com/firebase/firebase-js-sdk/issues/630
    if (!functions.INTERNAL) {
      functions.INTERNAL = {
        delete: function _delete() {}
      };
    }

    return functions;
  };

  var _default = _internal.default.extend({
    store: null,
    region: null,
    factoryFor: function factoryFor(name) {
      return this.store.factoryFor(name);
    },
    functions: Ember.computed('region', function () {
      var region = this.get('region');
      return hack(this.store.app.functions(region));
    }).readOnly(),
    createModel: function createModel() {
      return this.factoryFor('zuglet:functions').create({
        _internal: this
      });
    },
    createCallableInternalForCallable: function createCallableInternalForCallable(name, callable) {
      return this.factoryFor('zuglet:functions/callable/internal').create({
        functions: this,
        name: name,
        callable: callable
      });
    },
    createCallable: function createCallable(name) {
      return this.get('functions').httpsCallable(name);
    },
    callable: function callable(name) {
      var callable = this.createCallable(name);
      return this.createCallableInternalForCallable(name, callable);
    }
  });

  _exports.default = _default;
});