define("ember-cli-zuglet/-private/lifecycle/route/hooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onWillDestroy = _exports.onResetController = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var key = '__zuglet_hooks__';

  var _get = function _get(object, functionName) {
    return object[functionName][key];
  };

  var extend = function extend(object, functionName, cb) {
    var arr = _get(object, functionName);

    if (!arr) {
      arr = Ember.A();
      var fn = object[functionName];

      object[functionName] = function () {
        var _arguments = arguments,
            _this = this;

        fn.call.apply(fn, [this].concat(Array.prototype.slice.call(arguments)));

        _get(this, functionName).map(function (fn) {
          return fn.call.apply(fn, [_this].concat(_toConsumableArray(_arguments)));
        });
      };

      object[functionName][key] = arr;
    }

    arr.addObject(cb);
  };

  var onResetController = function onResetController(route, cb) {
    return extend(route, 'resetController', cb);
  };

  _exports.onResetController = onResetController;

  var onWillDestroy = function onWillDestroy(route, cb) {
    return extend(route, 'willDestroy', cb);
  };

  _exports.onWillDestroy = onWillDestroy;
});