define("design/flow/index", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.step = _exports.flow = void 0;

  var flow = function flow(steps) {
    return (0, _lifecycle.model)().named('flow/flow').mapping(function (owner) {
      return {
        owner: owner,
        opts: {
          steps: steps
        }
      };
    });
  };

  _exports.flow = flow;

  var step = function step(_ref) {
    var title = _ref.title,
        enabled = _ref.enabled,
        staticCurrent = _ref.current,
        route = _ref.route,
        badge = _ref.badge,
        confirmation = _ref.confirmation;

    if (enabled === undefined) {
      enabled = true;
    }

    return {
      title: title,
      enabled: enabled,
      staticCurrent: staticCurrent,
      route: route,
      badge: badge,
      confirmation: confirmation
    };
  };

  _exports.step = step;
});