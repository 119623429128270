define("design/util/scroll-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var withWindow = function withWindow(cb) {
    if (typeof window !== 'undefined') {
      // eslint-disable-line no-undef
      return cb(window); // eslint-disable-line no-undef
    }
  };

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      withWindow(function (window) {
        _this.__onWindowScroll = function () {
          return Ember.run(function () {
            return _this._onWindowScroll();
          });
        };

        window.addEventListener('scroll', _this.__onWindowScroll);

        _this._onWindowScroll();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      withWindow(function (window) {
        window.removeEventListener('scroll', _this2.__onWindowScroll);
      });
    },
    _onWindowScroll: function _onWindowScroll() {
      var _this3 = this;

      withWindow(function (window) {
        var left = window.scrollX,
            top = window.scrollY;

        _this3.set('scroll', {
          left: left,
          top: top
        });
      });
    }
  });

  _exports.default = _default;
});