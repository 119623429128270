define("ember-cli-zuglet/-private/reference/collection/internal", ["exports", "ember-cli-zuglet/-private/reference/internal", "ember-cli-zuglet/-private/reference/queryable/internal-mixin"], function (_exports, _internal, _internalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend(_internalMixin.default, {
    id: Ember.computed.readOnly('ref.id'),
    path: Ember.computed.readOnly('ref.path'),
    type: 'collection',
    createParentInternal: function createParentInternal(parent) {
      return this.store.createInternalDocumentReferenceForReference(parent);
    },
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:reference/collection').create({
        _internal: this
      });
    },
    doc: function doc(path) {
      var ref;

      if (path) {
        ref = this.ref.doc(path);
      } else {
        ref = this.ref.doc();
      }

      return this.store.createInternalDocumentReferenceForReference(ref, this);
    }
  });

  _exports.default = _default;
});