define("ember-cli-zuglet/-private/auth/methods/email/email", ["exports", "ember-cli-zuglet/-private/auth/methods/method/method", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _method, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _method.default.extend({
    signIn: (0, _invoke.invokePromiseReturningModel)('signIn'),
    signUp: (0, _invoke.invokePromiseReturningModel)('signUp'),
    sendPasswordReset: (0, _invoke.invokePromiseReturningUndefined)('sendPasswordReset')
  });

  _exports.default = _default;
});