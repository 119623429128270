define("ember-cli-zuglet/-private/reference/reference", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    isReference: true,
    id: Ember.computed.readOnly("_internal.ref.id"),
    path: Ember.computed.readOnly("_internal.ref.path"),
    parent: Ember.computed(function () {
      var parent = this._internal.get('parent');

      return parent && parent.model(true);
    }),
    serialized: Ember.computed.readOnly('_internal.serialized'),
    string: Ember.computed.readOnly('_internal.string'),
    toStringExtension: function toStringExtension() {
      var string = this.get('string');
      return "".concat(string);
    },
    isEqual: function isEqual(b) {
      return this._internal.isEqual(b && b._internal);
    }
  });

  _exports.default = _default;
});