define("design/models/validation/validator/string", ["exports", "design/models/validation/validator/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isString = function isString(value) {
    return typeof value === 'string';
  };

  var _default = _base.default.extend({
    validate: function validate(value, _ref) {
      var required = _ref.required;

      if (!required && !value) {
        return {
          valid: true,
          blank: true
        };
      }

      if (isString(value) && value.trim().length > 0) {
        return {
          valid: true,
          blank: false
        };
      } else {
        return {
          valid: false,
          blank: true,
          error: "cannot be blank"
        };
      }
    }
  });

  _exports.default = _default;
});