define("ember-cli-zuglet/-private/util/firestore-types", ["exports", "firebase"], function (_exports, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serverTimestamp = _exports.isFirestoreTimestamp = _exports.isFirestoreServerTimestamp = _exports.isFirestoreDocumentReference = _exports.isFirestoreDocumentOrCollectionReference = _exports.isFirestoreCollectionReference = _exports.isFirestoreBlob = _exports.blobFromUint8Array = void 0;
  var _firebase$firestore = _firebase.default.firestore,
      DocumentReference = _firebase$firestore.DocumentReference,
      CollectionReference = _firebase$firestore.CollectionReference,
      FieldValue = _firebase$firestore.FieldValue,
      Timestamp = _firebase$firestore.Timestamp,
      Blob = _firebase$firestore.Blob;

  var isFirestoreDocumentReference = function isFirestoreDocumentReference(value) {
    return value instanceof DocumentReference;
  };

  _exports.isFirestoreDocumentReference = isFirestoreDocumentReference;

  var isFirestoreCollectionReference = function isFirestoreCollectionReference(value) {
    return value instanceof CollectionReference;
  };

  _exports.isFirestoreCollectionReference = isFirestoreCollectionReference;

  var isFirestoreDocumentOrCollectionReference = function isFirestoreDocumentOrCollectionReference(value) {
    return isFirestoreDocumentReference(value) || isFirestoreCollectionReference(value);
  };

  _exports.isFirestoreDocumentOrCollectionReference = isFirestoreDocumentOrCollectionReference;
  var serverTimestamp = FieldValue.serverTimestamp();
  _exports.serverTimestamp = serverTimestamp;

  var isFirestoreServerTimestamp = function isFirestoreServerTimestamp(value) {
    return value && serverTimestamp.isEqual(value);
  };

  _exports.isFirestoreServerTimestamp = isFirestoreServerTimestamp;

  var isFirestoreTimestamp = function isFirestoreTimestamp(value) {
    return value instanceof Timestamp;
  };

  _exports.isFirestoreTimestamp = isFirestoreTimestamp;

  var isFirestoreBlob = function isFirestoreBlob(value) {
    return value instanceof Blob;
  };

  _exports.isFirestoreBlob = isFirestoreBlob;

  var blobFromUint8Array = function blobFromUint8Array(value) {
    return Blob.fromUint8Array(value);
  };

  _exports.blobFromUint8Array = blobFromUint8Array;
});