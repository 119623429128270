define("design/components/ui-block/validation/validator/component", ["exports", "design/components/ui-block/validation/validator/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var validator = function validator(key) {
    return Ember.computed.readOnly("validator.".concat(key));
  };

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    validation: null,
    key: null,
    validator: Ember.computed('validation', 'key', function () {
      var validation = this.validation,
          key = this.key;
      return validation[key];
    }).readOnly(),
    value: validator('value'),
    isRequired: validator('isRequired'),
    isValid: validator('isValid'),
    isBlank: validator('isBlank'),
    isTouched: validator('isTouched'),
    showError: Ember.computed('isValid', 'isBlank', 'isTouched', function () {
      var isTouched = this.isTouched,
          isValid = this.isValid,
          isBlank = this.isBlank;
      return isTouched && !isValid && !isBlank;
    }).readOnly(),
    error: validator('error'),
    actions: {
      update: function update(value) {
        this.validator.update(value);
      },
      touch: function touch() {
        this.validator.touch();
      }
    }
  });

  _exports.default = _default;
});