define("design/mixins/click-outside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._clickOutsideMouseDown = function (e) {
        return Ember.run(function () {
          return _this.onClickOutsideMouseDown(e);
        });
      };

      var w = window; // eslint-disable-line no-undef

      w.addEventListener('mousedown', this._clickOutsideMouseDown, false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var w = window; // eslint-disable-line no-undef

      w.removeEventListener('mousedown', this._clickOutsideMouseDown);
    },
    isChildOf: function isChildOf(target, element) {
      while (element) {
        if (target === element) {
          return true;
        }

        element = element.parentNode;
      }

      return false;
    },
    isContainerElement: function isContainerElement(element) {
      return this.isChildOf(this.element, element);
    },
    onClickOutside: function onClickOutside() {},
    onClickOutsideMouseDown: function onClickOutsideMouseDown(e) {
      var target = e.target;

      if (this.isContainerElement(target)) {
        return;
      }

      this.onClickOutside();
    }
  });

  _exports.default = _default;
});