define("ember-cli-zuglet/-private/data/blob/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer", "ember-cli-zuglet/-private/util/firestore-types"], function (_exports, _serializer, _firestoreTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isEqual = function isEqual(a, b) {
    if (a.byteLength !== b.byteLength) {
      return false;
    }

    return a.every(function (val, i) {
      return val === b[i];
    });
  };

  var isUint8Array = function isUint8Array(value) {
    return value instanceof Uint8Array;
  };

  var toUint8Array = function toUint8Array(value) {
    return (0, _firestoreTypes.isFirestoreBlob)(value) ? value.toUint8Array() : value;
  };

  var _default = _serializer.default.extend({
    supports: function supports(value) {
      return isUint8Array(value) || (0, _firestoreTypes.isFirestoreBlob)(value);
    },
    matches: function matches(internal, value) {
      return (0, _firestoreTypes.isFirestoreBlob)(value);
    },
    createInternal: function createInternal(content) {
      content = toUint8Array(content);
      return this.factoryFor('zuglet:data/blob/internal').create({
        serializer: this,
        content: content
      });
    },
    serialize: function serialize(internal, type) {
      var content = internal.content;

      if (type === 'raw') {
        return (0, _firestoreTypes.blobFromUint8Array)(content);
      } else {
        return content;
      }
    },
    deserialize: function deserialize(internal, value) {
      value = toUint8Array(value);

      if (isEqual(internal.content, value)) {
        return {
          replace: false,
          internal: internal
        };
      }

      internal.content = value;
      return {
        replace: true,
        internal: internal
      };
    },
    isDirty: function isDirty(internal, raw) {
      raw = toUint8Array(raw);
      return !isEqual(internal.content, raw);
    }
  });

  _exports.default = _default;
});