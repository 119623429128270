define("ember-cli-zuglet/-private/query/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/util/set-changed-properties", "ember-cli-zuglet/-private/queue/computed", "ember-cli-zuglet/-private/observers/computed", "ember-cli-zuglet/-private/util/actions"], function (_exports, _internal, _setChangedProperties, _computed, _computed2, _actions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.state = _exports.meta = _exports.default = void 0;
  var state = ['isLoading', 'isLoaded', 'isObserving', 'isError', 'error'];
  _exports.state = state;
  var meta = ['type', 'size', 'empty', 'metadata'];
  _exports.meta = meta;

  var _default = _internal.default.extend({
    store: null,
    query: null,
    opts: null,
    // { doc }
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: null,
    isObserving: Ember.computed.readOnly('observers.isEnabled'),
    size: undefined,
    empty: undefined,
    _metadata: undefined,
    metadata: Ember.computed('_metadata', function () {
      var metadata = this.get('_metadata');

      if (!metadata) {
        return;
      }

      return {
        fromCache: metadata.fromCache,
        hasPendingWrites: metadata.hasPendingWrites
      };
    }).readOnly(),
    normalizedQuery: Ember.computed.readOnly('query'),
    unwrappedQuery: Ember.computed('normalizedQuery', function () {
      return this.get('normalizedQuery').ref;
    }).readOnly(),
    queue: (0, _computed.default)('serialized', 'store.queue'),
    content: null,
    //
    reuseableInternalDocumentForSnapshot: function reuseableInternalDocumentForSnapshot(snapshot) {
      var doc = this.get('opts.doc');

      if (doc) {
        var model = doc(snapshot.ref.path);

        if (model) {
          return model._internal;
        }
      }
    },
    createInternalDocumentForSnapshot: function createInternalDocumentForSnapshot(snapshot) {
      var internal = this.reuseableInternalDocumentForSnapshot(snapshot);

      if (internal) {
        return this.updateInternalDocumentForSnapshot(internal, snapshot);
      }

      return this.get('store').createInternalDocumentForSnapshot(snapshot);
    },
    updateInternalDocumentForSnapshot: function updateInternalDocumentForSnapshot(internal, snapshot) {
      return this.get('store').updateInternalDocumentForSnapshot(internal, snapshot);
    },
    factoryFor: function factoryFor(name) {
      return this.get('store').factoryFor(name);
    },
    //
    _onQueryError: function _onQueryError(operation, err, opts) {
      this.store.onQueryError(this, operation, err, opts);
    },
    //
    willLoad: function willLoad() {
      (0, _setChangedProperties.default)(this, {
        isLoading: true,
        isError: false,
        error: null
      });
    },
    _didLoad: function _didLoad(snapshot) {
      var size = snapshot.size,
          metadata = snapshot.metadata,
          empty = snapshot.empty;
      (0, _setChangedProperties.default)(this, {
        isLoading: false,
        isLoaded: true,
        size: size,
        empty: empty,
        _metadata: metadata
      });
      this.resolveObservers();
    },
    didLoad: function didLoad(snapshot) {
      this._didLoad(snapshot);

      return this;
    },
    loadDidFail: function loadDidFail(err, opts) {
      (0, _setChangedProperties.default)(this, {
        isLoading: false,
        isError: true,
        error: err
      });

      this._onQueryError('load', err, opts);

      return Ember.RSVP.reject(err);
    },
    load: function load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var force = opts.force,
          source = opts.source;

      var _this$getProperties = this.getProperties('isLoaded', 'isLoading'),
          isLoaded = _this$getProperties.isLoaded,
          isLoading = _this$getProperties.isLoading;

      if (isLoaded && !isLoading && !force) {
        return Ember.RSVP.resolve(this);
      }

      return this.get('queue').schedule({
        name: 'query/load',
        reuse: function reuse(operations) {
          return operations.findBy('name', 'query/load');
        },
        invoke: function invoke() {
          _this.willLoad();

          var query = _this.get('unwrappedQuery');

          return query.get({
            source: source
          });
        },
        didResolve: function didResolve(snapshot) {
          return _this.didLoad(snapshot);
        },
        didReject: function didReject(err) {
          return _this.loadDidFail(err, opts);
        }
      });
    },
    reload: function reload() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.load(Ember.assign({
        force: true
      }, opts));
    },
    //
    onSnapshot: function onSnapshot(snapshot) {
      this._didLoad(snapshot);
    },
    onSnapshotError: function onSnapshotError(err) {
      this._onQueryError('snapshot', err);
    },
    subscribeQueryOnSnapshot: function subscribeQueryOnSnapshot() {
      var _this2 = this;

      var query = this.get('unwrappedQuery');
      var opts = {
        includeMetadataChanges: true
      };
      return query.onSnapshot(opts, function (snapshot) {
        return (0, _actions.default)(function () {
          if (_this2.isDestroying) {
            return;
          }

          _this2.onSnapshot(snapshot);
        });
      }, function (err) {
        return (0, _actions.default)(function () {
          return _this2.onSnapshotError(err);
        });
      });
    },
    willObserve: function willObserve() {
      var _this$getProperties2 = this.getProperties('isLoading', 'isLoaded'),
          isLoading = _this$getProperties2.isLoading,
          isLoaded = _this$getProperties2.isLoaded;

      if (!isLoaded && !isLoading) {
        this.set('isLoading', true);
      }
    },
    observers: (0, _computed2.default)({
      parent: 'store',
      start: function start(state) {
        this.willObserve();
        state._cancel = this.subscribeQueryOnSnapshot();
      },
      stop: function stop(state) {
        state._cancel();
      }
    }),
    resolveObservers: function resolveObservers() {
      this.get('observers').resolve(this.model(true));
    },
    observe: function observe() {
      var store = this.get('store');
      var query = this;
      return this.get('observers').add('zuglet:observer/query/internal', {
        store: store,
        query: query
      });
    }
  });

  _exports.default = _default;
});