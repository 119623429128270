define("ember-cli-zuglet/lifecycle/observed", ["exports", "ember-cli-zuglet/-private/lifecycle/observed", "ember-cli-zuglet/-private/lifecycle/observed/observer-for", "ember-cli-zuglet/-private/lifecycle/observed/resolve-observers"], function (_exports, _observed, _observerFor, _resolveObservers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "observerFor", {
    enumerable: true,
    get: function get() {
      return _observerFor.default;
    }
  });
  Object.defineProperty(_exports, "resolveObservers", {
    enumerable: true,
    get: function get() {
      return _resolveObservers.default;
    }
  });
  var _default = _observed.default;
  _exports.default = _default;
});