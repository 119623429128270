define("ember-cli-zuglet/-private/util/settle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var afterRender = function afterRender(fn) {
    return Ember.run.schedule('afterRender', fn);
  };

  var settleIteration = function settleIteration(fn) {
    var array = fn();

    if (!array || array.length === 0) {
      return;
    }

    return Ember.RSVP.allSettled(array);
  };

  var settle = function settle(fn, deferred) {
    afterRender(function () {
      var iteration = settleIteration(fn);

      if (!iteration) {
        deferred.resolve();
        return;
      }

      iteration.then(function () {
        return settle(fn, deferred);
      });
    });
  };

  var _default = function _default(fn) {
    var deferred = Ember.RSVP.defer();
    afterRender(function () {
      return settle(fn, deferred);
    });
    return deferred.promise;
  };

  _exports.default = _default;
});