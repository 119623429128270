define("ember-cli-sketch/-private/sketches/fonts", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Object.extend({
    types: null,
    families: Ember.computed('types', function () {
      var types = this.types;
      var array = [];

      for (var key in types) {
        var hash = types[key];

        for (var name in hash) {
          array.push(name);
        }
      }

      return array;
    }).readOnly(),
    _loaders: (0, _computed.array)(),
    _createLoader: function _createLoader(opts) {
      var loader = this.sketches.factory.fontsLoader(this, opts);

      this._loaders.pushObject(loader);

      return loader;
    },
    _createCompoundLoader: function _createCompoundLoader(loaders) {
      return this.sketches.factory.fontLoaderCompound(this, loaders);
    },
    _loaderFor: function _loaderFor(mod, family) {
      return this._loaders.find(function (loader) {
        return loader.includes(mod, family);
      });
    },
    _normalizeModuleOptions: function _normalizeModuleOptions(mod, opts) {
      var types = this.types[mod];

      if (!types) {
        return opts;
      }

      var families = Ember.A(opts.families.map(function (name) {
        var value = types[name];

        if (value === true) {
          return name;
        }

        return "".concat(name, ":").concat(value);
      })).compact();
      return {
        families: families
      };
    },
    _normalizeOptions: function _normalizeOptions(opts) {
      var normalized = {};

      for (var mod in opts) {
        normalized[mod] = this._normalizeModuleOptions(mod, opts[mod]);
      }

      return normalized;
    },
    loaders: function loaders(opts) {
      var _this = this;

      opts = this._normalizeOptions(opts);
      var loaders = Ember.A();

      var _loop = function _loop(mod) {
        var families = opts[mod].families;
        var missing = [];
        families.forEach(function (family) {
          var loader = _this._loaderFor(mod, family);

          if (loader) {
            loaders.push(loader);
          } else {
            missing.push(family);
          }
        });

        if (missing.length) {
          loaders.push(_this._createLoader(_defineProperty({}, mod, {
            families: missing
          })));
        }
      };

      for (var mod in opts) {
        _loop(mod);
      }

      return loaders;
    },
    loader: function loader(opts) {
      var loaders = this.loaders(opts);

      if (loaders.length === 1) {
        return loaders[0];
      }

      return this._createCompoundLoader(loaders);
    }
  });

  _exports.default = _default;
});