define("ember-cli-zuglet/-private/data/internal/util", ["exports", "ember-cli-zuglet/-private/data/internal/model-mixin", "ember-cli-zuglet/-private/data/internal/internal"], function (_exports, _modelMixin, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertIsInternal = void 0;
  Object.defineProperty(_exports, "isInternal", {
    enumerable: true,
    get: function get() {
      return _internal.isInternal;
    }
  });
  Object.defineProperty(_exports, "isModel", {
    enumerable: true,
    get: function get() {
      return _modelMixin.isModel;
    }
  });
  _exports.toModel = _exports.toInternal = _exports.map = void 0;

  var map = function map(object, cb) {
    var result = {};

    for (var key in object) {
      var value = cb(key, object[key]);

      if (value !== undefined) {
        result[key] = value;
      }
    }

    return result;
  };

  _exports.map = map;

  var assertIsInternal = function assertIsInternal(key, value) {
    (false && !((0, _internal.isInternal)(value)) && Ember.assert("".concat(key, " must be internal"), (0, _internal.isInternal)(value)));
  };

  _exports.assertIsInternal = assertIsInternal;

  var toInternal = function toInternal(value) {
    if ((0, _modelMixin.isModel)(value)) {
      return value._internal;
    }

    return value;
  };

  _exports.toInternal = toInternal;

  var toModel = function toModel(value) {
    if ((0, _internal.isInternal)(value)) {
      return value.model(true);
    }

    return value;
  };

  _exports.toModel = toModel;
});