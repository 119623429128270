define("analytics/instance-initializers/boekdrukken-raygun", ["exports", "boekdrukken/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global rg4js, window, Ember */
  var analytics = _environment.default && _environment.default.boekdrukken && _environment.default.boekdrukken.analytics;
  var key = analytics && analytics.raygun;
  var environment = _environment.default.environment;

  var isFastBoot = function isFastBoot(app) {
    var service = app.lookup('service:fastboot');
    return service && service.isFastBoot;
  };

  var _default = {
    name: 'boekdrukken:raygun',
    initialize: function initialize(app) {
      if (isFastBoot(app)) {
        return;
      }

      if (environment !== 'production') {
        return;
      }

      if (!key) {
        return;
      }

      rg4js('apiKey', key);
      rg4js('setVersion', _environment.default.APP.version);
      rg4js('saveIfOffline', true);
      rg4js('enableCrashReporting', true);
      rg4js('withCustomData', {
        environment: _environment.default.boekdrukken.environment,
        client: _environment.default.boekdrukken.client,
        version: _environment.default.APP.version
      });

      var send = function send(error, handler) {
        var stack = '';

        if (error && error.stack) {
          stack = error.stack;
          console.error(handler, stack);
        } else {
          console.error(handler, error);
        }

        var payload = {
          error: error,
          customData: {
            handler: handler,
            stack: stack
          }
        };
        rg4js('send', payload);
      };

      Ember.onerror = function (error) {
        send(error, 'Ember.onerror');
      };

      window.onerror = function (error) {
        send(error, 'window.onerror');
      };
    }
  };
  _exports.default = _default;
});