define("ember-cli-sketch/-private/util/decode-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(url) {
    return Ember.RSVP.resolve(url).then(function (url) {
      if (!url) {
        return null;
      }

      var image = new Image();
      image.src = url;
      return Ember.RSVP.resolve(image.decode()).then(function () {
        return image;
      }, function () {
        return null;
      });
    });
  };

  _exports.default = _default;
});