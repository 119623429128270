define("ember-cli-zuglet/-private/util/fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupFastBoot = _exports.isFastBoot = _exports.getFastBoot = void 0;

  var lookupFastBoot = function lookupFastBoot(owner) {
    var fastboot = owner.lookup('service:fastboot');
    var isFastBoot = fastboot && fastboot.get('isFastBoot');
    return {
      fastboot: fastboot,
      isFastBoot: isFastBoot
    };
  };

  _exports.lookupFastBoot = lookupFastBoot;

  var getFastBoot = function getFastBoot(sender) {
    return lookupFastBoot(Ember.getOwner(sender));
  };

  _exports.getFastBoot = getFastBoot;

  var isFastBoot = function isFastBoot(sender) {
    var _getFastBoot = getFastBoot(sender),
        isFastBoot = _getFastBoot.isFastBoot;

    return isFastBoot;
  };

  _exports.isFastBoot = isFastBoot;
});