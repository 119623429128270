define("ember-cli-sketch/-private/stage/tools", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var all = function all() {
    return (0, _computed.factory)(function (factory, tools) {
      return Ember.A(tools.types.map(function (type) {
        return factory.tool(type, tools);
      }));
    });
  };

  var selected = function selected() {
    return Ember.computed({
      get: function get() {
        if (!this._selected) {
          this._selected = this.default;

          this._selected.activate();
        }

        return this._selected;
      },
      set: function set(key, value) {
        this._selected = value;
        return this._selected;
      }
    });
  };

  var _default = Ember.Object.extend({
    stage: null,
    types: null,
    all: all(),
    default: Ember.computed(function () {
      return this.byType(this.types[0]);
    }).readOnly(),
    selected: selected(),
    byType: function byType(type) {
      return this.all.findBy('type', type);
    },
    replace: function replace(tool, props) {
      var selected = this.selected;
      tool = tool || this.default;

      if (selected === tool) {
        return;
      }

      if (selected) {
        selected.deactivate(tool);
      }

      this.set('selected', tool);
      tool.activate(props);
    },
    activate: function activate(type, props) {
      var tool = this.byType(type);
      this.replace(tool, props);
    },
    deactivate: function deactivate(type) {
      var tool = this.byType(type);

      if (this.selected !== tool) {
        return;
      }

      this.replace(null);
    },
    _deactivate: function _deactivate(tool) {
      if (this.selected !== tool) {
        return;
      }

      this.reset();
    },
    reset: function reset() {
      this.replace(null);
    }
  });

  _exports.default = _default;
});