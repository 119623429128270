define("ember-cli-zuglet/-private/query/array/internal", ["exports", "ember-cli-zuglet/-private/query/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    type: 'array',
    content: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),
    proxy: Ember.computed(function () {
      var content = this.get('content');
      return this.store.factoryFor('zuglet:query/array/content').create({
        content: content
      });
    }).readOnly(),
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:query/array').create({
        _internal: this
      });
    },
    //
    onChange: function onChange(change) {
      var type = change.type,
          oldIndex = change.oldIndex,
          newIndex = change.newIndex,
          snapshot = change.doc;
      var path = snapshot.ref.path;
      var content = this.get('content');

      if (type === 'added') {
        (false && !(!content.findBy('ref.path', path)) && Ember.assert("added: doc exists ".concat(path), !content.findBy('ref.path', path)));
        var doc = this.createInternalDocumentForSnapshot(snapshot);
        content.insertAt(newIndex, doc);
      } else if (type === 'modified') {
        var _doc = content.objectAt(oldIndex);

        if (!_doc || _doc.get('ref.path') !== path) {
          _doc = content.findBy('ref.path', path);
        }

        (false && !(_doc) && Ember.assert("modified: doc not found ".concat(path), _doc));
        this.updateInternalDocumentForSnapshot(_doc, snapshot);

        if (oldIndex !== newIndex) {
          content.removeAt(oldIndex);
          content.insertAt(newIndex, _doc);
        }
      } else if (type === 'removed') {
        content.removeAt(oldIndex);
      }
    },
    onChanges: function onChanges(snapshot) {
      var _this = this;

      snapshot.docChanges({
        includeMetadataChanges: true
      }).map(function (change) {
        return _this.onChange(change);
      });
    },
    onReplace: function onReplace(snapshot) {
      var _this2 = this;

      var content = this.get('content');
      var documents = Ember.A(snapshot.docs.map(function (doc) {
        var document = content.findBy('ref.path', doc.ref.path);

        if (document) {
          return _this2.updateInternalDocumentForSnapshot(document, doc);
        }

        return _this2.createInternalDocumentForSnapshot(doc);
      }));
      content.replace(0, content.get('length'), documents);
    },
    needsReplace: true,
    onSnapshot: function onSnapshot(snapshot) {
      if (this.needsReplace) {
        this.onReplace(snapshot);
        this.needsReplace = false;
      } else {
        this.onChanges(snapshot);
      }

      return this._super.apply(this, arguments);
    },
    didLoad: function didLoad(snapshot) {
      this.onReplace(snapshot);
      this.needsReplace = true;
      return this._super.apply(this, arguments);
    },
    subscribeQueryOnSnapshot: function subscribeQueryOnSnapshot() {
      this.needsReplace = true;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});