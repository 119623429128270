define("design/components/ui-block/master-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kh/aBdrK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"toolbar\",\"master\",\"detail\"],[[28,\"component\",[\"ui-block/master-detail/toolbar\"],null],[28,\"component\",[\"ui-block/master-detail/master\"],null],[28,\"component\",[\"ui-block/master-detail/detail\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/master-detail/template.hbs"
    }
  });

  _exports.default = _default;
});