define("ember-cli-sketch/util/image", ["exports", "ember-cli-sketch/-private/util/decode-image"], function (_exports, _decodeImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "decodeImage", {
    enumerable: true,
    get: function get() {
      return _decodeImage.default;
    }
  });
});