define("ember-cli-sketch/-private/util/computed", ["exports", "ember-cli-sketch/-private/util/math", "ember-cli-sketch/-private/util/object", "ember-cli-sketch/-private/util/sketches"], function (_exports, _math, _object, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoom = _exports.serialized = _exports.self = _exports.number = _exports.findBy = _exports.factory = _exports.array = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var array = function array() {
    return Ember.computed(function () {
      return Ember.A();
    }).readOnly();
  };

  _exports.array = array;

  var self = function self() {
    return Ember.computed(function () {
      return this;
    });
  };

  _exports.self = self;

  var serialized = function serialized(keys, fn) {
    var _Ember;

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(keys).concat([function () {
      var props = this.getProperties(keys);

      if (fn) {
        props = fn.call(this, props);
      }

      return (0, _object.compact)(props);
    }])).readOnly();
  };

  _exports.serialized = serialized;

  var factory = function factory(fn) {
    return Ember.computed(function () {
      return fn.call(this, (0, _sketches.default)(this).factory, this);
    }).readOnly();
  };

  _exports.factory = factory;

  var number = function number(opts) {
    return function (key) {
      var fn = (0, _math.numberConstraints)(opts);
      return Ember.computed(key, function () {
        return fn(this.get(key));
      }).readOnly();
    };
  };

  _exports.number = number;
  var zoom = number({
    initial: 1,
    min: 0,
    max: 10,
    decimals: 2
  });
  _exports.zoom = zoom;

  var findBy = function findBy(arrayKey, prop, value) {
    return Ember.computed("".concat(arrayKey, ".@each.").concat(prop), function () {
      var array = this.get(arrayKey);

      if (!array) {
        return;
      }

      return array.findBy(prop, value);
    }).readOnly();
  };

  _exports.findBy = findBy;
});