define("ember-cli-zuglet/-private/functions/functions/functions", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    region: Ember.computed.readOnly('_internal.region'),
    callable: function callable(name) {
      return this._internal.callable(name).model(true);
    },
    toStringExtension: function toStringExtension() {
      return this.get('region');
    }
  });

  _exports.default = _default;
});