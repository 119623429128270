define("analytics/mixins/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    analytics: Ember.inject.service(),
    isAnalyticsSuppressedForTransitionTarget: function isAnalyticsSuppressedForTransitionTarget(transition) {
      var to = transition.to;

      if (!to) {
        return false;
      }

      var attributes = to.attributes;

      if (!attributes) {
        return false;
      }

      var analyticsSuppressed = attributes.analyticsSuppressed;

      if (!analyticsSuppressed) {
        return false;
      }

      return true;
    },
    routeDidChange: function routeDidChange(transition) {
      var suppressed = this.isAnalyticsSuppressedForTransitionTarget(transition);
      var analytics = this.analytics,
          url = this.currentURL,
          routeName = this.currentRouteName;
      analytics.onRouteDidChange(suppressed, {
        url: url,
        routeName: routeName
      });
    },
    routeWillChange: function routeWillChange() {
      this.analytics.onRouteWillChange();
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeWillChange', function (transition) {
        _this.routeWillChange(transition);
      });
      this.on('routeDidChange', function (transition) {
        _this.routeDidChange(transition);
      });
    }
  });

  _exports.default = _default;
});