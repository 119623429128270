define("ember-cli-zuglet/-private/storage/storage/tasks", ["exports", "ember-cli-zuglet/-private/util/array-transform-mixin"], function (_exports, _arrayTransformMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TransformMixin = (0, _arrayTransformMixin.default)({
    internal: function internal() {
      (false && !(false) && Ember.assert('this array is read-only', false));
    },
    public: function _public(operation) {
      return operation.get('opts.task').model(true);
    }
  });

  var _default = Ember.ArrayProxy.extend(TransformMixin);

  _exports.default = _default;
});