define("ember-cli-zuglet/-private/auth/methods/anonymous/internal", ["exports", "ember-cli-zuglet/-private/auth/methods/method/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    signIn: function signIn() {
      return this.withAuthReturningUser(function (auth) {
        return auth.signInAnonymously().then(function (_ref) {
          var user = _ref.user;
          return user;
        });
      });
    }
  });

  _exports.default = _default;
});