define("design/components/ui-block/pill/component", ["exports", "design/components/ui-block/pill/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-pill', '_size', '_color', 'action:has-action:no-action'],
    layout: _template.default,
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    click: function click() {
      this.action && this.action();
    }
  });

  _exports.default = _default;
});