define("ember-cli-zuglet/-private/data/internal/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/internal/with-property-changes"], function (_exports, _internal, _withPropertyChanges2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInternal = _exports.default = void 0;

  var _Internal$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var key = '_isZugletDataInternal';

  var isInternal = function isInternal(arg) {
    return arg && Ember.get(arg, key) === true;
  };

  _exports.isInternal = isInternal;

  var _default = _internal.default.extend((_Internal$extend = {}, _defineProperty(_Internal$extend, key, true), _defineProperty(_Internal$extend, "serializer", null), _defineProperty(_Internal$extend, "manager", null), _defineProperty(_Internal$extend, "root", null), _defineProperty(_Internal$extend, "parent", null), _defineProperty(_Internal$extend, "init", function init() {
    this._super.apply(this, arguments);

    this.manager = this.serializer.manager;
  }), _defineProperty(_Internal$extend, "factoryFor", function factoryFor(name) {
    return this.serializer.factoryFor(name);
  }), _defineProperty(_Internal$extend, "attach", function attach(parent) {
    (false && !(isInternal(parent)) && Ember.assert("parent must be data internal", isInternal(parent)));
    this.parent = parent;
  }), _defineProperty(_Internal$extend, "detach", function detach() {
    (false && !(!this.root) && Ember.assert("cannot detach root", !this.root));
    this.parent = null;
  }), _defineProperty(_Internal$extend, "isAttached", function isAttached() {
    return !!this.parent;
  }), _defineProperty(_Internal$extend, "childDidUpdate", function childDidUpdate() {
    this.withPropertyChanges(true, function (changed) {
      return changed('serialized');
    });
    this.notifyDidUpdate();
  }), _defineProperty(_Internal$extend, "notifyDidUpdate", function notifyDidUpdate() {
    var parent = this.parent;

    if (parent) {
      parent.childDidUpdate(this);
    } else {
      var root = this.root;

      if (root) {
        root.internalDidUpdate();
      }
    }
  }), _defineProperty(_Internal$extend, "didUpdate", function didUpdate(changed) {
    changed('serialized');
    this.notifyDidUpdate();
  }), _defineProperty(_Internal$extend, "withPropertyChanges", function withPropertyChanges(notify, fn) {
    var _this = this;

    return (0, _withPropertyChanges2.default)(this, notify, fn, function (changed) {
      if (changed.any) {
        _this.didUpdate(changed);
      }
    });
  }), _defineProperty(_Internal$extend, "serialize", function serialize(type) {
    return this.serializer.serialize(this, type);
  }), _Internal$extend));

  _exports.default = _default;
});