define("ember-cli-zuglet/-private/document/document", ["exports", "ember-cli-zuglet/-private/internal/read-only-props-mixin", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/document/internal", "ember-cli-zuglet/-private/internal/invoke", "ember-cli-zuglet/-private/observers/computed"], function (_exports, _readOnlyPropsMixin, _modelMixin, _internal, _invoke, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var StateMixin = (0, _readOnlyPropsMixin.default)(_internal.state);
  var MetaMixin = (0, _readOnlyPropsMixin.default)(_internal.meta);

  var ref = function ref(key) {
    return Ember.computed.readOnly("ref.".concat(key));
  };

  var serialized = ['id', 'path'].concat(_toConsumableArray(_internal.state), _toConsumableArray(_internal.meta));

  var _default = Ember.Object.extend(_modelMixin.default, StateMixin, MetaMixin, {
    isDocument: true,
    ref: Ember.computed('_internal.ref', function () {
      return this.get('_internal.ref').model(true);
    }).readOnly(),
    id: ref('id'),
    path: ref('path'),
    data: Ember.computed(function () {
      return this.get('_internal.data.internal').model(true);
    }).readOnly(),
    serialized: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(serialized).concat(['data.serialized', function () {
      var props = this.getProperties.apply(this, _toConsumableArray(serialized));
      props.data = this.get('data.serialized');
      return props;
    }])).readOnly(),
    load: (0, _invoke.invokePromiseReturningThis)('load'),
    reload: (0, _invoke.invokePromiseReturningThis)('reload'),
    save: (0, _invoke.invokePromiseReturningThis)('save'),
    delete: (0, _invoke.invokePromiseReturningThis)('delete'),
    observe: (0, _invoke.invokeReturningModel)('observe'),
    observers: (0, _computed.observers)(),
    reset: (0, _invoke.invoke)('reset'),
    toStringExtension: function toStringExtension() {
      var path = this.get('path');
      return "".concat(path);
    }
  });

  _exports.default = _default;
});