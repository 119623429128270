define("ember-cli-zuglet/-private/reference/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    store: null,
    ref: null,
    _parent: null,
    id: Ember.computed.readOnly('ref.id'),
    path: Ember.computed.readOnly('ref.path'),
    parent: Ember.computed(function () {
      var parent = this.get('_parent');

      if (parent) {
        return parent;
      }

      parent = this.ref.parent;

      if (!parent) {
        return null;
      }

      return this.createParentInternal(parent);
    }),
    string: Ember.computed(function () {
      var parent = this.get('parent.string');
      var id = this.get('id');

      if (parent) {
        return "".concat(parent, "/").concat(id);
      }

      return id;
    }),
    serialized: Ember.computed(function () {
      var arr = this.get('parent.serialized') || [];

      var _this$getProperties = this.getProperties('type', 'id'),
          type = _this$getProperties.type,
          id = _this$getProperties.id;

      arr.push({
        type: type,
        id: id
      });
      return arr;
    }),
    isEqual: function isEqual(other) {
      if (!this.constructor.detectInstance(other)) {
        return false;
      }

      return this.ref.isEqual(other.ref);
    }
  });

  _exports.default = _default;
});