define("ember-cli-sketch/-private/task/mixin", ["exports", "ember-cli-sketch/-private/task/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    willDestroy: function willDestroy() {
      (0, _computed.destroyTasks)(this);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});