define("ember-cli-zuglet/-private/lifecycle/models/models", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/util/array-transform-mixin"], function (_exports, _modelMixin, _arrayTransformMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TransformMixin = (0, _arrayTransformMixin.default)({
    public: function _public(internal) {
      return internal && internal.model(true);
    }
  });

  var _default = Ember.ArrayProxy.extend(_modelMixin.default, TransformMixin);

  _exports.default = _default;
});