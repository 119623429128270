define("ember-cli-zuglet/-private/auth/methods/email/internal", ["exports", "ember-cli-zuglet/-private/auth/methods/method/internal", "firebase"], function (_exports, _internal, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    signIn: function signIn(email, password) {
      return this.withAuthReturningUser(function (auth) {
        return auth.signInWithEmailAndPassword(email, password).then(function (_ref) {
          var user = _ref.user;
          return user;
        });
      });
    },
    signUp: function signUp(email, password) {
      return this.withAuthReturningUser(function (auth) {
        return auth.createUserWithEmailAndPassword(email, password).then(function (_ref2) {
          var user = _ref2.user;
          return user;
        });
      });
    },
    credential: function credential(email, password) {
      return _firebase.default.auth.EmailAuthProvider.credential(email, password);
    },
    sendPasswordReset: function sendPasswordReset(email, opts) {
      return this.withAuth(function (auth) {
        return auth.sendPasswordResetEmail(email, opts);
      });
    }
  });

  _exports.default = _default;
});