define("ember-cli-zuglet/-private/models/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    store: null,
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:models').create({
        _internal: this
      });
    },
    normalizedModelName: function normalizedModelName(name) {
      (false && !(!!name) && Ember.assert("model name is required", !!name));
      (false && !(typeof name === 'string') && Ember.assert("model name must be string", typeof name === 'string'));
      return Ember.String.dasherize(name);
    },
    modelFullNameForNormalizedName: function modelFullNameForNormalizedName(name) {
      return "model:".concat(name);
    },
    registerFactory: function registerFactory(name, factory) {
      var normalizedName = this.normalizedModelName(name);
      var fullName = this.modelFullNameForNormalizedName(normalizedName);
      Ember.getOwner(this).register(fullName, factory);
    },
    hasFactoryFor: function hasFactoryFor(name) {
      return !!this.factoryFor(name, {
        optional: true
      });
    },
    factoryFor: function factoryFor(name) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var optional = opts.optional;
      var normalizedName = this.normalizedModelName(name);
      var fullName = this.modelFullNameForNormalizedName(normalizedName);
      var factory = this.store.factoryFor(fullName);
      (false && !(optional || !!factory) && Ember.assert("model '".concat(normalizedName, "' is not registered"), optional || !!factory));
      return factory;
    },
    create: function create(name, props) {
      var factory = this.factoryFor(name);
      return factory.create(props);
    }
  });

  _exports.default = _default;
});