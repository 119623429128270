define("ember-cli-zuglet/-private/auth/methods/method/method", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    type: Ember.computed.readOnly('_internal.type')
  });

  _exports.default = _default;
});