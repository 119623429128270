define("design/components/ui-block/left-right/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJOEJkw6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"side left\"],[3,\"action\",[[23,0,[]],\"left\"]],[8],[0,\"\\n  \"],[1,[28,\"ui-block/icon\",[\"chevron-left\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",\"side right\"],[3,\"action\",[[23,0,[]],\"right\"]],[8],[0,\"\\n  \"],[1,[28,\"ui-block/icon\",[\"chevron-right\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/left-right/template.hbs"
    }
  });

  _exports.default = _default;
});