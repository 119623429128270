define("ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/edit/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/edit/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':edit'],
    strings: Ember.computed.readOnly('model.node.stage.strings'),
    click: function click() {
      var node = this.model.node;
      this.model.node.stage.tools.activate('node/edit', {
        node: node
      });
    }
  });

  _exports.default = _default;
});