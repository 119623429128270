define("ember-cli-sketch/-private/util/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round = _exports.rotatedRectBounds = _exports.rotatePosition = _exports.radToDeg = _exports.numberConstraints = _exports.millimetersToPixels = _exports.degToRad = void 0;
  var min = Math.min,
      max = Math.max;
  var c_180divPI = 180 / Math.PI;
  var c_PIdiv180 = Math.PI / 180;

  var radToDeg = function radToDeg(rad) {
    return rad * c_180divPI;
  };

  _exports.radToDeg = radToDeg;

  var degToRad = function degToRad(deg) {
    return deg * c_PIdiv180;
  };

  _exports.degToRad = degToRad;

  var round = function round(value) {
    var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (!value) {
      return value;
    }

    value = value.toFixed(decimals + 1);
    return Number(Math.round("".concat(value, "e").concat(decimals)) + "e-".concat(decimals));
  };

  _exports.round = round;

  var numberConstraints = function numberConstraints(opts) {
    return function (value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return opts.initial;
      }

      value = round(value, opts.decimals || 0);

      if (opts.min !== undefined) {
        value = max(value, opts.min);
      }

      if (opts.max !== undefined) {
        value = min(value, opts.max);
      }

      return value;
    };
  };

  _exports.numberConstraints = numberConstraints;

  var rotatedRectBounds = function rotatedRectBounds(frame) {
    if (!frame) {
      return;
    }

    var x = frame.x,
        y = frame.y,
        width = frame.width,
        height = frame.height,
        rotation = frame.rotation;

    if (!rotation) {
      return Ember.assign({}, frame);
    }

    var points = [{
      x: x,
      y: y
    }, {
      x: x + width,
      y: y
    }, {
      x: x,
      y: y + height
    }, {
      x: x + width,
      y: y + height
    }];
    var rad = degToRad(rotation);
    var cos = Math.cos(rad);
    var sin = Math.sin(rad);
    var min = points[0];
    var max = points[3];
    var pivot = {
      x: max.x - (max.x - min.x) / 2,
      y: max.y - (max.y - min.y) / 2
    };

    var rotate = function rotate(point) {
      return {
        x: cos * (point.x - pivot.x) - sin * (point.y - pivot.y) + pivot.x,
        y: sin * (point.x - pivot.x) + cos * (point.y - pivot.y) + pivot.y
      };
    };

    var box = {
      min: {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY
      },
      max: {
        x: Number.NEGATIVE_INFINITY,
        y: Number.NEGATIVE_INFINITY
      }
    };
    points.forEach(function (point) {
      var rotated = rotate(point);
      box.min.x = Math.min(box.min.x, rotated.x);
      box.min.y = Math.min(box.min.y, rotated.y);
      box.max.x = Math.max(box.max.x, rotated.x);
      box.max.y = Math.max(box.max.y, rotated.y);
    });
    return {
      x: box.min.x,
      y: box.min.y,
      width: box.max.x - box.min.x,
      height: box.max.y - box.min.y,
      rotation: 0
    };
  };

  _exports.rotatedRectBounds = rotatedRectBounds;

  var rotatePosition = function rotatePosition(_ref, frame, rotation) {
    var x = _ref.x,
        y = _ref.y;
    var center = {
      x: frame.x + frame.width / 2,
      y: frame.y + frame.height / 2
    };
    var rad = degToRad(rotation);
    var cos = Math.cos(rad);
    var sin = Math.sin(rad);

    var r = function r(value) {
      return round(value, 0);
    };

    var point = {
      x: r(cos * (x - center.x) - sin * (y - center.y) + center.x),
      y: r(sin * (x - center.x) + cos * (y - center.y) + center.y)
    };
    return point;
  };

  _exports.rotatePosition = rotatePosition;

  var millimetersToPixels = function millimetersToPixels(mm) {
    return mm / 25.4 * 96;
  };

  _exports.millimetersToPixels = millimetersToPixels;
});