define("ember-cli-zuglet/-private/queue/queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    parent: null,
    operations: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),
    createInvocableOperation: function createInvocableOperation(opts) {
      var owner = this.get('owner');
      return Ember.getOwner(this).factoryFor('zuglet:queue/operation/invocable').create({
        queue: this,
        owner: owner,
        opts: opts
      });
    },
    createPromiseOperation: function createPromiseOperation(opts) {
      var owner = this.get('owner');
      return Ember.getOwner(this).factoryFor('zuglet:queue/operation/promise').create({
        queue: this,
        owner: owner,
        opts: opts
      });
    },
    reuse: function reuse(opts) {
      var operations = this.get('operations');

      if (opts.reuse) {
        return opts.reuse(operations);
      }
    },
    register: function register(operation) {
      var operations = this.get('operations');
      operations.pushObject(operation);
      operation.get('promise').catch(function () {}).finally(function () {
        operations.removeObject(operation);
      });
      var parent = this.get('parent');

      if (parent) {
        parent.register(operation);
      }
    },
    schedule: function schedule(opts) {
      if (this.isDestroying) {
        return;
      }

      var operation = this.reuse(opts);

      if (!operation) {
        if (opts.promise) {
          operation = this.createPromiseOperation(opts);
        } else {
          operation = this.createInvocableOperation(opts);
        }
      }

      this.register(operation);
      this.didCreateOperation(operation);
      return operation.get('promise');
    },
    promises: function promises() {
      return this.get('operations').mapBy('promise');
    },
    willDestroy: function willDestroy() {
      this.get('operations').forEach(function (operation) {
        return operation.destroy();
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});