define("ember-cli-zuglet/-private/lifecycle/observed/index", ["exports", "ember-cli-zuglet/-private/lifecycle/observed/property"], function (_exports, _property) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var compact = function compact(array) {
    return Ember.A(array).compact();
  };

  var build = function build(opts) {
    var nested = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    opts = Ember.assign({}, opts, nested);
    var prop = (0, _property.default)(opts);

    prop.owner = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return build(opts, {
        parent: compact(args)
      });
    };

    prop.content = function (arg) {
      return build(opts, {
        content: arg
      });
    };

    return prop;
  };

  var _default = function _default() {
    var opts = {
      parent: [],
      content: undefined
    };
    return build(opts, {});
  };

  _exports.default = _default;
});