define("ember-cli-zuglet/-private/models/models", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    registerFactory: function registerFactory(name, factory) {
      return this._internal.registerFactory(name, factory);
    },
    hasFactoryFor: function hasFactoryFor(name) {
      return this._internal.hasFactoryFor(name);
    },
    factoryFor: function factoryFor(name, opts) {
      return this._internal.factoryFor(name, opts);
    },
    create: function create(name, props) {
      return this._internal.create(name, props);
    }
  });

  _exports.default = _default;
});