define("ember-content-editable/components/content-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ember-content-editable'],
    classNameBindings: ['clearPlaceholderOnFocus:clear-on-focus'],
    attributeBindings: ['contenteditable', 'placeholder', 'spellcheck', 'tabindex', 'disabled'],
    disabled: false,
    spellcheck: null,
    allowNewlines: true,
    autofocus: false,
    clearPlaceholderOnFocus: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('keyWhitelist', [8, // backspace
      27, // escape
      37, // left arrow
      38, // up arrow
      39, // right arrow
      40 // down arrow
      ]);
      this._pasteHandler = Ember.run.bind(this, this.pasteHandler);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.updateDom();
      this._mutationObserver = new MutationObserver(Ember.run.bind(this, this.domChanged));

      this._mutationObserver.observe(this.element, {
        attributes: false,
        childList: true,
        characterData: true,
        subtree: true
      });

      if (this.get('autofocus')) {
        this.element.focus();
      }

      this.element.addEventListener('paste', this._pasteHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('paste', this._pasteHandler);

      this._mutationObserver.disconnect();
    },
    domChanged: function domChanged() {
      var text = this.element.innerText;
      this.setProperties({
        value: text,
        _internalValue: text
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('contenteditable', !this.get('disabled'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments); // if update has been initiated by a change of the dom (user entered something) we don't do anything because
      // - value has already been updated by domChanged
      // - the rendered text already shows the current value


      if (this.get('value') != this.get('_internalValue')) {
        this.updateDom();
      }
    },
    updateDom: function updateDom() {
      var value = this.get('value');

      if (value === undefined || value === null) {
        this.element.innerText = '';
      } else {
        this.element.innerText = value;
      }
    },
    keyUp: function keyUp(event) {
      this.get('key-up')(event);
    },
    keyPress: function keyPress(event) {
      // Firefox seems to call this method on backspace and cursor keyboard events, whereas chrome does not.
      // Therefore we keep a whitelist of keyCodes that we allow in case it is necessary.
      var newLength = this.element.innerText.length - this.getSelectionLength();

      if (this.get('maxlength') && newLength >= this.get('maxlength') && !this.get('keyWhitelist').includes(event.keyCode)) {
        event.preventDefault();
        this.get('length-exceeded')(this.element.innerText.length + 1);
        return false;
      }

      this.get('key-press')(event);
    },
    keyDown: function keyDown(event) {
      if (event.keyCode === 27) {
        this.get('escape-press')(event);
      } else if (event.keyCode === 13) {
        this.get('enter')(event);

        if (this.get('allowNewlines')) {
          this.get('insert-newline')(event);
        } else {
          event.preventDefault();
          return false;
        }
      }

      this.get('key-down')(event);
    },
    getSelectionLength: function getSelectionLength() {
      var selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        var range = selection.getRangeAt(0);
        return range.endOffset - range.startOffset;
      }

      return 0;
    },
    pasteHandler: function pasteHandler(event) {
      event.preventDefault(); // replace any html formatted text with its plain text equivalent

      var text = '';

      if (event.clipboardData) {
        text = event.clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      } // check max length


      if (this.get('maxlength')) {
        // a selection will be replaced. substract the length of the selection from the total length
        var selectionLength = this.getSelectionLength();
        var afterPasteLength = text.length + this.element.innerText.length - selectionLength;

        if (afterPasteLength > this.get('maxlength')) {
          this.get('length-exceeded')(afterPasteLength);
          return false;
        }
      }

      crossSupportInsertText(text);
      this.get('paste')(text);
    },
    enter: function enter() {},
    'escape-press': function escapePress() {},
    'key-up': function keyUp() {},
    'key-press': function keyPress() {},
    'key-down': function keyDown() {},
    'length-exceeded': function lengthExceeded() {},
    'insert-newline': function insertNewline() {},
    paste: function paste() {}
  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;

  function crossSupportInsertText(text) {
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      var range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      var textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);
      var selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
});