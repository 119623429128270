define("ember-cli-zuglet/-private/lifecycle/util/inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(args) {
    var mixins = args.slice();
    var props = mixins.pop();
    return {
      props: props,
      mixins: mixins
    };
  };

  _exports.default = _default;
});