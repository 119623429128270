define("analytics/models/analytics/ga", ["exports", "analytics/models/analytics/-analytics", "boekdrukken/util/computed"], function (_exports, _analytics, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analytics.default.extend({
    gtag: Ember.computed(function () {
      return window.gtag; // eslint-disable-line no-undef
    }).readOnly(),
    key: (0, _computed.config)(function (conf) {
      return Ember.get(conf, 'analytics.ga');
    }),
    mapping: (0, _computed.config)(function (conf) {
      return Ember.get(conf, 'analytics.conversions');
    }),
    isEnabled: Ember.computed.bool('gtag'),
    sendPageView: function sendPageView(opts) {
      var isEnabled = this.isEnabled,
          gtag = this.gtag,
          key = this.key;

      if (!isEnabled) {
        return;
      }

      var url = opts.url,
          path = opts.path;
      path = path || url; // console.log('page view', { key, path, url });

      gtag('config', key, {
        'page_title': path,
        'page_path': url
      });
    },
    sendEvent: function sendEvent(opts) {
      var category = opts.category,
          action = opts.action,
          label = opts.label,
          value = opts.value;
      var isEnabled = this.isEnabled,
          gtag = this.gtag;

      if (!isEnabled) {
        return;
      } // console.log('event', { category, action, label, value });


      gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'event_value': value
      });
    }
  });

  _exports.default = _default;
});