define("ember-cli-sketch/-private/task/computed", ["exports", "ember-cli-sketch/-private/util/sketches"], function (_exports, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.task = _exports.destroyTasks = _exports.build = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var __ember_cli_sketch_task = '__ember_cli_sketch_task';
  var __task_ = '__task_';

  var build = function build(opts) {
    var _Ember;

    var deps = opts.deps,
        fn = opts.fn,
        identityFn = opts.identityFn;
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(deps).concat([function (key) {
      if (this.isDestroying) {
        return;
      }

      var identity = identityFn && identityFn.call(this, this);

      var _key = "".concat(__task_).concat(key);

      var task = this[_key];

      if (task) {
        if (identity !== undefined && task.opts.identity === identity) {
          return task;
        }

        task.destroy();
      }

      task = (0, _sketches.default)(this).factory.task(this, {
        fn: fn,
        identity: identity,
        delay: 50
      });
      this[_key] = task;
      return task;
    }])).meta(_defineProperty({}, __ember_cli_sketch_task, true));
  };

  _exports.build = build;

  var task = function task() {
    for (var _len = arguments.length, deps = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
      deps[_key2] = arguments[_key2];
    }

    var fn = deps.pop();
    var prop = build({
      deps: deps,
      fn: fn
    });

    prop.cached = function (identityFn) {
      return build({
        deps: deps,
        fn: fn,
        identityFn: identityFn
      });
    };

    return prop;
  };

  _exports.task = task;

  var destroyTasks = function destroyTasks(owner) {
    return owner.constructor.eachComputedProperty(function (key, meta) {
      if (meta[__ember_cli_sketch_task] !== true) {
        return;
      }

      var _key = "".concat(__task_).concat(key);

      var task = owner[_key];

      if (!task) {
        return;
      }

      task.destroy();
    });
  };

  _exports.destroyTasks = destroyTasks;
});