define("design/components/ui-input/color-picker/component", ["exports", "design/components/ui-input/color-picker/template", "tinycolor"], function (_exports, _template, _tinycolor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var components = function components(value, ratio) {
    var hsv = (0, _tinycolor.default)(value).toHsv();
    var x = hsv.s * ratio;
    var y = ratio - hsv.v * ratio;
    var h = hsv.h / 360 * ratio;
    return {
      value: value,
      hsv: hsv,
      x: x,
      y: y,
      h: h
    };
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-input-color-picker'],
    ratio: 200,
    value: '#ffffff',
    components: Ember.computed('value', '_components', function () {
      var value = this.value,
          ratio = this.ratio,
          _components = this._components;

      if (_components && _components.value === value) {
        return _components;
      }

      return components(value, ratio);
    }).readOnly(),
    colorStyle: Ember.computed('components', function () {
      var h = this.components.hsv.h;

      var rgb = _tinycolor.default.fromRatio({
        h: h,
        s: 1,
        v: 1
      }).toRgbString();

      return Ember.String.htmlSafe("background-color: ".concat(rgb));
    }).readOnly(),
    actions: {
      updateColor: function updateColor(_ref) {
        var x = _ref.x,
            y = _ref.y;
        var ratio = this.ratio;
        var hsv = (0, _tinycolor.default)(this.value).toHsv();
        var s = x / ratio;
        var v = 1 - y / ratio;

        var value = _tinycolor.default.fromRatio({
          h: hsv.h,
          s: s,
          v: v
        }).toHexString();

        this._update({
          value: value,
          hsv: hsv,
          x: x,
          y: y
        });
      },
      updateHue: function updateHue(_ref2) {
        var y = _ref2.y;
        var ratio = this.ratio;
        var hsv = (0, _tinycolor.default)(this.value).toHsv();
        var h = y / ratio;

        var value = _tinycolor.default.fromRatio({
          h: h,
          s: hsv.s,
          v: hsv.v
        }).toHexString();

        this._update({
          value: value,
          hsv: hsv,
          h: y
        });
      },
      updateHex: function updateHex(value) {
        var tiny = (0, _tinycolor.default)(value);

        if (!tiny.isValid()) {
          return;
        }

        value = tiny.toHexString();
        this.update(value);
      },
      reset: function reset() {
        this._update({
          value: '',
          x: 0,
          y: 0
        });
      }
    },
    _update: function _update(hash) {
      if (this._components) {
        this.set('_components', Ember.assign({}, this._components, hash));
      }

      this.update(hash.value);
    }
  });

  _exports.default = _default;
});