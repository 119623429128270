define("design/components/ui-block/tooltip/component", ["exports", "design/components/ui-block/tooltip/template", "design/util/hover-mixin"], function (_exports, _template, _hoverMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hoverMixin.default, {
    classNameBindings: [':ui-block-tooltip'],
    layout: _template.default
  });

  _exports.default = _default;
});