define("design/models/validation/validator/instance", ["exports", "design/models/validation/validator/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isInstance = function isInstance(value) {
    return Ember.typeOf(value) === 'instance';
  };

  var _default = _base.default.extend({
    validate: function validate(value, _ref) {
      var required = _ref.required;

      if (!required && !value) {
        return {
          valid: true,
          blank: true
        };
      }

      if (isInstance(value)) {
        return {
          valid: true,
          blank: false
        };
      } else {
        return {
          valid: false,
          blank: !value,
          error: 'is required'
        };
      }
    }
  });

  _exports.default = _default;
});