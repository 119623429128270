define("ember-cli-zuglet/-private/lifecycle/observed/observer-for", ["exports", "ember-cli-zuglet/-private/util/destroyable"], function (_exports, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(owner, key) {
    var internal = (0, _destroyable.cacheFor)(owner, key);
    return internal && internal.getObserver();
  };

  _exports.default = _default;
});