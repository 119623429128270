define("ember-cli-zuglet/-private/lifecycle/observed/resolve-observers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return Ember.RSVP.all(args.map(function (prop) {
      return prop && Ember.get(prop, 'observers.promise');
    }));
  };

  _exports.default = _default;
});