define("ember-cli-zuglet/-private/storage/reference/metadata/metadata", ["exports", "ember-cli-zuglet/-private/storage/reference/base/model", "ember-cli-zuglet/-private/storage/reference/base/internal", "ember-cli-zuglet/-private/util/serialized", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _model, _internal, _serialized, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var raw = function raw() {
    return Ember.computed('raw', function (key) {
      var raw = this.get('raw');

      if (!raw) {
        return;
      }

      return raw[key];
    }).readOnly();
  };

  var rawDate = function rawDate(key) {
    return Ember.computed('raw', function () {
      var value = this.get("raw.".concat(key));

      if (!value) {
        return;
      }

      return new Date(value);
    }).readOnly();
  };

  var _default = _model.default.extend({
    raw: Ember.computed.readOnly('_internal._metadata'),
    type: raw(),
    name: raw(),
    size: raw(),
    contentType: raw(),
    customMetadata: raw(),
    cacheControl: raw(),
    contentDisposition: raw(),
    contentEncoding: raw(),
    contentLanguage: raw(),
    bucket: raw(),
    fullPath: raw(),
    generation: raw(),
    md5Hash: raw(),
    metageneration: raw(),
    createdAt: rawDate('timeCreated'),
    updatedAt: rawDate('updated'),
    // { ... }
    update: (0, _invoke.invokePromiseReturningThis)('update'),
    serialized: (0, _serialized.default)([].concat(_toConsumableArray(_internal.state), ['name', 'size', 'contentType', 'customMetadata']), ['exists'])
  });

  _exports.default = _default;
});