define("ember-cli-sketch/-private/stage/tools/node/edit", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    node: null,
    sticky: true,
    isMouseOver: function isMouseOver() {
      var node = this.node,
          absolute = this.mouse.absolute;
      return node.frame.containsPosition(absolute, 'absoluteBounds');
    },
    onMouseClick: function onMouseClick() {
      if (this.isMouseOver()) {
        return;
      }

      this.done();
    },
    activate: function activate(_ref) {
      var node = _ref.node;

      if (!node || !node.isEditable) {
        this.done();
        return;
      }

      this.set('node', node);
    },
    deactivate: function deactivate() {
      this.reset();
    },
    reset: function reset() {
      this.set('node', null);
    }
  });

  _exports.default = _default;
});