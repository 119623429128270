define("design/components/ui-input/color-picker/marker/component", ["exports", "design/components/ui-input/color-picker/marker/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    attributeBindings: ['style'],
    x: 0,
    y: 0,
    style: Ember.computed('x', 'y', function () {
      var x = this.x,
          y = this.y;
      return Ember.String.htmlSafe("transform: translate(".concat(x, "px, ").concat(y, "px)"));
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.container.set('marker', this);
    }
  });

  _exports.default = _default;
});