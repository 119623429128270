define("ember-cli-sketch/-private/actions/stage/center", ["exports", "ember-cli-sketch/-private/actions/stage/-center-fit"], function (_exports, _centerFit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _centerFit.default.extend({
    perform: function perform(stage, opts) {
      opts = Ember.assign({}, opts);
      var size = stage.renderer.size,
          zoom = stage.zoom;

      if (!size) {
        return;
      }

      var frame = this.frameForOptions(stage, opts);

      if (!frame) {
        return;
      }

      var bounds = frame.hover;

      if (!bounds) {
        return;
      }

      var dimension = function dimension(dimensionKey, sizeKey) {
        var value = opts[dimensionKey];

        if (value) {
          return value;
        }

        var base = stage.frame[dimensionKey] - bounds[dimensionKey] / zoom;
        var offset = (size[sizeKey] - bounds[sizeKey]) / 2 / zoom;
        return base + offset;
      };

      var position = {
        x: dimension('x', 'width'),
        y: dimension('y', 'height')
      };
      stage.update(position);
    }
  });

  _exports.default = _default;
});