define("design/components/ui-input/dropdown/item/pair/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znjbzIjh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"item\",\"title\",\"description\"],[[24,[\"item\"]],[28,\"component\",[\"ui-div\"],[[\"class\"],[\"title\"]]],[28,\"component\",[\"ui-div\"],[[\"class\"],[\"description\"]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/dropdown/item/pair/template.hbs"
    }
  });

  _exports.default = _default;
});