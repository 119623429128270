define("ember-cli-sketch/components/ui-block/sketch/stage/hovers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ar/lhpDf",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"each\",[[24,[\"stage\",\"node\",\"hover\",\"deselected\"]]],[[\"key\"],[\"isVisible\"]],{\"statements\":[[4,\"if\",[[23,1,[\"isVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/sketch/stage/hovers/hover\",null,[[\"model\"],[[23,1,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/hovers/template.hbs"
    }
  });

  _exports.default = _default;
});