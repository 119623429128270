define("ember-cli-sketch/-private/stage/interactions/handlers/tools", ["exports", "ember-cli-sketch/-private/stage/interactions/handlers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mapping = {
    isSpace: 'stage/drag',
    isAlt: 'stage/zoom'
  };

  var map = function map(key) {
    for (var prop in mapping) {
      if (key[prop] === true) {
        return mapping[prop];
      }
    }
  };

  var _default = _base.default.extend({
    tools: Ember.computed.readOnly('stage.tools'),
    onKeyDown: function onKeyDown(key) {
      // TODO: isSpace and isAlt should be handled in selected tool instead of this sticky thing
      if (!this.tools.selected.sticky) {
        var tool = map(key);

        if (tool) {
          this.tools.activate(tool);
        }
      }

      this.tools.selected.onKeyDown(key);
    },
    onKeyUp: function onKeyUp(key) {
      if (!this.tools.selected.sticky) {
        var tool = map(key);

        if (tool) {
          this.tools.deactivate(tool);
        }
      }

      if (key.isEscape) {
        this.tools.activate(this.tools.default.type);
      }

      this.tools.selected.onKeyUp(key);
    },
    onMouseDown: function onMouseDown() {
      var _this$tools$selected;

      return (_this$tools$selected = this.tools.selected).onMouseDown.apply(_this$tools$selected, arguments);
    },
    onMouseUp: function onMouseUp() {
      var _this$tools$selected2;

      return (_this$tools$selected2 = this.tools.selected).onMouseUp.apply(_this$tools$selected2, arguments);
    },
    onMouseClick: function onMouseClick() {
      var _this$tools$selected3;

      return (_this$tools$selected3 = this.tools.selected).onMouseClick.apply(_this$tools$selected3, arguments);
    },
    onMouseDoubleClick: function onMouseDoubleClick() {
      var _this$tools$selected4;

      return (_this$tools$selected4 = this.tools.selected).onMouseDoubleClick.apply(_this$tools$selected4, arguments);
    },
    onMouseMove: function onMouseMove() {
      var _this$tools$selected5;

      return (_this$tools$selected5 = this.tools.selected).onMouseMove.apply(_this$tools$selected5, arguments);
    },
    onMouseWheel: function onMouseWheel() {
      var _this$tools$selected6;

      return (_this$tools$selected6 = this.tools.selected).onMouseWheel.apply(_this$tools$selected6, arguments);
    }
  });

  _exports.default = _default;
});