define("ember-cli-sketch/components/ui-block/sketch/stage/node/rect/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/node/-component", "ember-cli-sketch/components/ui-block/sketch/stage/node/rect/template", "ember-cli-sketch/components/ui-block/sketch/stage/node/-computed"], function (_exports, _component, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    style: (0, _computed.style)('model.{fill,opacity}', function () {
      var _this$model = this.model,
          background = _this$model.fill,
          opacity = _this$model.opacity;
      return {
        background: background,
        opacity: opacity
      };
    })
  });

  _exports.default = _default;
});