define("ember-cli-sketch/-private/sketches/factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    opts: null,
    node: function node(model, opts) {
      (false && !(!!model) && Ember.assert("model is required", !!model));
      (false && !(!!opts) && Ember.assert("opts are required", !!opts));
      var identifier = opts.identifier,
          type = opts.type,
          properties = opts.properties;
      (false && !(!!identifier) && Ember.assert("identifier is required", !!identifier));
      (false && !(!!type) && Ember.assert("type is required", !!type));
      var owner = Ember.getOwner(this);
      var fullName = "sketch:node/".concat(identifier, "/").concat(type);
      var factory = owner.factoryFor(fullName);

      if (!factory) {
        factory = owner.factoryFor("sketch:factory/node/".concat(type));
        (false && !(!!factory) && Ember.assert("node factory for type '".concat(type, "' is not registered"), !!factory));
        factory = factory.class;
        owner.register(fullName, factory({
          identifier: identifier,
          type: type,
          properties: properties
        }));
        factory = owner.factoryFor(fullName);
      }

      var sketches = this.sketches;
      return factory.create({
        sketches: sketches,
        model: model,
        opts: opts
      });
    },
    _create: function _create(name, props) {
      var fullName = "sketch:".concat(name);
      var factory = Ember.getOwner(this).factoryFor(fullName);
      (false && !(!!factory) && Ember.assert("".concat(fullName, " is not registered"), !!factory));
      return factory.create(props);
    },
    edge: function edge(node) {
      return this._create("node/edge", {
        node: node
      });
    },
    attributes: function attributes(node, opts) {
      (false && !(!!node) && Ember.assert("node is required", !!node));
      (false && !(!!opts) && Ember.assert("opts are required", !!opts));
      var identifier = opts.identifier,
          type = opts.type;
      (false && !(!!identifier) && Ember.assert("identifier is required", !!identifier));
      (false && !(!!type) && Ember.assert("type is required", !!type));
      var owner = Ember.getOwner(this);
      var fullName = "sketch:node/".concat(identifier, "/").concat(type, "/attributes");
      var factory = owner.factoryFor(fullName);

      if (!factory) {
        factory = owner.factoryFor("sketch:factory/attributes");
        factory = factory.class;
        owner.register(fullName, factory({
          modelClass: node.model.constructor
        }));
        factory = owner.factoryFor(fullName);
      }

      return factory.create({
        node: node
      });
    },
    attribute: function attribute(attributes, type, opts) {
      return this._create("node/attribute/".concat(type), {
        attributes: attributes,
        opts: opts
      });
    },
    frame: function frame(type, parent) {
      return this._create("frame/".concat(type), {
        parent: parent
      });
    },
    nodes: function nodes(parent) {
      return this._create('node/nodes', {
        parent: parent
      });
    },
    typedNodes: function typedNodes(type, nodes) {
      return this._create("node/nodes/".concat(type), {
        nodes: nodes
      });
    },
    renderer: function renderer(stage) {
      return this._create('stage/renderer', {
        stage: stage
      });
    },
    cursor: function cursor(stage) {
      return this._create('stage/cursor', {
        stage: stage
      });
    },
    stageGuidelines: function stageGuidelines(stage) {
      return this._create('stage/guidelines', {
        stage: stage
      });
    },
    guidelinesPair: function guidelinesPair(guidelines, source, target) {
      return this._create('node/guidelines/pair', {
        guidelines: guidelines,
        source: source,
        target: target
      });
    },
    nodeGuidelines: function nodeGuidelines(node, type, opts) {
      return this._create("node/guidelines/types/".concat(type), {
        node: node,
        opts: opts
      });
    },
    hover: function hover(stage) {
      return this._create('stage/hover', {
        stage: stage
      });
    },
    dragging: function dragging(stage) {
      return this._create('stage/dragging', {
        stage: stage
      });
    },
    selection: function selection(stage) {
      return this._create('stage/selection', {
        stage: stage
      });
    },
    tools: function tools(stage) {
      var types = this.opts.tools;
      return this._create('stage/tools', {
        stage: stage,
        types: types
      });
    },
    tool: function tool(type, tools) {
      return this._create("stage/tools/".concat(type), {
        type: type,
        tools: tools
      });
    },
    interactions: function interactions(stage) {
      return this._create('stage/interactions', {
        stage: stage
      });
    },
    interactionMedium: function interactionMedium(type, interactions) {
      return this._create("stage/interactions/mediums/".concat(type), {
        interactions: interactions
      });
    },
    interationsKeyboardKey: function interationsKeyboardKey(opts) {
      return this._create('stage/interactions/mediums/keyboard/key', opts);
    },
    interactionHandlers: function interactionHandlers(interactions) {
      var types = this.opts.interactions;
      return this._create('stage/interactions/handlers', {
        interactions: interactions,
        types: types
      });
    },
    interactionHandler: function interactionHandler(type, handlers) {
      return this._create("stage/interactions/handlers/".concat(type), {
        handlers: handlers
      });
    },
    fonts: function fonts(sketches) {
      var types = this.opts.fonts;
      return this._create('sketches/fonts', {
        sketches: sketches,
        types: types
      });
    },
    fontsLoader: function fontsLoader(fonts, opts) {
      return this._create('sketches/fonts/loader', {
        fonts: fonts,
        opts: opts
      });
    },
    fontLoaderCompound: function fontLoaderCompound(fonts, loaders) {
      return this._create('sketches/fonts/loader/compound', {
        fonts: fonts,
        loaders: loaders
      });
    },
    actions: function actions(sketches) {
      var types = this.opts.actions;
      return this._create('sketches/actions', {
        sketches: sketches,
        types: types
      });
    },
    action: function action(type, actions) {
      return this._create("actions/".concat(type), {
        type: type,
        actions: actions
      });
    },
    task: function task(owner, opts) {
      return this._create('task', {
        owner: owner,
        opts: opts
      });
    },
    strings: function strings(stage) {
      return this._create('strings', {
        stage: stage
      });
    }
  });

  _exports.default = _default;
});