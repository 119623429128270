define("ember-cli-sketch/-private/computed/attrs", ["exports", "ember-cli-sketch/-private/computed/attr"], function (_exports, _attr2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(targetFn) {
    var attr = function attr(target, opts) {
      return (0, _attr2.default)(targetFn(target), opts);
    };

    var position = function position(target, opts) {
      return attr(target, Ember.assign({
        type: 'number',
        initial: 0,
        decimals: 1
      }, opts));
    };

    var size = function size(target, opts) {
      return attr(target, Ember.assign({
        type: 'number',
        min: 0,
        decimals: 1
      }, opts));
    };

    return {
      attr: attr,
      position: position,
      size: size
    };
  };

  _exports.default = _default;
});