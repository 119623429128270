define("ember-cli-sketch/components/ui-block/sketch/stage/node/-component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/-computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-stage-node', 'model.node.type'],
    attributeBindings: ['style'],
    frame: (0, _computed.frame)('model', 'rounded'),
    style: (0, _computed.style)('frame', function (_ref) {
      var frame = _ref.frame;
      return frame;
    }),
    zoom: Ember.computed.readOnly('model.node.stage.zoom')
  });

  _exports.default = _default;
});