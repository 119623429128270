define("ember-cli-zuglet/-private/data/internal/model-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isModel = _exports.default = void 0;

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var key = '_isZugletDataModel';

  var isModel = function isModel(arg) {
    return arg && Ember.get(arg, key) === true;
  };

  _exports.isModel = isModel;

  var _default = Ember.Mixin.create((_Ember$Mixin$create = {}, _defineProperty(_Ember$Mixin$create, key, true), _defineProperty(_Ember$Mixin$create, "serialized", Ember.computed(function () {
    return this.serialize('preview');
  }).readOnly()), _defineProperty(_Ember$Mixin$create, "serialize", function serialize(type) {
    return this._internal.serialize(type);
  }), _Ember$Mixin$create));

  _exports.default = _default;
});