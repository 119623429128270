define("design/components/ui-block/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvwByqle",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"content\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"value\"],[11,\"style\",[22,\"valueStyle\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-input/color-picker\",null,[[\"value\",\"update\",\"class\"],[[24,[\"value\"]],[24,[\"update\"]],\"popup\"]]],false],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"overlay\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/color-picker/template.hbs"
    }
  });

  _exports.default = _default;
});