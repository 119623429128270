define("ember-cli-zuglet/-private/data/internal/serializer", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    manager: null,
    factoryFor: function factoryFor(name) {
      return this.manager.factoryFor(name);
    },
    commit: function commit(internal) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.deserialize(internal, data, true);
    },
    rollback: function rollback(internal) {
      var data = internal.get('raw');

      if (!data) {
        return;
      }

      this.deserialize(internal, data, false);
    }
  });

  _exports.default = _default;
});