define("ember-cli-sketch/-private/sketches/actions", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var all = function all() {
    return (0, _computed.factory)(function (factory, actions) {
      return Ember.A(actions.types.map(function (type) {
        return factory.action(type, actions);
      }));
    });
  };

  var _default = Ember.Object.extend({
    all: all(),
    action: function action(node, name) {
      var type = node.type;
      var action = this.all.findBy('type', "".concat(type, "/").concat(name));

      if (!action) {
        action = this.all.findBy('type', "node/".concat(name));
      }

      (false && !(!!action) && Ember.assert("action '".concat(type, "/").concat(name, "' is not registered"), !!action));
      return action;
    },
    perform: function perform(node, name) {
      var action = this.action(node, name);

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return action.perform.apply(action, [node].concat(args));
    }
  });

  _exports.default = _default;
});