define("ember-cli-zuglet/-private/stores/stores", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _modelMixin, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this._internal = Ember.getOwner(this).factoryFor('zuglet:stores/internal').create({
        _model: this
      });
    },
    ready: Ember.computed('_internal.stores.[]', function () {
      return this._internal.ready();
    }).readOnly(),
    createStore: (0, _invoke.invokeReturningModel)('createStore'),
    settle: (0, _invoke.invokePromiseReturningThis)('settle')
  });

  _exports.default = _default;
});