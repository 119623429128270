define("ember-cli-zuglet/-private/lifecycle/route/index", ["exports", "ember-cli-zuglet/-private/lifecycle/route/property", "ember-cli-zuglet/-private/lifecycle/util/inline"], function (_exports, _property, _inline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var build = function build(opts) {
    var nested = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    opts = Ember.assign({}, opts, nested);
    var prop = (0, _property.default)(opts);

    prop.inline = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return build(opts, {
        inline: (0, _inline.default)(args)
      });
    };

    prop.named = function (arg) {
      return build(opts, {
        named: arg
      });
    };

    prop.mapping = function (arg) {
      return build(opts, {
        mapping: arg
      });
    };

    return prop;
  };

  var _default = function _default() {
    var opts = {
      inline: undefined,
      named: undefined,
      mapping: undefined
    };
    return build(opts, {});
  };

  _exports.default = _default;
});