define("ember-cli-sketch/-private/node/guidelines/pair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var guidelines = function guidelines(key) {
    return Ember.computed.readOnly("".concat(key, ".guidelines"));
  };

  var _delta = function _delta(source, target, zoom) {
    return (target - source) / zoom;
  };

  var _approx = function _approx(a, b, approx) {
    return a - approx < b && a + approx > b;
  };

  var _default = Ember.Object.extend({
    guidelines: null,
    zoom: Ember.computed.readOnly('guidelines.zoom'),
    approx: Ember.computed.readOnly('guidelines.approx'),
    source: null,
    // node
    target: null,
    // node
    _source: guidelines('source'),
    _target: guidelines('target'),
    matches: Ember.computed('_source.frame', '_target.frame', function () {
      var _source = this._source,
          _target = this._target;

      if (!_source || !_target) {
        return;
      }

      var _source$frame = _source.frame,
          sx = _source$frame.x,
          sy = _source$frame.y,
          sw = _source$frame.width,
          sh = _source$frame.height;
      var _target$frame = _target.frame,
          tx = _target$frame.x,
          ty = _target$frame.y,
          tw = _target$frame.width,
          th = _target$frame.height;
      var tyh = ty + th;
      var txw = tx + tw;
      var syh = sy + sh;
      var sxw = sx + sw;
      return sy >= ty && sy <= tyh || syh >= ty && syh <= tyh || sx >= tx && sx <= txw || sxw >= tx && sxw <= txw;
    }).readOnly(),
    _recomputeLinesForDirection: function _recomputeLinesForDirection(source, target, direction, positionKey, approx) {
      var zoom = this.zoom;
      var lines = [];
      source.points[direction].forEach(function (sourcePoint) {
        target.points[direction].forEach(function (targetPoint) {
          if (sourcePoint === targetPoint) {
            lines.push(_defineProperty({
              direction: direction
            }, positionKey, sourcePoint));
          } else if (approx && _approx(sourcePoint, targetPoint, approx)) {
            var _lines$push2;

            var delta = _delta(sourcePoint, targetPoint, zoom);

            lines.push((_lines$push2 = {
              direction: direction
            }, _defineProperty(_lines$push2, positionKey, targetPoint), _defineProperty(_lines$push2, "delta", delta), _defineProperty(_lines$push2, "approx", true), _lines$push2));
          }
        });
      });
      return lines;
    },
    _recompute: function _recompute() {
      var source = this._source,
          target = this._target,
          approx = this.approx;
      var array = [].concat(_toConsumableArray(this._recomputeLinesForDirection(source, target, 'horizontal', 'y', approx)), _toConsumableArray(this._recomputeLinesForDirection(source, target, 'vertical', 'x', approx)));

      var minmax = function minmax(_ref, _ref2, positionKey, sizeKey) {
        var source = _ref.frame;
        var target = _ref2.frame;
        var min = Math.min(source[positionKey], target[positionKey]);
        var max = Math.max(source[positionKey] + source[sizeKey], target[positionKey] + target[sizeKey]) - min;
        return [min, max];
      };

      var _minmax = minmax(source, target, 'x', 'width'),
          _minmax2 = _slicedToArray(_minmax, 2),
          hx = _minmax2[0],
          hl = _minmax2[1];

      var _minmax3 = minmax(source, target, 'y', 'height'),
          _minmax4 = _slicedToArray(_minmax3, 2),
          vy = _minmax4[0],
          vl = _minmax4[1];

      return array.map(function (def) {
        var direction = def.direction,
            x = def.x,
            y = def.y,
            approx = def.approx,
            delta = def.delta;
        var length;

        if (direction === 'horizontal') {
          x = hx;
          length = hl;
        } else if (direction === 'vertical') {
          y = vy;
          length = vl;
        }

        return {
          direction: direction,
          x: x,
          y: y,
          length: length,
          approx: approx,
          delta: delta
        };
      });
    },
    matched: Ember.computed('matches', 'approx', function () {
      var matches = this.matches;

      if (!matches) {
        return;
      }

      return this._recompute();
    }).readOnly()
  });

  _exports.default = _default;
});