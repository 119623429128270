define("ember-cli-zuglet/-private/transaction/transaction", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    load: function load(doc, opts) {
      return this._internal.load(doc._internal, opts).then(function (internal) {
        return internal.model(true);
      });
    },
    save: function save(doc, opts) {
      this._internal.save(doc._internal, opts);
    },
    delete: function _delete(doc) {
      this._internal.delete(doc._internal);
    }
  });

  _exports.default = _default;
});