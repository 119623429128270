define("ember-cli-zuglet/lifecycle/index", ["exports", "ember-cli-zuglet/lifecycle/models", "ember-cli-zuglet/lifecycle/model", "ember-cli-zuglet/lifecycle/route", "ember-cli-zuglet/lifecycle/observed"], function (_exports, _models, _model, _route, _observed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "model", {
    enumerable: true,
    get: function get() {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "models", {
    enumerable: true,
    get: function get() {
      return _models.default;
    }
  });
  Object.defineProperty(_exports, "observed", {
    enumerable: true,
    get: function get() {
      return _observed.default;
    }
  });
  Object.defineProperty(_exports, "observerFor", {
    enumerable: true,
    get: function get() {
      return _observed.observerFor;
    }
  });
  Object.defineProperty(_exports, "resolveObservers", {
    enumerable: true,
    get: function get() {
      return _observed.resolveObservers;
    }
  });
  Object.defineProperty(_exports, "route", {
    enumerable: true,
    get: function get() {
      return _route.default;
    }
  });
});