define("ember-cli-zuglet/-private/query/array/query", ["exports", "ember-cli-zuglet/-private/query/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _query.default.extend({
    content: Ember.computed.readOnly('_internal.proxy')
  });

  _exports.default = _default;
});