define("design/models/validation/validation", ["exports", "ember-cli-zuglet/lifecycle", "design/util/computed", "design/util/object"], function (_exports, _lifecycle, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var createProperty = function createProperty(validation, key, opts) {
    return (0, _lifecycle.model)().named("validation/validator/".concat(opts.type)).mapping(function () {
      return {
        validation: validation,
        key: key,
        opts: opts
      };
    });
  };

  var _default = Ember.Object.extend({
    owner: null,
    opts: null,
    keys: (0, _computed.keys)('opts'),
    properties: Ember.computed(function () {
      var _this = this;

      return this.keys.map(function (key) {
        return _this[key];
      });
    }).readOnly(),
    isValid: Ember.computed('properties.@each.isValid', function () {
      var properties = this.properties;
      return !properties.findBy('isValid', false);
    }).readOnly(),
    isInvalid: Ember.computed.not('isValid'),
    prepare: function prepare(_ref) {
      var _this2 = this;

      var owner = _ref.owner,
          opts = _ref.opts;
      this.setProperties({
        owner: owner,
        opts: opts
      });
      (0, _object.forEach)(opts, function (key, opts) {
        return Ember.defineProperty(_this2, key, createProperty(_this2, key, opts));
      });
    },
    touch: function touch() {
      this.properties.forEach(function (prop) {
        return prop.touch();
      });
    }
  });

  _exports.default = _default;
});