define("ember-cli-sketch/-private/stage/interactions/mediums/mouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var button = function button(value) {
    return Ember.computed.equal('button', value).readOnly();
  };

  var over = function over(value) {
    return Ember.computed.equal('over', value).readOnly();
  };

  var state = function state(value) {
    return Ember.computed.equal('state', value).readOnly();
  };

  var _default = Ember.Object.extend({
    state: 'up',
    button: null,
    over: null,
    stage: null,
    absolute: Ember.computed('stage', function () {
      var stage = this.stage;

      if (!stage) {
        return;
      }

      return this.interactions.stage.frame.convertPointFromScreen(stage);
    }).readOnly(),
    isLeftButton: button(0),
    isOverStage: over('stage'),
    isLeftButtonOverStage: Ember.computed.and('isLeftButton', 'isOverStage'),
    isDown: state('down'),
    onMouseOver: function onMouseOver(_ref) {
      var over = _ref.over;
      this.setProperties({
        over: over
      });
    },
    onMouseOut: function onMouseOut(_ref2) {
      var window = _ref2.window;

      if (window) {
        this.setProperties({
          over: null
        });
      }
    },
    onMouseDown: function onMouseDown(_ref3) {
      var button = _ref3.button;
      this.setProperties({
        state: 'down',
        button: button
      });
    },
    onMouseMove: function onMouseMove(_ref4) {
      var stage = _ref4.stage;
      this.setProperties({
        stage: stage
      });
    },
    onMouseClick: function onMouseClick() {},
    onMouseDoubleClick: function onMouseDoubleClick() {},
    onMouseUp: function onMouseUp() {
      this.setProperties({
        state: 'up',
        button: null
      });
    },
    onMouseWheel: function onMouseWheel() {},
    reset: function reset() {
      this.setProperties({
        state: 'up',
        button: null,
        over: null,
        stage: null
      });
    }
  });

  _exports.default = _default;
});