define("ember-cli-sketch/-private/task/index", ["exports", "ember-cli-sketch/-private/task/computed", "ember-cli-sketch/-private/task/mixin"], function (_exports, _computed, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "destroyTasks", {
    enumerable: true,
    get: function get() {
      return _computed.destroyTasks;
    }
  });
  Object.defineProperty(_exports, "task", {
    enumerable: true,
    get: function get() {
      return _computed.task;
    }
  });
  var _default = _mixin.default;
  _exports.default = _default;
});