define("ember-cli-zuglet/-private/batch/batch", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    save: function save(doc, opts) {
      return this._internal.save(doc._internal, opts);
    },
    delete: function _delete(doc) {
      return this._internal.delete(doc._internal);
    },
    commit: function commit() {
      return this._internal.commit();
    }
  });

  _exports.default = _default;
});