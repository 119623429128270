define("ember-cli-zuglet/-private/functions/callable/callable", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    name: Ember.computed.readOnly('_internal.name'),
    functions: Ember.computed(function () {
      return this._internal.functions.model(true);
    }).readOnly(),
    call: function call(opts) {
      return this._internal.call(opts);
    }
  });

  _exports.default = _default;
});