define("design/components/ui-block/flow/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fxWtcuQV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"type\",\"value\",\"action\",\"disabled\"],[\"action\",\"small\",\"outline\",[28,\"t\",[\"block.flow.navigation.previous\"],null],[28,\"action\",[[23,0,[]],\"previous\"],null],[28,\"or\",[[28,\"not\",[[24,[\"previous\",\"enabled\"]]],null],[24,[\"disabled\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.flow.navigation.label\"],[[\"number\",\"total\"],[[24,[\"number\"]],[24,[\"total\"]]]]],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"value\",\"action\",\"disabled\"],[\"action\",\"small\",[28,\"t\",[\"block.flow.navigation.continue\"],null],[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"or\",[[28,\"not\",[[24,[\"next\",\"enabled\"]]],null],[24,[\"disabled\"]]],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/flow/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});