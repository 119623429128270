define("design/components/ui-block/fill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J6pf3Y4e",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"size\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[14,1,[[28,\"hash\",null,[[\"size\"],[[24,[\"size\"]]]]]]],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/fill/template.hbs"
    }
  });

  _exports.default = _default;
});