define("ember-cli-sketch/-private/util/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.diff = void 0;

  var diff = function diff(_ref) {
    var array = _ref.array,
        objects = _ref.objects,
        find = _ref.find,
        create = _ref.create,
        destroy = _ref.destroy;
    var remove = Ember.A(array.slice());
    var add = [];
    objects.forEach(function (item) {
      var model = find(item);

      if (model) {
        remove.removeObject(model);
      } else {
        model = create(item);
        add.push(model);
      }
    });
    array.removeObjects(remove);
    remove.forEach(function (model) {
      return destroy(model);
    });
    array.pushObjects(add);
    return add.length || remove.length;
  };

  _exports.diff = diff;
});