define("ember-cli-zuglet/-private/data/reference/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer", "ember-cli-zuglet/-private/util/firestore-types", "ember-cli-zuglet/-private/reference/types"], function (_exports, _serializer, _firestoreTypes, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isAnyReference = function isAnyReference(value) {
    if ((0, _firestoreTypes.isFirestoreDocumentOrCollectionReference)(value)) {
      return true;
    }

    if ((0, _types.isDocumentOrCollectionReference)(value)) {
      return true;
    }

    return false;
  };

  var _default = _serializer.default.extend({
    supports: function supports(value) {
      return isAnyReference(value);
    },
    matches: function matches(internal, value) {
      return isAnyReference(value);
    },
    toInternalReference: function toInternalReference(value) {
      if ((0, _firestoreTypes.isFirestoreDocumentReference)(value)) {
        return this.store.createInternalDocumentReferenceForReference(value);
      } else if ((0, _firestoreTypes.isFirestoreCollectionReference)(value)) {
        return this.store.createInternalCollectionReferenceForReference(value);
      }

      return (0, _types.toInternalDocumentOrCollectionReference)(value);
    },
    toFirestoreReference: function toFirestoreReference(value) {
      if ((0, _firestoreTypes.isFirestoreDocumentOrCollectionReference)(value)) {
        return value;
      }

      var internal = (0, _types.toInternalDocumentOrCollectionReference)(value);
      return internal.ref;
    },
    createInternal: function createInternal(value) {
      var content = this.toInternalReference(value);
      return this.factoryFor('zuglet:data/reference/internal').create({
        serializer: this,
        content: content
      });
    },
    serialize: function serialize(internal, type) {
      var content = internal.content;

      if (type === 'raw') {
        return content.get('ref');
      } else if (type === 'preview') {
        return "reference:".concat(content.get('path'));
      } else if (type === 'model') {
        return content.model(true);
      } else {
        (false && !(false) && Ember.assert("unsupported type '".concat(type, "'"), false));
      }
    },
    deserialize: function deserialize(internal, value) {
      var ref = this.toFirestoreReference(value);
      var replace = true;

      if (internal.content.ref.isEqual(ref)) {
        replace = false;
      } else {
        internal = this.createInternal(ref);
      }

      return {
        replace: replace,
        internal: internal
      };
    },
    isDirty: function isDirty(internal, value) {
      var contentRef = internal.content.ref;
      var valueRef = this.toFirestoreReference(value);
      return contentRef.path !== valueRef.path;
    }
  });

  _exports.default = _default;
});