define("design/components/ui-block/flow/steps/component", ["exports", "design/components/ui-block/flow/steps/template", "design/mixins/child-component"], function (_exports, _template, _childComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_childComponent.default, {
    layout: _template.default,
    classNameBindings: [':ui-block-flow-steps'],
    flow: null,
    componentHeight: 48 // 55px - 7px

  });

  _exports.default = _default;
});