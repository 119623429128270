define("design/components/ui-block/segmented-button/component", ["exports", "design/components/ui-block/segmented-button/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-segmented-button', '_size', '_type'],
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    })
  });

  _exports.default = _default;
});