define("design/models/validation/validator/number", ["exports", "design/models/validation/validator/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isNumber = function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  };

  var _default = _base.default.extend({
    validate: function validate(value, _ref) {
      var required = _ref.required;
      var empty = Ember.isEmpty(value);

      if (!required && empty) {
        return {
          valid: true,
          blank: true
        };
      }

      if (empty) {
        return {
          valid: false,
          blank: true,
          error: "cannot be blank"
        };
      }

      if (isNumber(value)) {
        return {
          valid: true,
          blank: false
        };
      } else {
        return {
          valid: false,
          blank: true,
          error: "must be number"
        };
      }
    }
  });

  _exports.default = _default;
});