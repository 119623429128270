define("ember-cli-sketch/components/ui-block/sketch/stage/node/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qT/AQ5fD",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"task\",\"image\"]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/deferred\",null,[[\"promise\"],[[24,[\"task\",\"promise\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/node/image/template.hbs"
    }
  });

  _exports.default = _default;
});