define("ember-cli-sketch/-private/task/task", ["exports", "ember-cli-sketch/-private/util/safe"], function (_exports, _safe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    owner: null,
    opts: null,
    promise: Ember.computed.readOnly('deferred.promise'),
    isBusy: true,
    isEmpty: true,
    error: null,
    status: Ember.computed('isBusy', 'isEmpty', 'error', function () {
      var isBusy = this.isBusy,
          isEmpty = this.isEmpty,
          error = this.error;

      if (isBusy) {
        return 'loading';
      }

      if (error) {
        return 'error';
      }

      if (isEmpty) {
        return 'empty';
      }

      return 'loaded';
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this.deferred = Ember.RSVP.defer();

      this._scheduleInvoke();
    },
    _didInvoke: (0, _safe.default)(function (result) {
      this.setProperties(Ember.assign({
        isBusy: false,
        isEmpty: !result
      }, result));
      this.deferred.resolve(this);
    }),
    _invokeDidFail: (0, _safe.default)(function (error) {
      this.setProperties({
        isBusy: false,
        error: error
      });
      this.deferred.reject(error);
    }),
    _invoke: (0, _safe.default)(function () {
      var _this = this;

      var fn = this.opts.fn,
          owner = this.owner;
      Ember.RSVP.resolve(fn.call(owner, this, owner)).then(function (result) {
        return _this._didInvoke(result);
      }, function (err) {
        return _this._invokeDidFail(err);
      });
    }),
    _scheduleInvoke: function _scheduleInvoke() {
      var _this2 = this;

      var delay = this.opts.delay;
      this.__scheduleInvoke = Ember.run.later(function () {
        return _this2._invoke();
      }, delay);
    },
    _cancel: function _cancel() {
      this.cancelled = true;
      var err = new Error('Task was cancelled');
      err.code = 'cancelled';
      this.deferred.reject(err);
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.__scheduleInvoke);

      this._cancel();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});