define("design/mixins/component-owner", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    registeredChildComponents: (0, _computed.array)(),
    registerChildComponent: function registerChildComponent(component) {
      this.registeredChildComponents.pushObject(component);
    },
    unregisterChildComponent: function unregisterChildComponent(component) {
      this.registeredChildComponents.removeObject(component);
    }
  });

  _exports.default = _default;
});