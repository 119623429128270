define("ember-cli-sketch/-private/actions/stage/reset", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    perform: function perform(stage) {
      stage.update({
        x: 0,
        y: 0,
        zoom: 1
      });
    }
  });

  _exports.default = _default;
});