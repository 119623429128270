define("ember-cli-zuglet/-private/util/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.operationDestroyedError = _exports.noObserversError = _exports.error = _exports.documentMissingError = void 0;

  var error = function error(message, code, opts) {
    var err = new Ember.Error(message);
    err.code = code;
    err.opts = opts;
    return err;
  };

  _exports.error = error;

  var zuglet = function zuglet(message, code, opts) {
    return error(message, "zuglet/".concat(code), opts);
  };

  var documentMissingError = function documentMissingError(opts) {
    return zuglet('Document does not exist', 'document-missing', opts);
  };

  _exports.documentMissingError = documentMissingError;

  var operationDestroyedError = function operationDestroyedError(opts) {
    return zuglet('Operation is destroyed', 'operation/destroyed', opts);
  };

  _exports.operationDestroyedError = operationDestroyedError;

  var noObserversError = function noObserversError(opts) {
    return zuglet('No observers registered', 'observers/none', opts);
  };

  _exports.noObserversError = noObserversError;
});