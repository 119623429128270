define("design/util/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = void 0;

  var chunk = function chunk(array) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (!array) {
      return;
    }

    var result = Ember.A();

    for (var i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }

    return result;
  };

  _exports.chunk = chunk;
});