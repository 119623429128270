define("ember-cli-zuglet/-private/reference/types", ["exports", "ember-cli-zuglet/-private/reference/document/document", "ember-cli-zuglet/-private/reference/document/internal", "ember-cli-zuglet/-private/reference/collection/collection", "ember-cli-zuglet/-private/reference/collection/internal"], function (_exports, _document, _internal, _collection, _internal2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toInternalDocumentOrCollectionReference = _exports.isInternalDocumentReference = _exports.isInternalDocumentOrCollectionReference = _exports.isInternalCollectionReference = _exports.isDocumentReference = _exports.isDocumentOrCollectionReference = _exports.isCollectionReference = void 0;

  var isDocumentReference = function isDocumentReference(value) {
    return _document.default.detectInstance(value);
  };

  _exports.isDocumentReference = isDocumentReference;

  var isCollectionReference = function isCollectionReference(value) {
    return _collection.default.detectInstance(value);
  };

  _exports.isCollectionReference = isCollectionReference;

  var isDocumentOrCollectionReference = function isDocumentOrCollectionReference(value) {
    return isDocumentReference(value) || isCollectionReference(value);
  };

  _exports.isDocumentOrCollectionReference = isDocumentOrCollectionReference;

  var isInternalDocumentReference = function isInternalDocumentReference(value) {
    return _internal.default.detectInstance(value);
  };

  _exports.isInternalDocumentReference = isInternalDocumentReference;

  var isInternalCollectionReference = function isInternalCollectionReference(value) {
    return _internal2.default.detectInstance(value);
  };

  _exports.isInternalCollectionReference = isInternalCollectionReference;

  var isInternalDocumentOrCollectionReference = function isInternalDocumentOrCollectionReference(value) {
    return isInternalDocumentReference(value) || isInternalCollectionReference(value);
  };

  _exports.isInternalDocumentOrCollectionReference = isInternalDocumentOrCollectionReference;

  var toInternalDocumentOrCollectionReference = function toInternalDocumentOrCollectionReference(value) {
    if (isDocumentOrCollectionReference(value)) {
      return value._internal;
    } else if (isInternalDocumentOrCollectionReference(value)) {
      return value;
    }
  };

  _exports.toInternalDocumentOrCollectionReference = toInternalDocumentOrCollectionReference;
});