define("ember-cli-sketch/-private/stage/tools/node/resize", ["exports", "ember-cli-sketch/-private/stage/tools/-base", "ember-cli-sketch/-private/util/math"], function (_exports, _base, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    guidelines: true,
    node: null,
    edge: null,
    calculateAspect: function calculateAspect() {
      var node = this.node,
          aspect = this.node.attributes.aspect;

      if (aspect.free) {
        return;
      }

      var shift = this.get('keyboard.isShift');

      if (shift) {
        return;
      }

      return node.aspect;
    },
    updateAspect: function updateAspect() {
      this.node.perform('aspect-update');
    },
    moveToContainer: function moveToContainer() {
      return this.node.perform('move-to-container');
    },
    rotatedPoint: function rotatedPoint() {
      var properties = this.properties;
      var rotation = -properties.rotation;
      return (0, _math.rotatePosition)(this.mouse.absolute, properties, rotation);
    },
    calculateDelta: function calculateDelta(target) {
      var source = this.point;
      var delta = {
        x: target.x - source.x,
        y: target.y - source.y
      };
      return delta;
    },
    pin: function pin(_pin, frame, properties) {
      var rotation = properties.rotation;

      var dim = function dim(o, s) {
        var value = _pin[o];

        if (value === 'min') {
          return 0;
        } else if (value === 'max') {
          return properties[s];
        }
      };

      var pos = {
        x: dim('x', 'width'),
        y: dim('y', 'height')
      };
      var initial = (0, _math.rotatePosition)(pos, properties, rotation);
      var updated = (0, _math.rotatePosition)(pos, frame, rotation);
      frame.x += initial.x - updated.x;
      frame.y += initial.y - updated.y;
      return frame;
    },
    update: function update() {
      var edge = this.edge,
          node = this.node;
      var point = this.rotatedPoint();
      var delta = this.calculateDelta(point);
      var aspect = this.calculateAspect();
      var properties = Ember.assign({}, this.properties);
      var pin = {
        x: 'min',
        y: 'min'
      };
      var attributes = node.attributes;

      if (edge.vertical === 'top') {
        var clamped = attributes.clamp('height', properties.height - delta.y);
        properties.y -= clamped - properties.height;
        properties.height = clamped;
      } else if (edge.vertical === 'bottom') {
        pin.y = 'max';

        var _clamped = attributes.clamp('height', properties.height + delta.y);

        properties.height = _clamped;
      }

      if (edge.horizontal === 'left') {
        var _clamped2 = attributes.clamp('width', properties.width - delta.x);

        properties.x -= _clamped2 - properties.width;
        properties.width = _clamped2;
      } else if (edge.horizontal === 'right') {
        pin.x = 'max';

        var _clamped3 = attributes.clamp('width', properties.width + delta.x);

        properties.width = _clamped3;
      }

      if (aspect) {
        if ((edge.horizontal === 'right' || edge.horizontal === 'left') && edge.vertical !== 'middle') {
          var value = attributes.clamp('height', properties.width / aspect);
          var diff = properties.height - value;
          properties.height = value;

          if (edge.vertical === 'top') {
            properties.y += diff;
          }
        } else {
          aspect = 0;
        }
      }

      this.pin(pin, properties, this.properties);
      node.update(properties); // TODO: children delta
      // delta since previous child update
      // node.nodes.all.forEach(node => node.update(children, { delta: true }));

      if (!aspect) {
        this.updateAspect();
      }

      if (this.moveToContainer()) {
        this.setup();
      }
    },
    onMouseMove: function onMouseMove() {
      var isLeftButton = this.mouse.isLeftButton;

      if (!isLeftButton) {
        return;
      }

      this.update();
    },
    onMouseUp: function onMouseUp() {
      this.done();
    },
    onKeyDown: function onKeyDown() {
      if (!this.free) {
        this.updateAspect();
      }
    },
    setup: function setup() {
      var node = this.node;
      var properties = node.frame.properties;
      this.setProperties({
        properties: properties
      });
      var point = this.rotatedPoint();
      this.setProperties({
        point: point
      });
    },
    activate: function activate(_ref) {
      var node = _ref.node;
      this.selection.removeExcept(node);
      var edge = node.edge.serialized;
      this.setProperties({
        node: node,
        edge: edge
      });
      this.setup();
    },
    deactivate: function deactivate() {
      this.reset();
    },
    reset: function reset() {
      this.setProperties({
        node: null,
        edge: null
      });
    }
  });

  _exports.default = _default;
});