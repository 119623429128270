define("ember-cli-sketch/components/ui-block/sketch/stage/-events-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    interactions: Ember.computed.readOnly('stage.node.interactions'),
    _withEvents: function _withEvents(cb) {
      var _this = this;

      var events = this._handlers;

      if (!events) {
        var wrap = function wrap(fn) {
          return function (e) {
            return Ember.run(function () {
              if (!_this.interactions || _this.isDestroying) {
                return;
              }

              return fn(e);
            });
          };
        };

        events = {
          blur: wrap(function (e) {
            return _this.onBlur(e);
          }),
          focus: wrap(function (e) {
            return _this.onFocus(e);
          }),
          resize: wrap(function (e) {
            return _this.onWindowResize(e);
          }),
          mouseover: wrap(function (e) {
            return _this.onMouseOver(e);
          }),
          mouseout: wrap(function (e) {
            return _this.onMouseOut(e);
          }),
          mousemove: wrap(function (e) {
            return _this.onMouseMove(e);
          }),
          mousedown: wrap(function (e) {
            return _this.onMouseDown(e);
          }),
          click: wrap(function (e) {
            return _this.onMouseClick(e);
          }),
          dblclick: wrap(function (e) {
            return _this.onMouseDoubleClick(e);
          }),
          mouseup: wrap(function (e) {
            return _this.onMouseUp(e);
          }),
          wheel: wrap(function (e) {
            return _this.onMouseWheel(e);
          }),
          keydown: wrap(function (e) {
            return _this.onKeyDown(e);
          }),
          keyup: wrap(function (e) {
            return _this.onKeyUp(e);
          }),
          selectstart: wrap(function (e) {
            return _this.onSelectStart(e);
          })
        };
        this._handlers = events;
      }

      for (var key in events) {
        cb(window, key, events[key]);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.elementSizeDidChange();

      this._withEvents(function (window, key, fn) {
        return window.addEventListener(key, fn);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._withEvents(function (window, key, fn) {
        return window.removeEventListener(key, fn);
      });
    },
    setDeltaMouse: function setDeltaMouse(e) {
      var x = e.screenX,
          y = e.screenY;
      this._deltaMouse = {
        x: x,
        y: y
      };
    },
    deltaMouse: function deltaMouse() {
      return this._deltaMouse || {
        x: 0,
        y: 0
      };
    },
    keysHashFromMouseEvent: function keysHashFromMouseEvent(e) {
      var alt = e.altKey,
          ctrl = e.ctrlKey,
          meta = e.metaKey;
      return {
        alt: alt,
        ctrl: ctrl,
        meta: meta
      };
    },
    elementSizeDidChange: function elementSizeDidChange() {
      var element = this.element;

      if (!element) {
        return;
      }

      var _element$getBoundingC = element.getBoundingClientRect(),
          width = _element$getBoundingC.width,
          height = _element$getBoundingC.height;

      this.set('elementSize', {
        width: width,
        height: height
      });
    },
    onWindowResize: function onWindowResize() {
      this.elementSizeDidChange();
    },
    onMouseDown: function onMouseDown(e) {
      var button = e.button;
      this.interactions.onMouseDown({
        button: button
      });
    },
    onMouseMove: function onMouseMove(e) {
      var screenX = e.screenX,
          screenY = e.screenY,
          clientX = e.clientX,
          clientY = e.clientY;
      var rect = this.element.getBoundingClientRect();
      var stage = {
        x: clientX - rect.left + window.scrollX,
        y: clientY - rect.top + window.scrollX
      };
      var delta = this.deltaMouse();
      delta = {
        x: screenX - delta.x,
        y: screenY - delta.y
      };
      var keys = this.keysHashFromMouseEvent(e);
      this.interactions.onMouseMove({
        stage: stage,
        delta: delta,
        keys: keys
      });
      this.setDeltaMouse(e);
    },
    onMouseUp: function onMouseUp(e) {
      var button = e.button;
      this.interactions.onMouseUp({
        button: button
      });
    },
    overFromMouseEvent: function overFromMouseEvent(e) {
      var element = this.element;
      var target = e.target;
      var stage = target === element || element.contains(e.target);

      if (stage) {
        return 'stage';
      }

      return 'body';
    },
    onMouseOver: function onMouseOver(e) {
      var over = this.overFromMouseEvent(e);
      this.interactions.onMouseOver({
        over: over
      });
    },
    onMouseOut: function onMouseOut(e) {
      var window = e.toElement === null;
      this.interactions.onMouseOut({
        window: window
      });
    },
    onMouseClick: function onMouseClick(e) {
      var button = e.button;
      this.interactions.onMouseClick({
        button: button
      });
    },
    onMouseDoubleClick: function onMouseDoubleClick(e) {
      var button = e.button;
      this.interactions.onMouseDoubleClick({
        button: button
      });
    },
    onMouseWheel: function onMouseWheel(e) {
      var deltaX = e.deltaX,
          deltaY = e.deltaY;
      var direction;
      var value;

      if (deltaX) {
        direction = 'x';
        value = Math.sign(deltaX);
      } else if (deltaY) {
        direction = 'y';
        value = Math.sign(deltaY);
      } else {
        return;
      }

      var keys = this.keysHashFromMouseEvent(e);
      this.interactions.onMouseWheel({
        direction: direction,
        value: value,
        keys: keys
      });
    },
    keysHashFromKeyboardEvent: function keysHashFromKeyboardEvent(e) {
      var keyCode = e.keyCode,
          key = e.key,
          _metaKey = e.metaKey;
      var body = document.activeElement === document.body;
      var hash = {
        keyCode: keyCode,
        key: key,
        body: body,
        _metaKey: _metaKey
      };
      return hash;
    },
    onKeyDown: function onKeyDown(e) {
      this.interactions.onKeyDown(this.keysHashFromKeyboardEvent(e));
    },
    onKeyUp: function onKeyUp(e) {
      this.interactions.onKeyUp(this.keysHashFromKeyboardEvent(e));
    },
    onBlur: function onBlur() {
      this.interactions.onBlur();
    },
    onFocus: function onFocus() {
      this.interactions.onFocus();
    },
    onSelectStart: function onSelectStart() {
      return false;
    },
    screenPointFromMouseEvent: function screenPointFromMouseEvent(e) {
      var clientX = e.clientX,
          clientY = e.clientY;
      var rect = this.element.getBoundingClientRect();
      return {
        x: clientX - rect.left + window.scrollX,
        y: clientY - rect.top + window.scrollX
      };
    }
  });

  _exports.default = _default;
});