define("ember-cli-zuglet/-private/util/thenable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(arg) {
    var lookup;

    if (typeof arg === 'string') {
      lookup = function lookup(owner) {
        return owner.get(arg);
      };
    } else {
      lookup = arg;
    }

    var wrap = function wrap(target) {
      return function () {
        var promise = lookup.call(this, this);
        var fn = promise[target];

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return fn.call.apply(fn, [promise].concat(args));
      };
    };

    return Ember.Mixin.create({
      then: wrap('then'),
      catch: wrap('catch'),
      finally: wrap('finally')
    });
  };

  _exports.default = _default;
});