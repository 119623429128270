define("ember-cli-zuglet/-private/auth/auth/auth", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    methods: Ember.computed(function () {
      return this.get('_internal.methods').model(true);
    }).readOnly(),
    user: Ember.computed('_internal.user', function () {
      var internal = this.get('_internal.user');
      return internal && internal.model(true);
    }).readOnly(),
    signOut: function signOut() {
      return this._internal.signOut();
    }
  });

  _exports.default = _default;
});