define("ember-cli-zuglet/-private/util/browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInIframe = void 0;

  var isInIframe = function isInIframe() {
    try {
      return window.self !== window.top;
    } catch (err) {
      return true;
    }
  };

  _exports.isInIframe = isInIframe;
});