define("ember-cli-zuglet/-private/storage/task/task", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/util/serialized", "ember-cli-zuglet/-private/util/thenable"], function (_exports, _modelMixin, _serialized, _thenable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var snapshot = ['bytesTransferred', 'totalBytes'];
  var task = ['type', 'percent', 'isRunning', 'isCompleted', 'isError', 'error'];
  var TaskPropertiesMixin = Ember.Mixin.create(task.reduce(function (hash, key) {
    hash[key] = Ember.computed.readOnly("_internal.".concat(key));
    return hash;
  }, {}));
  var SnapshotPropertiesMixin = Ember.Mixin.create(snapshot.reduce(function (hash, key) {
    hash[key] = Ember.computed('_internal.snapshot', function () {
      var snapshot = this.get('_internal.snapshot');

      if (!snapshot) {
        return;
      }

      return snapshot[key];
    }).readOnly();
    return hash;
  }, {}));
  var ThenableMixin = (0, _thenable.default)('promise');

  var _default = Ember.Object.extend(_modelMixin.default, TaskPropertiesMixin, SnapshotPropertiesMixin, ThenableMixin, {
    ref: Ember.computed(function () {
      return this._internal.ref.model(true);
    }).readOnly(),
    serialized: (0, _serialized.default)([].concat(task, snapshot)),
    promise: Ember.computed('_internal.promise', function () {
      return this.get('_internal.promise').then(function () {
        return undefined;
      });
    }).readOnly(),
    toStringExtension: function toStringExtension() {
      return this.get('ref.fullPath');
    }
  });

  _exports.default = _default;
});