define("design/components/ui-block/tabs/content/component", ["exports", "design/components/ui-block/tabs/content/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    isSelected: Ember.computed('id', 'selected', function () {
      var id = this.id,
          selected = this.selected;
      return id === selected;
    }).readOnly()
  });

  _exports.default = _default;
});