define("ember-cli-sketch/components/ui-block/sketch/stage/guidelines/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/guidelines/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-sketch-stage-guidelines'],
    guidelines: Ember.computed.readOnly('stage.node.guidelines')
  });

  _exports.default = _default;
});