define("ember-cli-zuglet/-private/data/object/internal", ["exports", "ember-cli-zuglet/-private/data/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.content = Object.create(null);
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:data/object').create({
        _internal: this
      });
    },
    getModelValue: function getModelValue(key) {
      return this.serializer.getModelValue(this, key);
    },
    setModelValue: function setModelValue(key, value) {
      return this.serializer.setModelValue(this, key, value);
    }
  });

  _exports.default = _default;
});