define("ember-cli-zuglet/initialize", ["exports", "ember-cli-zuglet/-private/util/fastboot"], function (_exports, _fastboot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.register = _exports.initialize = void 0;

  // register({
  //   app,
  //   store: {
  //     identifier: 'store',
  //     factory: Store,
  //   },
  //   service: {
  //     enabled: true,
  //     name: 'store',
  //     inject: [ 'route', 'controller', 'component', 'model' ],
  //   },
  //   development: {
  //     enabled: true,
  //     export: 'store',
  //     logging: true
  //   }
  // });
  var normalizeStore = function normalizeStore(store) {
    store = Ember.assign({
      identifier: 'store',
      factory: null
    }, store || {});
    (false && !(typeof store.identifier === 'string') && Ember.assert("opts.store.identifier is required", typeof store.identifier === 'string'));
    (false && !(!!store.factory) && Ember.assert("opts.store.factory is required", !!store.factory));
    return store;
  };

  var normalizeService = function normalizeService(service, store) {
    service = Ember.assign({
      enabled: true,
      name: store.identifier,
      inject: ['route', 'controller', 'component', 'model']
    }, service);
    (false && !(typeof service.name === 'string') && Ember.assert("opts.service.name is required", typeof service.name === 'string'));
    (false && !(Ember.typeOf(service.inject) === 'array') && Ember.assert("opts.service.inject must be array", Ember.typeOf(service.inject) === 'array'));
    return service;
  };

  var normalizeDevelopment = function normalizeDevelopment(development, store) {
    development = Ember.assign({
      enabled: true,
      logging: true,
      export: store.identifier
    }, development);
    (false && !(typeof development.export === 'string') && Ember.assert("opts.development.export is required", typeof development.export === 'string'));
    return development;
  };

  var normalizeArgs = function normalizeArgs() {
    var opts;
    var app;

    if (arguments.length > 1) {
      app = arguments.length <= 0 ? undefined : arguments[0];
      opts = arguments.length <= 1 ? undefined : arguments[1];
      (false && !(Ember.typeOf(opts) === 'object') && Ember.assert("opts must be object", Ember.typeOf(opts) === 'object'));
      (false && !(!!app) && Ember.assert("app is required", !!app));
    } else {
      opts = arguments.length <= 0 ? undefined : arguments[0];
      (false && !(Ember.typeOf(opts) === 'object') && Ember.assert("opts must be object", Ember.typeOf(opts) === 'object'));
      app = opts.app;
      delete opts.app;
      (false && !(!!app) && Ember.assert("opts.app is required", !!app));
    }

    return {
      app: app,
      opts: opts
    };
  };

  var normalize = function normalize() {
    var _normalizeArgs = normalizeArgs.apply(void 0, arguments),
        app = _normalizeArgs.app,
        opts = _normalizeArgs.opts;

    var store = opts.store,
        service = opts.service,
        development = opts.development;
    store = normalizeStore(store);
    service = normalizeService(service, store);
    development = normalizeDevelopment(development, store);
    return {
      app: app,
      opts: {
        store: store,
        service: service,
        development: development
      }
    };
  };

  var environment = function environment(app) {
    return app.factoryFor('config:environment').class.environment;
  };

  var register = function register() {
    var _normalize = normalize.apply(void 0, arguments),
        app = _normalize.app,
        opts = _normalize.opts;

    var stores = app.lookup('zuglet:stores');
    var store = stores.createStore(opts.store.identifier, opts.store.factory);

    if (opts.service.enabled) {
      var fullName = "service:".concat(opts.service.name);
      app.register(fullName, store, {
        instantiate: false
      });
      Ember.A(opts.service.inject).forEach(function (key) {
        app.inject(key, opts.service.name, fullName);
      });
    }

    if (opts.development.enabled && environment(app) === 'development') {
      if (typeof window !== 'undefined' && !(0, _fastboot.isFastBoot)(store)) {
        var key = opts.store.identifier;
        window[key] = store;

        if (opts.development.logging) {
          console.log("window.".concat(key, " = ").concat(store));
        }

        stores._internal.registerWillDestroyListener(function () {
          delete window[key];
        });
      }
    }

    return store;
  };

  _exports.register = register;

  var initialize = function initialize(opts) {
    return function (app) {
      return register(app, opts);
    };
  };

  _exports.initialize = initialize;
});