define("ember-cli-zuglet/-private/lifecycle/observed/internal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var validate = function validate(opts) {
    (false && !(Ember.typeOf(opts) === 'object') && Ember.assert("opts must be object", Ember.typeOf(opts) === 'object'));
    (false && !(Ember.typeOf(opts.parent) === 'array') && Ember.assert("opts.parent must be array", Ember.typeOf(opts.parent) === 'array'));

    if (opts.content) {
      (false && !(Ember.typeOf(opts.content) === 'function') && Ember.assert("opts.content must be function", Ember.typeOf(opts.content) === 'function'));
    }
  };

  var _default = Ember.Object.extend({
    parent: null,
    key: null,
    opts: null,
    observable: null,
    observer: null,
    init: function init() {
      this._super.apply(this, arguments);

      validate(this.opts);
    },
    getObservable: function getObservable() {
      return this.observable;
    },
    getObserver: function getObserver() {
      return this.observer;
    },
    setObservable: function setObservable(next) {
      if (this.observable === next) {
        return next;
      }

      this.stopObserving();
      this.observable = next;
      this.startObserving();
      return next;
    },
    stopObserving: function stopObserving() {
      var observer = this.observer;

      if (!observer) {
        return;
      }

      observer.cancel();
      this.observer = null;
    },
    startObserving: function startObserving() {
      var observable = this.observable;

      if (!observable) {
        return;
      }

      this.observer = observable.observe();
    },
    willDestroy: function willDestroy() {
      this.stopObserving();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});