define("ember-cli-zuglet/-private/queue/operation/invocable", ["exports", "ember-cli-zuglet/-private/util/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    queue: null,
    owner: null,
    opts: null,
    isInvoked: false,
    deferred: Ember.computed(function () {
      return Ember.RSVP.defer();
    }),
    name: Ember.computed.readOnly('opts.name'),
    promise: Ember.computed.readOnly('deferred.promise'),
    invoke: function invoke() {
      var _this = this;

      var deferred = this.get('deferred');
      var promise = deferred.promise;

      if (this.isDestroying) {
        deferred.reject((0, _errors.operationDestroyedError)());
        return promise;
      }

      (false && !(!this.get('isInvoked')) && Ember.assert("operation is already invoked", !this.get('isInvoked')));
      this.set('isInvoked', true);
      var opts = this.get('opts');
      Ember.RSVP.resolve().then(function () {
        return opts.invoke();
      }).then(function (arg) {
        if (_this.isDestroying) {
          return;
        }

        return opts.didResolve ? opts.didResolve(arg) : arg;
      }, function (err) {
        if (_this.isDestroying) {
          return Ember.RSVP.reject(err);
        }

        return opts.didReject ? opts.didReject(err) : Ember.RSVP.reject(err);
      }).then(function (arg) {
        return deferred.resolve(arg);
      }, function (err) {
        return deferred.reject(err);
      });
      return promise;
    }
  });

  _exports.default = _default;
});