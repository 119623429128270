define("design/components/ui-block/flow/navigation/component", ["exports", "design/components/ui-block/flow/navigation/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-flow-navigation', 'current::hidden', 'last:hidden'],
    flow: null,
    disabled: false,
    current: Ember.computed.readOnly('flow.current'),
    total: Ember.computed.readOnly('flow.steps.length'),
    number: Ember.computed.readOnly('current.number'),
    next: Ember.computed.readOnly('flow.next'),
    previous: Ember.computed.readOnly('flow.prev'),
    last: Ember.computed('flow.steps.lastObject', 'current', function () {
      var last = this.flow.steps.lastObject,
          current = this.current;
      return current === last;
    }).readOnly(),
    actions: {
      previous: function previous() {
        this.transition(this.previous);
      },
      next: function next() {
        this.transition(this.next);
      }
    },
    transition: function transition(step) {
      var disabled = this.disabled;

      if (disabled) {
        return;
      }

      if (!step || !step.enabled) {
        return;
      }

      step.transitionTo();
    }
  });

  _exports.default = _default;
});