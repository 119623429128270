define("ember-cli-sketch/-private/stage/tools/selection", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    nodesForMousePosition: function nodesForMousePosition() {
      var stage = this.stage,
          absolute = this.mouse.absolute;
      return stage.nodesForAbsolutePosition(absolute);
    },
    updateHover: function updateHover() {
      var nodes = this.nodesForMousePosition();
      this.hover.replace(nodes);
    },
    updateSelection: function updateSelection() {
      var node = this.hover.last,
          selection = this.selection;

      if (!node) {
        selection.clear();
        return;
      }

      var shift = this.keyboard.isShift;

      if (!shift && node.isSelected) {
        return;
      }

      node.select({
        replace: !shift,
        toggle: shift
      });
    },
    selectedNodeWithActiveEdge: function selectedNodeWithActiveEdge() {
      return this.selection.find(function (node) {
        return node.edge.has;
      });
    },
    onMouseMove: function onMouseMove() {
      this.updateHover();
    },
    onMouseWheel: function onMouseWheel() {
      this.updateHover();
    },
    onMouseDown: function onMouseDown() {
      if (!this.mouse.isLeftButtonOverStage) {
        return;
      }

      var node = this.selectedNodeWithActiveEdge();

      if (node) {
        this.tools.activate('node/resize', {
          node: node
        });
      } else {
        this.updateSelection();

        if (this.selection.any) {
          this.tools.activate('node/drag');
        }
      }
    },
    onMouseDoubleClick: function onMouseDoubleClick() {
      var node = this.selection.find(function (node) {
        return node.isHovered;
      });

      if (!node) {
        return;
      }

      this.tools.activate('node/edit', {
        node: node
      });
    },
    onKeyDown: function onKeyDown(key) {
      if (key.isBody) {
        this.tools.activate('node/move', {
          key: key
        });
      }
    },
    onKeyUp: function onKeyUp(key) {
      if (key.isBody && key.isBackspaceOrDelete) {
        this.tools.activate('node/remove', {
          key: key
        });
      }
    },
    activate: function activate() {
      if (!this.mouse.absolute) {
        return;
      }

      this.updateHover();
    },
    deactivate: function deactivate(next) {
      if (next.type === 'node/drag') {
        return;
      }

      this.hover.reset();
    }
  });

  _exports.default = _default;
});