define("ember-cli-sketch/components/ui-block/sketch/stage/node/area/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/node/-component", "ember-cli-sketch/components/ui-block/sketch/stage/node/area/template", "ember-cli-sketch/components/ui-block/sketch/stage/node/-computed"], function (_exports, _component, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    style: (0, _computed.style)('model.{fill}', function () {
      var background = this.model.fill;
      return {
        background: background
      };
    })
  });

  _exports.default = _default;
});