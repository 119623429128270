define("ember-cli-sketch/-private/node/stage", ["exports", "ember-cli-sketch/-private/node/-base", "ember-cli-sketch/-private/util/computed"], function (_exports, _base, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var model = function model(name) {
    return (0, _computed.factory)(function (factory, stage) {
      return factory[name].call(factory, stage);
    });
  };

  var _default = function _default(opts) {
    return (0, _base.default)(opts).extend({
      type: 'stage',
      isStage: true,
      isContainer: true,
      isAttached: true,
      isVisible: true,
      stage: (0, _computed.self)(),
      frame: (0, _base.frame)('stage'),
      zoom: (0, _computed.zoom)('model.zoom'),
      renderer: model('renderer'),
      interactions: model('interactions'),
      hover: model('hover'),
      selection: model('selection'),
      tools: model('tools'),
      cursor: model('cursor'),
      guidelines: model('stageGuidelines'),
      strings: model('strings'),
      isSelectable: Ember.computed.and('isAttached', '_selectable'),
      recursive: Ember.computed.readOnly('nodes.recursive'),
      //
      attach: function attach() {
        var _this$renderer;

        (_this$renderer = this.renderer).attach.apply(_this$renderer, arguments);
      },
      detach: function detach() {
        var renderer = this.renderer,
            interactions = this.interactions,
            hover = this.hover,
            selection = this.selection,
            tools = this.tools;
        renderer.detach.apply(renderer, arguments);
        interactions.reset();
        hover.reset();
        selection.reset();
        tools.reset();
      },
      //
      focus: function focus() {
        this.renderer.focus();
      },
      //
      handle: function handle(action) {
        (false && !(!!this.model.handle) && Ember.assert("handle is required for ".concat(this.model), !!this.model.handle));
        this.model.handle(action);
      },
      nodesPerform: function nodesPerform(nodes, name) {
        for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          args[_key - 2] = arguments[_key];
        }

        if (!nodes) {
          return;
        }

        nodes = nodes.slice();
        var actions = this.sketches.actions;
        return nodes.map(function (node) {
          return actions.perform.apply(actions, [node, name].concat(args));
        });
      },
      nodesForAbsolutePosition: function nodesForAbsolutePosition(position) {
        return this.nodes.nodesForAbsolutePosition(position);
      }
    });
  };

  _exports.default = _default;
});