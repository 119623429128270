define("design/components/ui-block/fill/component", ["exports", "design/components/ui-block/fill/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-fill'],
    layout: _template.default,
    window: Ember.inject.service(),
    padding: null,
    // { width, height }
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._startObserving();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._stopObserving();
    },
    _startObserving: function _startObserving() {
      var _this = this;

      this._observer = function () {
        return Ember.run(function () {
          return _this._onResize();
        });
      };

      window.addEventListener('resize', this._observer); // eslint-disable-line no-undef

      this._observer();
    },
    _stopObserving: function _stopObserving() {
      window.removeEventListener('resize', this._observer); // eslint-disable-line no-undef
    },
    _onResize: function _onResize() {
      var _window = window,
          width = _window.innerWidth,
          height = _window.innerHeight; // eslint-disable-line no-undef

      width = width - this.window.scrollbarWidth;
      var padding = this.padding;
      var size = {
        width: width,
        height: height
      };

      if (padding) {
        var pad = function pad(key) {
          size[key] -= padding[key] || 0;
        };

        pad('width');
        pad('height');
      }

      this.setProperties({
        size: size
      });
    }
  });

  _exports.default = _default;
});