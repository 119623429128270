define("ember-cli-sketch/-private/util/runloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.next = _exports.later = _exports.afterRender = _exports.actions = void 0;

  var next = function next() {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.next(resolve);
    });
  };

  _exports.next = next;

  var afterRender = function afterRender() {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.schedule('afterRender', resolve);
    });
  };

  _exports.afterRender = afterRender;

  var actions = function actions() {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.schedule('actions', resolve);
    });
  };

  _exports.actions = actions;

  var later = function later(delay) {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.later(resolve, delay);
    });
  };

  _exports.later = later;
});