define("ember-cli-sketch/-private/util/load-fonts", ["exports", "webfontloader"], function (_exports, _webfontloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.err = _exports.default = void 0;

  var err = function err(errors) {
    var error = new Error('Fonts failed to load');
    error.code = 'webfont/load';

    if (errors) {
      error.errors = errors;
    }

    return error;
  };

  _exports.err = err;

  var _default = function _default(spec) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      spec = Ember.assign({}, spec);

      spec.active = function () {
        return resolve();
      };

      spec.inactive = function () {
        return reject(err());
      };

      _webfontloader.default.load(spec);
    });
  };
  /*
  
  // https://github.com/typekit/webfontloader
  
  await loadFonts({
    google: {
      families: ['Droid Sans', 'Droid Serif']
    }
  });
  
  */


  _exports.default = _default;
});