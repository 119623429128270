define("design/components/ui-block/tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jD5PYGsd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"content\"],[false]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"ui-block/tooltip/overlay\",null,[[\"parent\"],[[23,0,[]]]],{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"content\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});