define("ember-cli-sketch/-private/computed/prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isProp = _exports.hashToDeps = _exports.default = _exports.__sketch__prop__ = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var __sketch__prop__ = '__sketch__prop__';
  _exports.__sketch__prop__ = __sketch__prop__;

  var hashToDeps = function hashToDeps(hash) {
    return Ember.A(Object.keys(hash).map(function (key) {
      var value = hash[key];

      if (value && value[__sketch__prop__]) {
        return value.key;
      }
    })).compact();
  };

  _exports.hashToDeps = hashToDeps;

  var isProp = function isProp(object) {
    return object && object[__sketch__prop__] === true;
  };

  _exports.isProp = isProp;

  var _default = function _default(key) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, __sketch__prop__, true), _defineProperty(_ref, "key", key), _ref;
  };

  _exports.default = _default;
});