define("ember-cli-sketch/-private/stage/tools/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    tools: null,
    stage: Ember.computed.readOnly('tools.stage'),
    zoom: Ember.computed.readOnly('stage.zoom'),
    hover: Ember.computed.readOnly('stage.hover'),
    selection: Ember.computed.readOnly('stage.selection'),
    mouse: Ember.computed.readOnly('stage.interactions.mouse'),
    keyboard: Ember.computed.readOnly('stage.interactions.keyboard'),
    guidelines: false,
    cursor: null,
    done: function done() {
      this.tools._deactivate(this);
    },
    //
    zoomedDelta: function zoomedDelta(delta) {
      var zoom = this.zoom;
      return {
        x: delta.x / zoom,
        y: delta.y / zoom
      };
    },
    //
    onMouseDown: function onMouseDown() {},
    onMouseUp: function onMouseUp() {},
    onMouseMove: function onMouseMove() {},
    onMouseWheel: function onMouseWheel() {},
    onMouseClick: function onMouseClick() {},
    onMouseDoubleClick: function onMouseDoubleClick() {},
    onKeyDown: function onKeyDown() {},
    onKeyUp: function onKeyUp() {},
    //
    activate: function activate() {},
    deactivate: function deactivate() {}
  });

  _exports.default = _default;
});