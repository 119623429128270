define("design/components/ui-input/file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mfIePmKy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"input\"],[11,\"accept\",[22,\"accept\"]],[10,\"multiple\",\"false\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onChange\"],null]],[11,\"disabled\",[22,\"disabled\"]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[4,\"ui-input/button\",null,[[\"class\",\"size\",\"color\",\"type\",\"disabled\",\"hover\"],[\"details\",[24,[\"size\"]],[24,[\"color\"]],[24,[\"type\"]],[24,[\"disabled\"]],[24,[\"hover\"]]]],{\"statements\":[[4,\"if\",[[24,[\"selection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"selection\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/icon\",[\"arrow-circle-o-up\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subtitle\"],[8],[1,[28,\"t\",[\"input.file.selected\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[24,[\"selection\",\"name\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"placeholder\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/icon\",[\"arrow-circle-o-up\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subtitle\"],[8],[1,[28,\"t\",[\"input.file.drag\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/file/template.hbs"
    }
  });

  _exports.default = _default;
});