define("ember-cli-sketch/computed", ["exports", "ember-cli-sketch/-private/computed/node", "ember-cli-sketch/-private/computed/attr", "ember-cli-sketch/-private/computed/attrs", "ember-cli-sketch/-private/computed/prop", "ember-cli-sketch/-private/computed/guidelines"], function (_exports, _node, _attr, _attrs, _prop, _guidelines) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function get() {
      return _attr.default;
    }
  });
  Object.defineProperty(_exports, "attrs", {
    enumerable: true,
    get: function get() {
      return _attrs.default;
    }
  });
  Object.defineProperty(_exports, "guidelines", {
    enumerable: true,
    get: function get() {
      return _guidelines.default;
    }
  });
  Object.defineProperty(_exports, "node", {
    enumerable: true,
    get: function get() {
      return _node.default;
    }
  });
  Object.defineProperty(_exports, "prop", {
    enumerable: true,
    get: function get() {
      return _prop.default;
    }
  });
});