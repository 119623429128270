define("ember-cli-sketch/-private/actions/node/move-to-container", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    perform: function perform(node) {
      var isContainer = node.isContainer,
          stage = node.stage,
          container = node.parent;

      if (isContainer || !stage || !container) {
        return;
      }

      var findTarget = function findTarget() {
        return stage.nodes.containers.selectable.find(function (container) {
          return container.frame.overlapsFrame(node.frame.absoluteBounds, 'absoluteBounds');
        });
      };

      var move = function move(target) {
        node.moveTo(target);
        return target;
      };

      if (container.isStage) {
        var target = findTarget();

        if (target) {
          return move(target);
        }
      } else {
        var outside = container.frame.excludesFrame(node.frame.absoluteBounds, 'absoluteBounds');

        if (outside) {
          var _target = findTarget() || stage;

          return move(_target);
        }
      }
    }
  });

  _exports.default = _default;
});