define("ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/template", "ember-cli-sketch/components/ui-block/sketch/stage/-computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var both = function both() {
    return Ember.computed.or('width.immutable', 'height.immutable');
  };

  var width = function width() {
    return Ember.computed.or('width.immutable');
  };

  var height = function height() {
    return Ember.computed.or('height.immutable');
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-sketch-stage-selections-selection'],
    attributeBindings: ['style'],
    node: Ember.computed.readOnly('model.node'),
    edge: Ember.computed.readOnly('node.edge'),
    stage: Ember.computed.readOnly('node.stage'),
    width: (0, _computed.attribute)('model', 'width'),
    height: (0, _computed.attribute)('model', 'height'),
    isBothDisabled: both(),
    isHeightDisabled: height(),
    isWidthDisabled: width(),
    isEditable: Ember.computed.readOnly('node.isEditable'),
    isEditableVisible: Ember.computed('isEditable', 'node.frame.selection', function () {
      if (!this.isEditable) {
        return;
      }

      var frame = this.node.frame.selection;

      if (frame.width < 90 || frame.height < 45) {
        return false;
      }

      return true;
    }).readOnly(),
    frame: (0, _computed.frame)('model', 'selection', {
      index: false
    }),
    style: (0, _computed.style)('frame', function (_ref) {
      var frame = _ref.frame;
      return frame;
    })
  });

  _exports.default = _default;
});