define("design/models/validation/validator/custom", ["exports", "design/models/validation/validator/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    validate: function validate(value, opts) {
      var object = this.object;
      var validate = opts.validate;
      var result = validate.call(object, value, object, opts);
      return result;
    }
  });

  _exports.default = _default;
});