define("design/components/ui-block/guidelines/component", ["exports", "design/components/ui-block/guidelines/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-guidelines', 'visible:visible:hidden'],
    layout: _template.default,
    dimensions: null,
    visible: true,
    lines: Ember.computed('dimensions.{page.width,page.height,spine,crop}', function () {
      var dimensions = this.dimensions;

      if (!dimensions) {
        return;
      }

      var _dimensions$page = dimensions.page,
          width = _dimensions$page.width,
          height = _dimensions$page.height,
          crop = dimensions.crop,
          spine = dimensions.spine;
      var w = 100 / (crop + width + spine + width + crop);
      var h = 100 / (crop + height + crop);

      var vertical = function vertical(value) {
        return {
          className: 'vertical',
          style: Ember.String.htmlSafe("left: ".concat(w * value, "%"))
        };
      };

      var horizontal = function horizontal(value) {
        return {
          className: 'horizontal',
          style: Ember.String.htmlSafe("top: ".concat(h * value, "%"))
        };
      };

      return [vertical(crop), vertical(crop + width), vertical(crop + width + spine), vertical(crop + width + spine + width), horizontal(crop), horizontal(crop + height)];
    }).readOnly(),
    actions: {
      hide: function hide() {
        this.setProperties({
          visible: false
        });
      },
      show: function show() {
        this.setProperties({
          visible: true
        });
      }
    }
  });

  _exports.default = _default;
});