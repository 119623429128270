define("ember-cli-zuglet/-private/stores/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.stores = Ember.A();
      this.willDestroyListeners = Ember.A();
    },
    factoryFor: function factoryFor(name) {
      return Ember.getOwner(this).factoryFor(name);
    },
    _registerStoreFactory: function _registerStoreFactory(identifier, factory) {
      var factoryName = "zuglet:store/".concat(identifier);
      var registered = this.factoryFor(factoryName);

      if (!registered) {
        Ember.getOwner(this).register(factoryName, factory);
        registered = this.factoryFor(factoryName);
      }

      return registered;
    },
    _createInternalStore: function _createInternalStore(identifier, factory) {
      factory = this._registerStoreFactory(identifier, factory);
      return this.factoryFor('zuglet:store/internal').create({
        stores: this,
        identifier: identifier,
        factory: factory
      });
    },
    createStore: function createStore(identifier, factory) {
      var internal = this._createInternalStore(identifier, factory);

      this.get('stores').pushObject(internal);
      return internal;
    },
    ready: function ready() {
      return Ember.RSVP.all(this.get('stores').map(function (store) {
        return store.get('ready');
      }));
    },
    settle: function settle() {
      return Ember.RSVP.all(this.get('stores').map(function (store) {
        return store.settle();
      }));
    },
    registerWillDestroyListener: function registerWillDestroyListener(fn) {
      var array = this.get('willDestroyListeners');
      array.pushObject(fn);
      var removed = false;
      return function () {
        if (removed) {
          return;
        }

        removed = true;
        array.removeObject(fn);
      };
    },
    willDestroy: function willDestroy() {
      this.get('willDestroyListeners').map(function (fn) {
        return fn();
      });
      this.get('stores').map(function (store) {
        return store.destroy();
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});