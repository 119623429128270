define("ember-cli-sketch/components/ui-block/sketch/stage/node/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ujOrvzqz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"content-editable\",null,[[\"value\",\"autofocus\",\"class\"],[[24,[\"editable\"]],true,\"text\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"editing\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[24,[\"strings\",\"loadingFont\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"ui-block/sketch/stage/deferred\",null,[[\"promise\"],[[24,[\"loader\",\"promise\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/node/text/template.hbs"
    }
  });

  _exports.default = _default;
});