define("ember-cli-sketch/initialize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.register = _exports.initialize = void 0;

  var registerHash = function registerHash(app, hash, prefix) {
    if (!hash) {
      return;
    }

    for (var key in hash) {
      var factory = hash[key];
      var name = "".concat(prefix, "/").concat(key);
      app.register(name, factory);
    }

    return Object.keys(hash);
  };

  var register = function register(app, opts) {
    var factory = opts.factory,
        strings = opts.strings,
        interactions = opts.interactions,
        actions = opts.actions,
        tools = opts.tools,
        fonts = opts.fonts;
    (false && !(!!factory) && Ember.assert("opts.factory is required", !!factory));

    if (strings) {
      app.register('sketch:strings', strings);
    }

    interactions = registerHash(app, interactions, 'sketch:stage/interactions/handlers');
    actions = registerHash(app, actions, 'sketch:actions');
    tools = registerHash(app, tools, 'sketch:stage/tools');
    factory = factory.extend({
      registrations: Object.freeze({
        interactions: interactions,
        actions: actions,
        tools: tools,
        fonts: fonts
      })
    });
    app.register('sketch:sketches', factory);
  };

  _exports.register = register;

  var initialize = function initialize(opts) {
    return function (app) {
      register(app, opts);
    };
  };

  _exports.initialize = initialize;
});