define("ember-cli-sketch/-private/stage/interactions", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var medium = function medium(type) {
    return (0, _computed.factory)(function (factory, interactions) {
      return factory.interactionMedium(type, interactions);
    });
  };

  var mouse = function mouse() {
    return medium('mouse');
  };

  var keyboard = function keyboard() {
    return medium('keyboard');
  };

  var handlers = function handlers() {
    return (0, _computed.factory)(function (factory, interactions) {
      return factory.interactionHandlers(interactions);
    });
  };

  var _default = Ember.Object.extend({
    stage: null,
    isEnabled: Ember.computed.readOnly('stage.isSelectable'),
    mouse: mouse(),
    keyboard: keyboard(),
    handlers: handlers(),
    invokeHandlers: function invokeHandlers() {
      var _this$handlers;

      (_this$handlers = this.handlers).onEvent.apply(_this$handlers, arguments);
    },
    onMouseOver: function onMouseOver() {
      var _this$mouse;

      (_this$mouse = this.mouse).onMouseOver.apply(_this$mouse, arguments);

      this.invokeHandlers.apply(this, ['onMouseOver'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseOut: function onMouseOut() {
      var _this$mouse2;

      (_this$mouse2 = this.mouse).onMouseOut.apply(_this$mouse2, arguments);

      this.invokeHandlers.apply(this, ['onMouseOut'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseDown: function onMouseDown() {
      var _this$mouse3;

      (_this$mouse3 = this.mouse).onMouseDown.apply(_this$mouse3, arguments);

      this.invokeHandlers.apply(this, ['onMouseDown'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseMove: function onMouseMove() {
      var _this$mouse4;

      (_this$mouse4 = this.mouse).onMouseMove.apply(_this$mouse4, arguments);

      this.invokeHandlers.apply(this, ['onMouseMove'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseClick: function onMouseClick() {
      var _this$mouse5;

      (_this$mouse5 = this.mouse).onMouseClick.apply(_this$mouse5, arguments);

      this.invokeHandlers.apply(this, ['onMouseClick'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseDoubleClick: function onMouseDoubleClick() {
      var _this$mouse6;

      (_this$mouse6 = this.mouse).onMouseDoubleClick.apply(_this$mouse6, arguments);

      this.invokeHandlers.apply(this, ['onMouseDoubleClick'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseUp: function onMouseUp() {
      var _this$mouse7;

      (_this$mouse7 = this.mouse).onMouseUp.apply(_this$mouse7, arguments);

      this.invokeHandlers.apply(this, ['onMouseUp'].concat(Array.prototype.slice.call(arguments)));
    },
    onMouseWheel: function onMouseWheel() {
      var _this$mouse8;

      (_this$mouse8 = this.mouse).onMouseWheel.apply(_this$mouse8, arguments);

      this.invokeHandlers.apply(this, ['onMouseWheel'].concat(Array.prototype.slice.call(arguments)));
    },
    _keyboardMetaWorkaround: function _keyboardMetaWorkaround(opts) {
      var meta = opts._metaKey;
      delete opts._metaKey; // OSX doesn't fire meta if window is not in focus
      // so, fake it on next key down

      if (!meta && this.keyboard.isMeta) {
        this.onKeyUp({
          key: 'Meta',
          keyCode: 91
        });
      }

      return opts;
    },
    onKeyDown: function onKeyDown(opts) {
      opts = this._keyboardMetaWorkaround(opts);
      var key = this.keyboard.onKeyDown(opts);

      if (key) {
        this.invokeHandlers('onKeyDown', key);
      }
    },
    onKeyUp: function onKeyUp(opts) {
      var key = this.keyboard.onKeyUp(opts);

      if (key) {
        this.invokeHandlers('onKeyUp', key);
      }
    },
    onBlur: function onBlur() {},
    onFocus: function onFocus() {},
    reset: function reset() {
      this.mouse.reset(), this.keyboard.reset();
      this.invokeHandlers('reset');
    }
  });

  _exports.default = _default;
});