define("design/services/dialogs", ["exports", "design/util/computed", "design/services/dialogs/-alert"], function (_exports, _computed, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_alert.default, {
    presenters: (0, _computed.array)(),
    models: (0, _computed.array)(),
    registerPresenter: function registerPresenter(component) {
      this.presenters.pushObject(component);
    },
    unregisterPresenter: function unregisterPresenter(component) {
      this._cancelAll(component);

      this.presenters.removeObject(component);
    },
    presenter: function presenter(label) {
      var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var presenter = this.presenters.findBy('label', label);
      (false && !(!!presenter || !required) && Ember.assert("presenter '".concat(label, "' not registered"), !!presenter || !required));
      return presenter;
    },
    _cancelAll: function _cancelAll(presenter) {
      var models = this.models.filterBy('presenter', presenter);
      models.forEach(function (model) {
        return model.cancel();
      });
    },
    _remove: function _remove(model) {
      this.models.removeObject(model);
    },
    model: function model(dialog, presenter, opts) {
      var dialogs = this;
      return Ember.getOwner(this).factoryFor('model:dialog').create(Ember.assign({
        dialogs: dialogs,
        dialog: dialog,
        presenter: presenter
      }, opts));
    },
    present: function present(label, name, opts) {
      var presenter = this.presenter(label || 'application');
      var model = this.model(name, presenter, opts);
      this.models.pushObject(model);
      return model.promise;
    }
  });

  _exports.default = _default;
});