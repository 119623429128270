define("ember-cli-zuglet/-private/data/primitive/internal", ["exports", "ember-cli-zuglet/-private/data/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    content: null,
    model: function model() {
      return this.content;
    }
  });

  _exports.default = _default;
});