define("ember-cli-zuglet/-private/reference/queryable/internal-mixin", ["exports", "ember-cli-zuglet/-private/util/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keys = _exports.default = void 0;

  var formatter = function formatter() {
    return {
      string: function string(info) {
        return "".concat(info.name, "(").concat(info.args.join(', '), ")");
      },
      object: function object(info) {
        return {
          type: 'query',
          id: info.name,
          args: info.args
        };
      }
    };
  };

  var types = {
    'where': formatter(),
    'orderBy': formatter(),
    'limit': formatter(),
    'startAt': formatter(),
    'startAfter': formatter(),
    'endAt': formatter(),
    'endBefore': formatter()
  };
  var keys = Object.keys(types);
  _exports.keys = keys;

  var query = function query(name) {
    return function () {
      var _ref$name;

      var ref = this.ref;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var nested = (_ref$name = ref[name]).call.apply(_ref$name, [ref].concat(args));

      var formatter = types[name];
      return this.store.createInternalQueryReferenceForReference(nested, this, {
        name: name,
        args: args,
        formatter: formatter
      });
    };
  };

  var _default = Ember.Mixin.create(keys.reduce(function (hash, key) {
    hash[key] = query(key);
    return hash;
  }, {}), {
    query: function query(opts) {
      return this.store.createInternalQueryWithReference(this, opts);
    },
    didLoad: function didLoad(snapshot) {
      var store = this.store;
      return snapshot.docs.map(function (doc) {
        return store.createInternalDocumentForSnapshot(doc);
      });
    },
    load: function load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var source = opts.source;
      return this.get('store.queue').schedule({
        name: 'reference/queryable/load',
        invoke: function invoke() {
          return _this.ref.get({
            source: source
          });
        },
        didResolve: function didResolve(snapshot) {
          var internals = _this.didLoad(snapshot);

          return Ember.A(internals.map(function (internal) {
            return internal.model(true);
          }));
        },
        didReject: function didReject(err) {
          return Ember.RSVP.reject(err);
        }
      });
    },
    first: function first() {
      var _this2 = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var source = opts.source;
      return this.get('store.queue').schedule({
        name: 'reference/queryable/first',
        invoke: function invoke() {
          return _this2.ref.get({
            source: source
          });
        },
        didResolve: function didResolve(snapshot) {
          var internals = _this2.didLoad(snapshot);

          var first = internals[0];

          if (first) {
            return first.model(true);
          }

          if (opts.optional) {
            return;
          }

          return Ember.RSVP.reject((0, _errors.documentMissingError)(opts));
        },
        didReject: function didReject(err) {
          return Ember.RSVP.reject(err);
        }
      });
    }
  });

  _exports.default = _default;
});