define("ember-cli-zuglet/-private/lifecycle/route/property", ["exports", "ember-cli-zuglet/-private/lifecycle/route/hooks", "ember-cli-zuglet/-private/lifecycle/route/internal"], function (_exports, _hooks, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var destroyInternal = function destroyInternal(internal) {
    return internal && internal.destroy();
  };

  var destroyCurentModel = function destroyCurentModel(route) {
    var model = route.currentModel;
    var internal = (0, _internal.getInternal)(model, route);
    destroyInternal(internal);
  };

  var resetController = function resetController() {
    destroyCurentModel(this);
  };

  var willDestroy = function willDestroy() {
    destroyCurentModel(this);
  };

  var create = function create(route, params, opts) {
    var owner = Ember.getOwner(route);
    return owner.factoryFor('zuglet:lifecycle/route/internal').create({
      route: route,
      params: params,
      opts: opts
    });
  };

  var _default = function _default(opts) {
    return function (params, transition) {
      (0, _hooks.onResetController)(this, resetController);
      (0, _hooks.onWillDestroy)(this, willDestroy);
      var internal = create(this, params, opts);
      return Ember.RSVP.resolve().then(function () {
        return internal.load();
      }).then(function (model) {
        if (transition.isAborted) {
          internal.scheduleDestroy();
        }

        return model;
      }, function (err) {
        internal.scheduleDestroy();
        return Ember.RSVP.reject(err);
      });
    };
  };

  _exports.default = _default;
});