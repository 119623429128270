define("design/components/ui-input/slider/component", ["exports", "design/components/ui-input/slider/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-input-slider'],
    min: 0,
    max: 100,
    value: 0,
    step: 1,
    actions: {
      update: function update(value) {
        var parsed;

        if (this.step === 1) {
          parsed = parseInt(value);
        } else {
          parsed = parseFloat(value);
        }

        this.update && this.update(parsed);
      }
    }
  });

  _exports.default = _default;
});