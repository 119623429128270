define("ember-cli-zuglet/-private/data/timestamp/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer", "ember-cli-zuglet/-private/util/firestore-types", "luxon"], function (_exports, _serializer, _firestoreTypes, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isLuxonDateTime = function isLuxonDateTime(value) {
    return value instanceof _luxon.DateTime;
  };

  var isJSDate = function isJSDate(value) {
    return Ember.typeOf(value) === 'date';
  };

  var toDate = function toDate(value) {
    if ((0, _firestoreTypes.isFirestoreTimestamp)(value)) {
      return value.toDate();
    } else if (isLuxonDateTime(value)) {
      return value.toJSDate();
    }

    return value;
  };

  var isAnyDate = function isAnyDate(value) {
    return (0, _firestoreTypes.isFirestoreTimestamp)(value) || isJSDate(value) || (0, _firestoreTypes.isFirestoreServerTimestamp)(value) || isLuxonDateTime(value);
  };

  var _default = _serializer.default.extend({
    supports: function supports(value) {
      return isAnyDate(value);
    },
    matches: function matches(internal, value) {
      return isAnyDate(value);
    },
    createInternal: function createInternal(content) {
      content = toDate(content);
      return this.factoryFor('zuglet:data/timestamp/internal').create({
        serializer: this,
        content: content
      });
    },
    serialize: function serialize(internal, type) {
      if (type === 'raw') {
        return internal.get('content');
      } else if (type === 'preview') {
        if (internal.get('isServerTimestamp')) {
          return "timestamp:server";
        }

        return internal.get('date');
      } else if (type === 'model') {
        return internal.get('date');
      } else {
        (false && !(false) && Ember.assert("unsupported type '".concat(type, "'"), false));
      }
    },
    deserialize: function deserialize(internal, value) {
      var contentServerTimestamp = (0, _firestoreTypes.isFirestoreServerTimestamp)(internal.content);
      var valueServerTimeStamp = (0, _firestoreTypes.isFirestoreServerTimestamp)(value);

      if (contentServerTimestamp && valueServerTimeStamp) {
        return {
          replace: false,
          internal: internal
        };
      }

      var contentDate = internal.get('date');

      if (contentDate) {
        var valueDate = toDate(value);

        if (valueDate) {
          if (contentDate.getTime() === valueDate.getTime()) {
            return {
              replace: false,
              internal: internal
            };
          }
        }
      }

      internal = this.createInternal(value);
      return {
        replace: true,
        internal: internal
      };
    },
    isDirty: function isDirty(internal, value) {
      var contentServerTimestamp = (0, _firestoreTypes.isFirestoreServerTimestamp)(internal.content);
      var valueServerTimeStamp = (0, _firestoreTypes.isFirestoreServerTimestamp)(value);

      if (contentServerTimestamp && valueServerTimeStamp) {
        return false;
      }

      if (contentServerTimestamp || valueServerTimeStamp) {
        return true;
      }

      var contentDate = toDate(internal.content);
      var valueDate = toDate(value);
      return contentDate.getTime() !== valueDate.getTime();
    }
  });

  _exports.default = _default;
});