define("design/components/ui-input/color-picker/container/component", ["exports", "design/components/ui-input/color-picker/container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var clamp = function clamp(value, min, max) {
    return Math.min(Math.max(min, value), max);
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    marker: null,
    isDragging: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._listeners = {
        mousedown: function mousedown(e) {
          return Ember.run(function () {
            return _this.onMouseDown(e);
          });
        },
        mousemove: function mousemove(e) {
          return Ember.run(function () {
            return _this.onMouseMove(e);
          });
        },
        mouseup: function mouseup(e) {
          return Ember.run(function () {
            return _this.onMouseUp(e);
          });
        }
      };
      var w = window; // eslint-disable-line no-undef

      w.addEventListener('mousedown', this._listeners.mousedown, false);
      w.addEventListener('mousemove', this._listeners.mousemove, false);
      w.addEventListener('mouseup', this._listeners.mouseup, false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var w = window; // eslint-disable-line no-undef

      w.removeEventListener('mousedown', this._listeners.mousedown);
      w.removeEventListener('mousemove', this._listeners.mousemove);
      w.removeEventListener('mouseup', this._listeners.mouseup);
    },
    isChildOf: function isChildOf(target, element) {
      while (element) {
        if (target === element) {
          return true;
        }

        element = element.parentNode;
      }
    },
    isContainerElement: function isContainerElement(element) {
      return this.isChildOf(this.element, element);
    },
    onChange: function onChange(e) {
      var rect = this.element.getBoundingClientRect();
      var x = clamp(e.clientX - rect.left, 0, rect.width);
      var y = clamp(e.clientY - rect.top, 0, rect.height);
      this.update({
        x: x,
        y: y
      });
    },
    onMouseDown: function onMouseDown(e) {
      if (e.button !== 0) {
        return;
      }

      var target = e.target;

      if (!this.isContainerElement(target)) {
        return;
      }

      this.onChange(e);
      this.setProperties({
        isDragging: true
      });
    },
    onMouseMove: function onMouseMove(e) {
      if (!this.isDragging) {
        return;
      }

      e.preventDefault();
      this.onChange(e);
    },
    onMouseUp: function onMouseUp(e) {
      if (!this.isDragging) {
        return;
      }

      this.onChange(e);
      this.setProperties({
        isDragging: false
      });
    }
  });

  _exports.default = _default;
});