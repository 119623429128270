define("ember-cli-sketch/components/ui-block/sketch/stage/node/accessories/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3oIZs6F",
    "block": "{\"symbols\":[\"accessory\"],\"statements\":[[4,\"each\",[[24,[\"model\",\"node\",\"accessories\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"ui-block/sketch/stage/node/\",[24,[\"model\",\"node\",\"type\"]],\"/accessories/\",[23,1,[\"type\"]]],null]],[[\"model\",\"accessory\"],[[24,[\"model\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/node/accessories/template.hbs"
    }
  });

  _exports.default = _default;
});