define("ember-cli-sketch/components/ui-block/sketch/stage/-show-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['show:show'],
    show: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(function () {
        if (_this.isDestroying) {
          return;
        }

        _this.set('show', true);
      });
    }
  });

  _exports.default = _default;
});