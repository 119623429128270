define("design/util/hover-mixin", ["exports", "design/util/mouse-enter-leave-mixin"], function (_exports, _mouseEnterLeaveMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_mouseEnterLeaveMixin.default, {
    handleMouseEnter: function handleMouseEnter() {
      this._super.apply(this, arguments);

      this.set('hover', true);
    },
    handleMouseLeave: function handleMouseLeave() {
      this._super.apply(this, arguments);

      this.set('hover', false);
    }
  });

  _exports.default = _default;
});