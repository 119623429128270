define("design/components/ui-input/dropdown/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tQUjBG66",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"item\",\"icon\",\"value\",\"pair\"],[[24,[\"item\"]],[28,\"component\",[\"ui-block/icon\"],[[\"class\"],[\"icon\"]]],[28,\"component\",[\"ui-div\"],[[\"class\"],[\"value\"]]],[28,\"component\",[\"ui-input/dropdown/item/pair\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[22,\"value\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"chevron\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/icon\",null,[[\"class\",\"name\"],[\"chevron\",\"chevron-down\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/dropdown/item/template.hbs"
    }
  });

  _exports.default = _default;
});