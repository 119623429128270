define("ember-cli-sketch/-private/actions/stage/fit", ["exports", "ember-cli-sketch/-private/actions/stage/-center-fit"], function (_exports, _centerFit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _centerFit.default.extend({
    perform: function perform(stage, opts) {
      opts = Ember.assign({
        offset: 0
      }, opts);
      var size = stage.renderer.size;

      if (!size) {
        return;
      }

      var frame = this.frameForOptions(stage, opts);

      if (!frame) {
        return;
      }

      var bounds = frame.absoluteBounds;

      if (!bounds) {
        return;
      }

      var value = function value(dimension) {
        return (size[dimension] - opts.offset * 2) / bounds[dimension];
      };

      var zoom = Math.min(value('width'), value('height'));

      if (opts.zoom) {
        zoom = Math.min(zoom, opts.zoom);
      }

      stage.update({
        zoom: zoom
      });
      stage.perform('center', {
        type: opts.type
      });
    }
  });

  _exports.default = _default;
});