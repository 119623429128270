define("design/components/ui-block/crop-marks/component", ["exports", "design/components/ui-block/crop-marks/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-crop-marks', '_type', 'inset::hidden'],
    attributeBindings: ['style'],
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    inset: null,
    style: Ember.computed('inset', function () {
      var inset = this.inset;

      if (!inset) {
        return;
      }

      var value = function value(key) {
        return "".concat(key, ": ").concat(inset[key], "px");
      };

      return Ember.String.htmlSafe(['top', 'bottom', 'left', 'right'].map(function (key) {
        return value(key);
      }).join('; '));
    }).readOnly()
  });

  _exports.default = _default;
});