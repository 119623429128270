define("ember-cli-zuglet/-private/data/root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    raw: null,
    internal: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.internal.root = this;
    },
    isDirty: Ember.computed(function () {
      var _this$getProperties = this.getProperties('raw', 'internal'),
          raw = _this$getProperties.raw,
          internal = _this$getProperties.internal;

      return internal.serializer.isDirty(internal, raw);
    }).readOnly(),
    dirtyDidChange: function dirtyDidChange() {
      this.notifyPropertyChange('isDirty');
    },
    deserialize: function deserialize(raw) {
      var internal = this.internal;
      internal.serializer.deserialize(internal, raw);
    },
    serialize: function serialize(type) {
      var internal = this.internal;
      return internal.serializer.serialize(internal, type);
    },
    commit: function commit(raw) {
      var deserialize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!raw) {
        return;
      }

      this.set('raw', raw);

      if (deserialize) {
        this.deserialize(raw);
      }

      this.dirtyDidChange();
    },
    rollback: function rollback() {
      var raw = this.get('raw');
      this.deserialize(raw);
    },
    internalDidUpdate: function internalDidUpdate() {
      this.dirtyDidChange();
    }
  });

  _exports.default = _default;
});