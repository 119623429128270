define("design/components/ui-block/color-picker/component", ["exports", "design/components/ui-block/color-picker/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-color-picker'],
    isOpen: false,
    value: 'white',
    valueStyle: Ember.computed('value', function () {
      var value = this.value;
      return Ember.String.htmlSafe("background-color: ".concat(value));
    }).readOnly(),
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');
      },
      close: function close() {
        this.set('isOpen', false);
      }
    }
  });

  _exports.default = _default;
});