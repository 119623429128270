define("ember-cli-zuglet/-private/queue/serialized-queue", ["exports", "ember-cli-zuglet/-private/queue/queue"], function (_exports, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _queue.default.extend({
    next: function next() {
      var _this = this;

      if (this.isDestroying) {
        return;
      }

      if (this.get('running')) {
        return;
      }

      var operations = this.get('operations');
      var operation = operations.get('firstObject');

      if (!operation) {
        return;
      }

      this.set('running', operation);
      operation.invoke().catch(function () {}).finally(function () {
        (false && !(_this.get('running') === operation) && Ember.assert('running operation must match operation in scope', _this.get('running') === operation));

        _this.set('running', null);

        _this.next();
      });
    },
    didCreateOperation: function didCreateOperation() {
      this.next();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});