define("design/util/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.style = _exports.forEach = void 0;

  var style = function style(props) {
    if (!props) {
      return;
    }

    var arr = [];

    for (var key in props) {
      var value = props[key];

      if (value !== undefined) {
        var normalized = Ember.String.dasherize(key);
        arr.push("".concat(normalized, ": ").concat(value));
      }
    }

    return Ember.String.htmlSafe(arr.join('; '));
  };

  _exports.style = style;

  var forEach = function forEach(hash, fn) {
    return Object.keys(hash).forEach(function (key) {
      return fn(key, hash[key]);
    });
  };

  _exports.forEach = forEach;
});