define("design/components/ui-block/icon/component", ["exports", "design/components/ui-block/icon/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = {
    fontawesome: function fontawesome(name) {
      return "fa fa-".concat(name);
    },
    custom: function custom(name) {
      return "icons8-".concat(name);
    }
  };
  var spins = {
    fontawesome: 'fa-spin'
  }; // {{ui-block/icon 'chevron-bottom'}}
  // Icons: https://fontawesome.com/v4.7.0/icons
  //

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-icon', 'iconClassName'],
    type: 'fontawesome',
    spin: false,
    iconClassName: Ember.computed('name', 'type', 'spin', function () {
      var name = this.name,
          type = this.type,
          spin = this.spin;

      if (!name) {
        return;
      }

      type = type || 'fontawesome';
      var arr = Ember.A();
      arr.push(types[type](name));

      if (spin) {
        arr.push(spins[type]);
      }

      return arr.compact().join(' ');
    }).readOnly()
  }).reopenClass({
    positionalParams: ['name']
  });

  _exports.default = _default;
});