define("ember-cli-sketch/components/ui-block/sketch/stage/-computed", ["exports", "ember-cli-sketch/-private/util/sketches"], function (_exports, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.style = _exports.objectToStyle = _exports.frameToObject = _exports.frame = _exports.fontLoader = _exports.editing = _exports.className = _exports.attribute = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var normalizeInset = function normalizeInset(inset) {
    if (_typeof(inset) !== 'object') {
      return {
        x: inset,
        y: inset,
        width: -inset * 2,
        height: -inset * 2
      };
    }

    return inset;
  };

  var frameToObject = function frameToObject(frame) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!frame) {
      return;
    }

    if (opts.inset) {
      var inset = normalizeInset(opts.inset);
      frame = Ember.assign({}, frame);

      for (var key in inset) {
        frame[key] += inset[key];
      }
    }

    var _frame = frame,
        x = _frame.x,
        y = _frame.y,
        width = _frame.width,
        height = _frame.height,
        rotation = _frame.rotation;
    var hash = {};
    hash.transform = "translate(".concat(x, "px, ").concat(y, "px)");

    if (rotation !== undefined) {
      hash.transform += " rotate(".concat(rotation, "deg)");
    }

    if (width !== undefined && height !== undefined) {
      hash.width = "".concat(width, "px");
      hash.height = "".concat(height, "px");
    }

    return hash;
  };

  _exports.frameToObject = frameToObject;

  var frame = function frame(modelKey, frameKey) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Ember.computed("".concat(modelKey, ".node.{index,frame.").concat(frameKey, "}"), function () {
      var node = this.get("".concat(modelKey, ".node"));

      if (!node) {
        return;
      }

      var hash = {};

      if (opts.index !== false) {
        hash['z-index'] = node.index;
      }

      var frame = node.frame;

      if (frame) {
        hash = Ember.assign(hash, frameToObject(frame.get(frameKey), opts));
      }

      return hash;
    }).readOnly();
  };

  _exports.frame = frame;

  var objectToStyle = function objectToStyle(hash) {
    if (!hash) {
      return;
    }

    if (Ember.typeOf(hash) === 'array') {
      hash = hash.reduce(function (result, hash) {
        result = Ember.assign(result, hash);
        return result;
      }, {});
    }

    var array = Object.keys(hash).reduce(function (array, key) {
      var value = hash[key];

      if (value !== undefined && value !== null && value !== '') {
        array.push("".concat(Ember.String.dasherize(key), ": ").concat(value));
      }

      return array;
    }, []);
    return Ember.String.htmlSafe(array.join('; '));
  };

  _exports.objectToStyle = objectToStyle;

  var style = function style() {
    var _Ember;

    for (var _len = arguments.length, deps = new Array(_len), _key = 0; _key < _len; _key++) {
      deps[_key] = arguments[_key];
    }

    var fn = deps.pop();
    return (_Ember = Ember).computed.apply(_Ember, deps.concat([function () {
      var hash = fn.call(this, this);
      return objectToStyle(hash);
    }])).readOnly();
  };

  _exports.style = style;

  var attribute = function attribute(modelKey, _attribute) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return Ember.computed(modelKey, function () {
      var model = this.get(modelKey);

      if (!model) {
        return;
      }

      return model.node.attributes.attribute(_attribute, required);
    }).readOnly();
  };

  _exports.attribute = attribute;

  var className = function className(valueKey, prefix) {
    return Ember.computed(valueKey, function () {
      var value = this.get(valueKey);

      if (!value) {
        return;
      }

      return "".concat(prefix, "-").concat(value);
    }).readOnly();
  };

  _exports.className = className;

  var fontLoader = function fontLoader() {
    var _Ember2;

    for (var _len2 = arguments.length, deps = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      deps[_key2] = arguments[_key2];
    }

    var fn = deps.pop();
    return (_Ember2 = Ember).computed.apply(_Ember2, deps.concat([function () {
      var opts = fn.call(this, this);

      if (!opts) {
        return;
      }

      return (0, _sketches.default)(this).fonts.loader(opts);
    }])).readOnly();
  };

  _exports.fontLoader = fontLoader;

  var editing = function editing(modelKey) {
    return Ember.computed("".concat(modelKey, ".stage.node.tools.selected.{type,node}"), function () {
      var model = this.get(modelKey);

      if (!model) {
        return;
      }

      var node = model.node,
          stage = model.stage;
      var tool = stage.node.tools.selected;

      if (tool.type !== 'node/edit') {
        return false;
      }

      if (tool.node !== node) {
        return false;
      }

      return true;
    }).readOnly();
  };

  _exports.editing = editing;
});