(function() {

  function vendorModule() {
    'use strict';
    let lib = self['math'];
    return lib;
  }

  define('mathjs', [], vendorModule);
})();
