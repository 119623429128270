define("design/components/ui-input/file/component", ["exports", "design/components/ui-input/file/template", "design/util/hover-mixin", "design/util/drag-over-mixin", "design/util/computed"], function (_exports, _template, _hoverMixin, _dragOverMixin, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hoverMixin.default, _dragOverMixin.default, {
    layout: _template.default,
    classNameBindings: [':ui-input-file', 'isDraggingOver:dragging-over', '_size'],
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    title: _computed.t.static('input.file.title'),
    disabled: false,
    actions: {
      onChange: function onChange(e) {
        var files = e.target.files;
        var file = files[0];

        if (!file) {
          return;
        }

        this.set('selection', file);
        var update = this.update;
        update && update(file);
      }
    }
  });

  _exports.default = _default;
});