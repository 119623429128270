define("ember-cli-zuglet/-private/storage/reference/reference", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/util/serialized", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _modelMixin, _serialized, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ref = ['fullPath', 'bucket', 'name'];
  var RefPropertiesMixin = Ember.Mixin.create(ref.reduce(function (hash, key) {
    hash[key] = Ember.computed('_internal.ref', function () {
      return this._internal.ref[key];
    }).readOnly();
    return hash;
  }, {}));

  var _default = Ember.Object.extend(_modelMixin.default, RefPropertiesMixin, {
    parent: Ember.computed('_internal.parent', function () {
      var internal = this.get('_internal.parent');
      return internal && internal.model(true);
    }).readOnly(),
    metadata: Ember.computed('_internal.metadata', function () {
      var internal = this.get('_internal.metadata');
      return internal && internal.model(true);
    }).readOnly(),
    url: Ember.computed('_internal.url', function () {
      var internal = this.get('_internal.url');
      return internal && internal.model(true);
    }).readOnly(),
    serialized: (0, _serialized.default)(ref),
    // { url, metatada, optional }
    load: (0, _invoke.invokePromiseReturningThis)('load'),
    delete: (0, _invoke.invokePromiseReturningThis)('delete'),
    // { type: 'data', data: ..., metadata: { } }
    // { type: 'string', data: ..., format: 'raw' / 'base64' / 'base64-url' / 'data-url', metadata: {} }
    put: function put(opts) {
      return this._internal.put(opts).model(true);
    },
    // will be deprecated in favor of `ref`
    child: (0, _invoke.invokeReturningModel)('child'),
    ref: (0, _invoke.invokeReturningModel)('child'),
    toStringExtension: function toStringExtension() {
      return this.get('fullPath');
    }
  });

  _exports.default = _default;
});