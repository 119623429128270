define("ember-cli-zuglet/-private/reference/collection/collection", ["exports", "ember-cli-zuglet/-private/reference/reference", "ember-cli-zuglet/-private/reference/queryable/mixin", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _reference, _mixin, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reference.default.extend(_mixin.default, {
    id: Ember.computed.readOnly('_internal.id'),
    path: Ember.computed.readOnly('_internal.path'),
    doc: (0, _invoke.invokeReturningModel)('doc')
  });

  _exports.default = _default;
});