define("ember-cli-zuglet/-private/observers/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/util/errors"], function (_exports, _internal, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    owner: null,
    observers: null,
    isEnabled: Ember.computed.gt('observers.length', 0),
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        deferred: Ember.RSVP.defer(),
        observers: Ember.A()
      });
    },
    observer: Ember.computed.readOnly('observers.lastObject'),
    promise: Ember.computed('observer.promise', function () {
      var promise = this.get('observer.promise');

      if (promise) {
        return promise;
      }

      return Ember.RSVP.reject((0, _errors.noObserversError)());
    }).readOnly(),
    factoryFor: function factoryFor(name) {
      return this.get('owner').factoryFor(name);
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:observers').create({
        _internal: this
      });
    },
    _withParent: function _withParent(fn) {
      var parentKey = this.get('opts.parent');

      if (!parentKey) {
        return;
      }

      var owner = this.get('owner');
      var parent = owner.get(parentKey);
      (false && !(!!parent) && Ember.assert("parent ".concat(parentKey, " is not set"), !!parent));
      fn(parent, owner);
    },
    _startObserving: function _startObserving() {
      this._withParent(function (parent, owner) {
        return parent.registerObservedInternal(owner);
      });

      this.opts.start.call(this.owner, this);
    },
    _stopObserving: function _stopObserving() {
      this.opts.stop.call(this.owner, this);

      this._withParent(function (parent, owner) {
        return parent.unregisterObservedInternal(owner);
      });
    },
    _withObservers: function _withObservers(cb) {
      var _this = this;

      var observers = this.get('observers');

      var builder = function builder(idx, remove, add) {
        return function (invocation) {
          var model;

          if (!_this.isDestroying) {
            model = _this.model(false);
          }

          if (model) {
            model.arrayContentWillChange(idx, remove, add);
          }

          invocation();

          if (model) {
            model.arrayContentDidChange(idx, remove, add);
          }
        };
      };

      cb(observers, builder);
    },
    _addObserver: function _addObserver(internal) {
      this._withObservers(function (observers, builder) {
        var idx = observers.get('length');
        var change = builder(idx, 0, 1);
        change(function () {
          return observers.pushObject(internal);
        });
      });
    },
    _removeObserver: function _removeObserver(internal) {
      this._withObservers(function (observers, builder) {
        var idx = observers.indexOf(internal);
        var change = builder(idx, 1, 0);
        change(function () {
          return observers.removeObject(internal);
        });
      });
    },
    add: function add(factoryName, props) {
      var _this2 = this;

      if (this.isDestroying) {
        return;
      }

      if (this.get('observers.length') === 0) {
        this._startObserving();
      }

      var promise = this.get('deferred.promise');

      var cancel = function cancel(internal) {
        return _this2.cancelObserver(internal);
      };

      var state = {
        promise: promise,
        cancel: cancel
      };
      var internal = this.factoryFor(factoryName).create(Ember.assign({
        observers: this,
        state: state
      }, props));

      this._addObserver(internal);

      return internal;
    },
    cancelObserver: function cancelObserver(internal) {
      if (internal.get('isCancelled')) {
        return;
      }

      internal.set('isCancelled', true);

      if (this.get('observers.length') === 1) {
        this._stopObserving();

        this.resolve();
      }

      this._removeObserver(internal);
    },
    resolve: function resolve(arg) {
      this.get('deferred').resolve(arg);
    },
    willDestroy: function willDestroy() {
      var _this3 = this;

      this.get('observers').map(function (observer) {
        return _this3.cancelObserver(observer);
      });
      this.resolve();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});