define("design/components/ui-block/guidelines/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nyf9Lj73",
    "block": "{\"symbols\":[\"line\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"lines\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"line \",[23,1,[\"className\"]]]]],[11,\"style\",[23,1,[\"style\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[14,2,[[23,0,[]]]],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/guidelines/template.hbs"
    }
  });

  _exports.default = _default;
});