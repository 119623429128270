define("design/components/ui-block/segmented-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/i1Qs55",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"segment\"],[[28,\"component\",[\"ui-block/segmented-button/segment\"],null]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/segmented-button/template.hbs"
    }
  });

  _exports.default = _default;
});