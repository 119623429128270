define("ember-cli-sketch/-private/actions/stage/zoom", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    normalize: function normalize(stage) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var delta = opts.delta,
          value = opts.value;

      if (delta) {
        value = stage.attributes.clampDelta('zoom', delta);

        if (!value) {
          return;
        }

        return stage.zoom + value;
      }

      return value;
    },
    perform: function perform(stage, opts) {
      var zoom = this.normalize(stage, opts);

      if (!zoom) {
        return;
      }

      var prev = stage.zoom;
      var size = stage.renderer.size;
      var props = stage.frame.properties;

      var calc = function calc(d, s) {
        var point = size[s] / 2;
        var p = point / prev;
        var n = point / zoom;
        var v = p - n;
        return props[d] - v;
      };

      var x = calc('x', 'width');
      var y = calc('y', 'height');
      stage.update({
        zoom: zoom,
        x: x,
        y: y
      });
    }
  });

  _exports.default = _default;
});