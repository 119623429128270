define("analytics/models/analytics/tawk", ["exports", "analytics/models/analytics/-analytics"], function (_exports, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analytics.default.extend({
    window: Ember.inject.service(),
    tawk: Ember.computed(function () {
      return window.Tawk_API; // eslint-disable-line no-undef
    }).readOnly(),
    getInstance: function getInstance() {
      var tawk = this.tawk;

      if (!tawk) {
        return;
      }

      if (!tawk.onLoaded) {
        return;
      }

      return tawk;
    },
    show: function show() {
      if (typeof loadTawk === 'function') {
        // eslint-disable-next-line no-undef
        loadTawk();
      }
    },
    sendPageView: function sendPageView(_ref) {
      var url = _ref.url,
          path = _ref.path;
      var tawk = this.getInstance();
      url = this.window.absoluteUrl(url);
      tawk && tawk.addEvent && tawk.addEvent('pageview', {
        url: url,
        path: path
      });
    }
  });

  _exports.default = _default;
});