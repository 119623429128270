define("design/components/ui-block/tabs/component", ["exports", "design/components/ui-block/tabs/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-block-tabs']
  });

  _exports.default = _default;
});