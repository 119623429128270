define("ember-cli-zuglet/-private/storage/storage/storage", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    tasks: Ember.computed.readOnly('_internal.tasks'),
    ref: function ref(opts) {
      return this._internal.createInternalReferenceWithOptions(opts).model(true);
    }
  });

  _exports.default = _default;
});