define("ember-cli-zuglet/-private/query/first/internal", ["exports", "ember-cli-zuglet/-private/query/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    type: 'first',
    content: null,
    proxy: Ember.computed('content', function () {
      var content = this.get('content');

      if (!content) {
        return null;
      }

      return content.model(true);
    }).readOnly(),
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:query/first').create({
        _internal: this
      });
    },
    normalizedQuery: Ember.computed('query', function () {
      var query = this.get('query');

      if (!query) {
        return;
      }

      return query.limit(1);
    }),
    onReplace: function onReplace(snapshot) {
      var change = snapshot.docs[0];
      var current = this.get('content');

      if (change) {
        if (current && current.get('ref.path') === change.ref.path) {
          this.updateInternalDocumentForSnapshot(current, change);
        } else {
          var doc = this.createInternalDocumentForSnapshot(change);
          this.set('content', doc);
        }
      } else {
        this.set('content', null);
      }
    },
    didLoad: function didLoad(snapshot) {
      this.onReplace(snapshot);
      return this._super.apply(this, arguments);
    },
    onSnapshot: function onSnapshot(snapshot) {
      this.onReplace(snapshot);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});