define("ember-cli-zuglet/-private/auth/auth/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/queue/computed", "ember-cli-zuglet/-private/util/actions", "ember-cli-zuglet/-private/util/settle"], function (_exports, _internal, _computed, _actions, _settle2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var queue = function queue() {
    return (0, _computed.default)('serialized', 'store.queue');
  };

  var _default = _internal.default.extend({
    store: null,
    user: null,
    queue: queue(),
    state: queue(),
    _deferred: Ember.computed(function () {
      return Ember.RSVP.defer();
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this._waitForUser = Ember.A();
    },
    prepare: function prepare() {
      var _this = this;

      this.startObservingAuthState();
      return this.get('_deferred').promise.then(function () {
        return _this.settle();
      });
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:auth').create({
        _internal: this
      });
    },
    factoryFor: function factoryFor(name) {
      return this.store.factoryFor(name);
    },
    auth: Ember.computed(function () {
      return this.store.app.auth();
    }).readOnly(),
    methods: Ember.computed(function () {
      return this.store.factoryFor('zuglet:auth/methods/internal').create({
        auth: this
      });
    }).readOnly(),
    //
    createUserInternal: function createUserInternal(user) {
      return this.factoryFor('zuglet:auth/user/internal').create({
        auth: this,
        user: user
      });
    },
    restoreUserInternal: function restoreUserInternal(internal) {
      var store = this.get('store').model(true);
      return Ember.RSVP.resolve().then(function () {
        if (store.isDestroying) {
          return;
        }

        var model = null;

        if (internal) {
          if (internal.isDestroying) {
            return;
          }

          model = internal.model(true);
        }

        return store.restoreUser(model);
      });
    },
    restoreUser: function restoreUser(user) {
      var _this2 = this;

      if (this.isDestroying) {
        return;
      }

      var current = this.get('user');
      var internal = null;

      if (current && current.get('user') === user) {
        internal = current;
        internal.notifyPropertyChange('user');
      } else if (user) {
        internal = this.createUserInternal(user);
      }

      if (current !== internal) {
        this.set('user', internal);

        if (current) {
          current.destroy();
        }
      }

      this.get('_deferred').resolve();
      return this.restoreUserInternal(internal).then(function () {
        return _this2.notifyUser();
      });
    },
    //
    onAuthStateChanged: function onAuthStateChanged(user) {
      var _this3 = this;

      return this.get('state').schedule({
        name: 'restore',
        invoke: function invoke() {
          return _this3.restoreUser(user);
        }
      });
    },
    startObservingAuthState: function startObservingAuthState() {
      var _this4 = this;

      this._authStateObserver = this.get('auth').onAuthStateChanged(function (user) {
        return (0, _actions.default)(function () {
          return _this4.onAuthStateChanged(user);
        });
      });
    },
    stopObservingAuthState: function stopObservingAuthState() {
      this._authStateObserver && this._authStateObserver();
    },
    //
    waitForUser: function waitForUser() {
      var deferred = Ember.RSVP.defer();
      var promise = deferred.promise;
      var array = this._waitForUser;
      var object = {};

      var resolve = function resolve() {
        return deferred.resolve();
      };

      var cancel = function cancel() {
        return array.removeObject(object);
      };

      object = {
        resolve: resolve,
        cancel: cancel,
        promise: promise
      };
      array.pushObject(object);
      return object;
    },
    notifyUser: function notifyUser() {
      if (this.isDestroying) {
        return;
      }

      var listeners = this._waitForUser.slice();

      this._waitForUser.clear();

      listeners.forEach(function (listener) {
        return listener.resolve();
      });
    },
    //
    signOut: function signOut() {
      return this.withAuthReturningUser(function (auth) {
        return auth.signOut().then(function () {
          return null;
        });
      });
    },
    //
    settle: function settle() {
      var _this5 = this;

      var promises = function promises(key) {
        return _this5.get(key).promises();
      };

      return (0, _settle2.default)(function () {
        return [].concat(_toConsumableArray(promises('queue')), _toConsumableArray(promises('state')));
      });
    },
    withAuth: function withAuth(fn) {
      var auth = this.get('auth');
      return Ember.RSVP.resolve(fn(auth));
    },
    withAuthReturningUser: function withAuthReturningUser(fn) {
      var _this6 = this;

      return this.get('queue').schedule({
        name: 'auth',
        invoke: function invoke() {
          return _this6.withAuth(function (auth) {
            var current = _this6.get('user.user') || null;

            var waiter = _this6.waitForUser();

            return fn(auth).then(function (next) {
              if (current === next) {
                waiter.cancel();
                return _this6.restoreUser(next);
              } else {
                return waiter.promise;
              }
            }).then(function () {
              return _this6.get('user');
            });
          });
        }
      });
    },
    //
    willDestroy: function willDestroy() {
      this.stopObservingAuthState();
      var user = this.get('user');
      user && user.destroy();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});