define("ember-cli-zuglet/-private/auth/methods/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.types = _exports.default = void 0;
  var types = Ember.A(['anonymous', 'email']);
  _exports.types = types;

  var _default = _internal.default.extend({
    auth: null,
    factoryFor: function factoryFor(name) {
      return this.auth.factoryFor(name);
    },
    types: Ember.computed(function () {
      return Ember.A(types.slice());
    }).readOnly(),
    methods: Ember.computed(function () {
      return Object.create(null);
    }).readOnly(),
    createModel: function createModel() {
      return this.auth.factoryFor('zuglet:auth/methods').create({
        _internal: this
      });
    },
    createInternalMethod: function createInternalMethod(type) {
      return this.auth.factoryFor("zuglet:auth/method/".concat(type, "/internal")).create({
        methods: this,
        type: type
      });
    },
    method: function method(name) {
      if (!types.includes(name)) {
        return;
      }

      var methods = this.get('methods');
      var method = methods[name];

      if (!method) {
        method = this.createInternalMethod(name);
        methods[name] = method;
      }

      return method;
    }
  });

  _exports.default = _default;
});