define("ember-cli-zuglet/-private/functions/callable/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/queue/computed"], function (_exports, _internal, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    functions: null,
    name: null,
    callable: null,
    queue: (0, _computed.default)('serialized', 'functions.store.queue'),
    createModel: function createModel() {
      return this.functions.factoryFor('zuglet:functions/callable').create({
        _internal: this
      });
    },
    call: function call(opts) {
      var _this = this;

      return this.get('queue').schedule({
        name: 'functions/callable',
        invoke: function invoke() {
          return _this.callable(opts);
        }
      });
    }
  });

  _exports.default = _default;
});