define("ember-cli-sketch/-private/stage/interactions/handlers/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    handlers: null,
    interactions: Ember.computed.readOnly('handlers.interactions'),
    mouse: Ember.computed.readOnly('interactions.mouse'),
    keyboard: Ember.computed.readOnly('interactions.keyboard'),
    stage: Ember.computed.readOnly('interactions.stage'),
    zoom: Ember.computed.readOnly('stage.zoom'),
    hover: Ember.computed.readOnly('stage.hover'),
    selection: Ember.computed.readOnly('stage.selection'),
    onMouseOver: function onMouseOver() {},
    onMouseOut: function onMouseOut() {},
    onMouseDown: function onMouseDown() {},
    onMouseClick: function onMouseClick() {},
    onMouseDoubleClick: function onMouseDoubleClick() {},
    onMouseMove: function onMouseMove() {},
    onMouseUp: function onMouseUp() {},
    onMouseWheel: function onMouseWheel() {},
    onKeyDown: function onKeyDown() {},
    onKeyUp: function onKeyUp() {},
    reset: function reset() {}
  });

  _exports.default = _default;
});