define("design/components/ui-block/highlight/block/component", ["exports", "design/components/ui-block/highlight/block/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    target: Ember.computed.readOnly('childViews.firstObject'),
    hasLabel: Ember.computed.bool('label'),
    label: null,
    offset: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.highlight.registerBlock(this);
      this.startObservingLabel();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.highlight.unregisterBlock(this);
      this.stopObservingLabel();
    },
    startObservingLabel: function startObservingLabel() {
      this.addObserver('hasLabel', this, this.labelDidChange);
    },
    stopObservingLabel: function stopObservingLabel() {
      this.removeObserver('hasLabel', this, this.labelDidChange);
    },
    labelDidChange: function labelDidChange() {
      if (!this.hasLabel) {
        this.highlight.dismiss();
      }
    }
  });

  _exports.default = _default;
});