define("design/components/ui-block/master-detail/detail/component", ["exports", "design/components/ui-block/master-detail/detail/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':detail']
  });

  _exports.default = _default;
});