define("ember-cli-zuglet/-private/util/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(fn) {
    return Ember.run(function () {
      return Ember.run.schedule('actions', fn);
    });
  };

  _exports.default = _default;
});