define("ember-cli-zuglet/-private/util/array-transform-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(hash) {
    return Ember.Mixin.create({
      objectAtContent: function objectAtContent(idx) {
        var object = Ember.A(Ember.get(this, 'arrangedContent')).objectAt(idx);
        return hash.public.call(this, object);
      },
      replaceContent: function replaceContent(idx, amt, objects) {
        var _this = this;

        (false && !(!!hash.internal) && Ember.assert("this array is not mutable", !!hash.internal));
        objects = Ember.A(objects).map(function (object) {
          return hash.internal.call(_this, object);
        });

        this._super(idx, amt, objects);
      }
    });
  };

  _exports.default = _default;
});