define("ember-cli-zuglet/-private/reference/query/query", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/reference/queryable/mixin"], function (_exports, _modelMixin, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, _mixin.default, {
    isReference: true,
    type: Ember.computed.readOnly('_internal.type'),
    args: Ember.computed.readOnly('_internal.args'),
    parent: Ember.computed(function () {
      var parent = this._internal.get('parent');

      return parent && parent.model(true);
    }),
    serialized: Ember.computed.readOnly('_internal.serialized'),
    string: Ember.computed.readOnly('_internal.string'),
    toStringExtension: function toStringExtension() {
      var string = this.get('string');
      return "".concat(string);
    }
  });

  _exports.default = _default;
});