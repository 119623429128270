define("ember-cli-zuglet/-private/data/primitive/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    supports: function supports() {
      return true;
    },
    matches: function matches(internal, value) {
      return Ember.typeOf(internal.content) === Ember.typeOf(value);
    },
    createInternal: function createInternal(content) {
      return this.factoryFor('zuglet:data/primitive/internal').create({
        serializer: this,
        content: content
      });
    },
    serialize: function serialize(internal) {
      return internal.content;
    },
    deserialize: function deserialize(internal, value) {
      if (internal.content === value) {
        return {
          replace: false,
          internal: internal
        };
      }

      internal.content = value;
      return {
        replace: true,
        internal: internal
      };
    },
    isDirty: function isDirty(internal, raw) {
      return internal.content !== raw;
    }
  });

  _exports.default = _default;
});