define("ember-cli-sketch/-private/stage/guidelines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Object.extend({
    stage: null,
    enabled: Ember.computed('stage.{tools.selected.guidelines,model.guidelines}', function () {
      var stage = this.stage;
      return stage.model.guidelines && stage.tools.selected.guidelines;
    }).readOnly(),
    nodes: Ember.computed.readOnly('stage.selection.attached'),
    all: Ember.computed('nodes.@each._guidelines', function () {
      var nodes = this.nodes;
      var all = [];
      nodes.forEach(function (node) {
        var guidelines = node._guidelines;

        if (guidelines) {
          all.push.apply(all, _toConsumableArray(guidelines));
        }
      });
      return all;
    }).readOnly(),
    visible: Ember.computed('all', 'enabled', function () {
      if (this.enabled) {
        return this.all;
      }

      return;
    }).readOnly()
  });

  _exports.default = _default;
});