define("design/components/ui-block/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+n3NezrA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"present\",\"dismiss\",\"block\"],[[28,\"action\",[[23,0,[]],\"present\"],null],[28,\"action\",[[23,0,[]],\"dismiss\"],null],[28,\"component\",[\"ui-block/highlight/block\"],[[\"highlight\"],[[23,0,[]]]]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"marker\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/highlight/marker\",null,[[\"position\",\"label\",\"dismiss\"],[[24,[\"marker\",\"position\"]],[24,[\"marker\",\"label\"]],[28,\"action\",[[23,0,[]],\"dismiss\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/highlight/template.hbs"
    }
  });

  _exports.default = _default;
});