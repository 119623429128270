define("ember-cli-sketch/-private/stage/tools/node/move", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    perform: function perform(_ref) {
      var key = _ref.key;
      var stage = this.stage,
          selection = this.selection,
          keyboard = this.keyboard;
      var delta = {
        x: 0,
        y: 0
      };
      var d = keyboard.isShift ? 15 : 1;

      if (key.isArrowUp) {
        delta.y = -d;
      } else if (key.isArrowDown) {
        delta.y = d;
      } else if (key.isArrowLeft) {
        delta.x = -d;
      } else if (key.isArrowRight) {
        delta.x = d;
      } else {
        return;
      }

      var nodes = selection.selectable;
      nodes.forEach(function (node) {
        return node.update(delta, {
          delta: true
        });
      });
      stage.nodesPerform(nodes, 'move-to-container');
    },
    activate: function activate(_ref2) {
      var key = _ref2.key;

      if (this.selection.any) {
        this.perform({
          key: key
        });
      }

      this.done();
    }
  });

  _exports.default = _default;
});