define("ember-cli-sketch/-private/node/frame/-bounds-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var keys = ['x', 'y', 'width', 'height', 'rotation'];

  var property = function property(frameKey, propertyKey) {
    return Ember.computed(frameKey, function () {
      return this[frameKey][propertyKey];
    }).readOnly();
  };

  var frame = function frame(ownerNodesKey, sourceKey) {
    return Ember.computed("".concat(ownerNodesKey, ".@each.").concat(sourceKey), function () {
      var nodes = this.get(ownerNodesKey);

      if (!nodes.length) {
        return;
      }

      var box = {
        min: {
          x: Number.POSITIVE_INFINITY,
          y: Number.POSITIVE_INFINITY
        },
        max: {
          x: Number.NEGATIVE_INFINITY,
          y: Number.NEGATIVE_INFINITY
        }
      };
      nodes.forEach(function (node) {
        var frame = node[sourceKey];
        box.min.x = Math.min(box.min.x, frame.x);
        box.min.y = Math.min(box.min.y, frame.y);
        box.max.x = Math.max(box.max.x, frame.x + frame.width);
        box.max.y = Math.max(box.max.y, frame.y + frame.height);
      });
      return {
        x: box.min.x,
        y: box.min.y,
        width: box.max.x - box.min.x,
        height: box.max.y - box.min.y,
        rotation: 0
      };
    }).readOnly();
  };

  var _default = function _default(framePropertyKey, ownerNodesKey, sourceKey) {
    return Ember.Mixin.create(Ember.assign(_defineProperty({}, framePropertyKey, frame(ownerNodesKey, sourceKey)), keys.reduce(function (hash, key) {
      hash[key] = property(framePropertyKey, key);
      return hash;
    }, {})));
  };

  _exports.default = _default;
});