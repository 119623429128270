define("design/components/ui-block/highlight/marker/component", ["exports", "design/components/ui-block/highlight/marker/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-highlight-marker'],
    attributeBindings: ['style'],
    layout: _template.default,
    position: null,
    label: null,
    style: Ember.computed('position', function () {
      var position = this.position;

      if (!position) {
        return;
      }

      var top = position.top,
          left = position.left;
      return Ember.String.htmlSafe("top: ".concat(top, "px; left: ").concat(left, "px"));
    }).readOnly(),
    click: function click() {
      this.dismiss();
    }
  });

  _exports.default = _default;
});