define("design/util/mouse-enter-leave-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    handleMouseEnter: function handleMouseEnter() {},
    handleMouseLeave: function handleMouseLeave() {},
    _withMouseEnterLeave: function _withMouseEnterLeave(cb) {
      var _this = this;

      var callbacks = this.__mouseEnterLeave;

      if (!callbacks) {
        callbacks = {
          'mouseenter': function mouseenter(e) {
            return Ember.run(function () {
              return _this.handleMouseEnter(e);
            });
          },
          'mouseleave': function mouseleave(e) {
            return Ember.run(function () {
              return _this.handleMouseLeave(e);
            });
          }
        };
        this.__mouseEnterLeave = callbacks;
      }

      var element = this.element;
      Object.keys(callbacks).forEach(function (name) {
        return cb(element, name, callbacks[name]);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._withMouseEnterLeave(function (element, name, handler) {
        return element.addEventListener(name, handler);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._withMouseEnterLeave(function (element, name, handler) {
        return element.removeEventListener(name, handler);
      });
    }
  });

  _exports.default = _default;
});