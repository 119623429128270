define("ember-cli-sketch/-private/stage/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    edit: 'Edit',
    loadingFont: 'Loading font…'
  });

  _exports.default = _default;
});