define("ember-cli-sketch/-private/node/attributes/attribute/number", ["exports", "ember-cli-sketch/-private/node/attributes/attribute/-base", "ember-cli-sketch/-private/util/math"], function (_exports, _base, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    numberConstraints: Ember.computed('min', 'max', function () {
      var _this$opts = this.opts,
          decimals = _this$opts.decimals,
          initial = _this$opts.initial,
          min = this.min,
          max = this.max;
      return (0, _math.numberConstraints)({
        decimals: decimals,
        min: min,
        max: max,
        initial: initial
      });
    }).readOnly(),
    transformValue: function transformValue(value) {
      if (typeof value !== 'number') {
        value = parseFloat(value);
      }

      if (isNaN(value)) {
        value = undefined;
      }

      if (value === undefined) {
        value = this.opts.initial;
      }

      value = this.numberConstraints(value);
      return value;
    },
    clamp: function clamp(value) {
      if (this.immutable) {
        return this.getValue();
      }

      return this.numberConstraints(value);
    },
    clampDelta: function clampDelta(value) {
      var current = this.getValue();
      return this.clamp(current + value) - current;
    }
  });

  _exports.default = _default;
});