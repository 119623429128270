define("ember-cli-sketch/components/ui-block/sketch/stage/node/-computed", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/-computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "className", {
    enumerable: true,
    get: function get() {
      return _computed.className;
    }
  });
  Object.defineProperty(_exports, "editing", {
    enumerable: true,
    get: function get() {
      return _computed.editing;
    }
  });
  Object.defineProperty(_exports, "fontLoader", {
    enumerable: true,
    get: function get() {
      return _computed.fontLoader;
    }
  });
  _exports.style = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var style = function style() {
    for (var _len = arguments.length, deps = new Array(_len), _key = 0; _key < _len; _key++) {
      deps[_key] = arguments[_key];
    }

    var fn = deps.pop();
    return _computed.style.apply(void 0, ['frame'].concat(deps, [function () {
      var frame = this.frame;
      var results = fn.call(this, this);

      if (Ember.typeOf(results) !== 'array') {
        results = [results];
      }

      return [frame].concat(_toConsumableArray(results));
    }]));
  };

  _exports.style = style;
});