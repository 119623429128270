define("ember-cli-zuglet/-private/internal/read-only-props-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(keys) {
    return Ember.Mixin.create(keys.reduce(function (props, key) {
      props[key] = Ember.computed.readOnly("_internal.".concat(key));
      return props;
    }, {}));
  };

  _exports.default = _default;
});