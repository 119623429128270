define("ember-cli-sketch/-private/actions/stage/-center-fit", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    frameForOptions: function frameForOptions(stage) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var type = opts.type;

      if (!type) {
        return stage.nodes.frame;
      } else if (type === 'containers') {
        return stage.nodes.containers.frame;
      }
    }
  });

  _exports.default = _default;
});