define("design/components/ui-input/textarea/component", ["exports", "design/components/ui-input/textarea/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'textarea',
    layout: _template.default,
    classNameBindings: [':ui-input-textarea', '_size', '_color', 'disabled:disabled:enabled'],
    attributeBindings: ['autofocus', 'spellcheck', 'autocapitalize', 'autocorrect', 'disabled', 'placeholder'],
    placeholder: null,
    value: null,
    autofocus: false,
    spellcheck: false,
    autocapitalize: 'off',
    autocorrect: 'off',
    disabled: false,
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    input: function input(e) {
      var value = e.target.value;
      this.update && this.update(value);
    }
  });

  _exports.default = _default;
});