define("ember-cli-sketch/components/ui-block/sketch/stage/selections/selection/marker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':marker', 'vertical', 'horizontal', 'disabled:disabled', 'over:over'],
    over: Ember.computed('edge.serialized', 'vertical', 'horizontal', function () {
      var edge = this.edge.serialized;

      if (!edge) {
        return;
      }

      return edge.horizontal === this.horizontal && edge.vertical === this.vertical;
    }).readOnly()
  });

  _exports.default = _default;
});