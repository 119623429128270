define("ember-cli-sketch/components/ui-block/sketch/stage/selections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VK/+sXWB",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"each\",[[24,[\"stage\",\"node\",\"selection\",\"selectable\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/stage/selections/selection\",null,[[\"model\"],[[23,1,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/selections/template.hbs"
    }
  });

  _exports.default = _default;
});