define("ember-cli-zuglet/-private/document/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/util/set-changed-properties", "ember-cli-zuglet/-private/reference/document/internal", "ember-cli-zuglet/-private/observers/computed", "ember-cli-zuglet/-private/queue/computed", "ember-cli-zuglet/-private/util/actions", "ember-cli-zuglet/-private/util/random-string"], function (_exports, _internal, _setChangedProperties, _internal2, _computed, _computed2, _actions, _randomString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.state = _exports.meta = _exports.default = void 0;
  var state = ['isNew', 'isDirty', 'isLoading', 'isLoaded', 'isSaving', 'isObserving', 'isError', 'error'];
  _exports.state = state;
  var meta = ['exists', 'metadata'];
  _exports.meta = meta;
  var _token = '_token';

  var _default = _internal.default.extend({
    store: null,
    ref: null,
    data: null,
    init: function init() {
      this._super.apply(this, arguments);

      (0, _internal2.assertDocumentInternalReference)('document-internal.ref', this.get('ref'));
    },
    isNew: false,
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    isError: false,
    error: null,
    isObserving: Ember.computed.readOnly('observers.isEnabled'),
    isDirty: Ember.computed.readOnly('data.isDirty'),
    exists: undefined,
    _metadata: undefined,
    token: Ember.computed(function () {
      return (0, _randomString.default)(20);
    }).readOnly(),
    metadata: Ember.computed('_metadata', function () {
      var metadata = this.get('_metadata');

      if (!metadata) {
        return;
      }

      return {
        fromCache: metadata.fromCache,
        hasPendingWrites: metadata.hasPendingWrites
      };
    }).readOnly(),
    queue: (0, _computed2.default)('serialized', 'store.queue'),
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:document').create({
        _internal: this
      });
    },
    factoryFor: function factoryFor(name) {
      return this.get('store').factoryFor(name);
    },
    onData: function onData(props, deserialize) {
      this.data.commit(props, deserialize);
    },
    onNew: function onNew(props) {
      this.onData(props);
      (0, _setChangedProperties.default)(this, {
        isNew: true
      });
    },
    _onDocumentError: function _onDocumentError(operation, err, opts) {
      this.store.onDocumentError(this, operation, err, opts);
    },
    _didLoad: function _didLoad(snapshot) {
      var exists = snapshot.exists,
          _metadata = snapshot.metadata;
      (0, _setChangedProperties.default)(this, {
        isNew: false,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        exists: exists,
        _metadata: _metadata
      });
      this.resolveObservers();
    },
    shouldApplySnapshotData: function shouldApplySnapshotData(json) {
      var token = json[_token];
      delete json[_token];
      return this.get('token') !== token;
    },
    onSnapshot: function onSnapshot(snapshot) {
      if (snapshot.exists) {
        var json = snapshot.data({
          serverTimestamps: 'estimate'
        });

        if (this.shouldApplySnapshotData(json)) {
          this.onData(json);
        }
      }

      this._didLoad(snapshot);
    },
    //
    willLoad: function willLoad() {
      (0, _setChangedProperties.default)(this, {
        isLoading: true,
        isError: false,
        error: null
      });
    },
    didLoad: function didLoad(snapshot) {
      this.onSnapshot(snapshot);
      return this;
    },
    loadDidFail: function loadDidFail(err, opts) {
      (0, _setChangedProperties.default)(this, {
        isLoading: false,
        isError: true,
        error: err
      });

      this._onDocumentError(this, 'load', err, opts);

      return Ember.RSVP.reject(err);
    },
    load: function load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var force = opts.force,
          source = opts.source;

      var _this$getProperties = this.getProperties('isLoaded', 'isLoading'),
          isLoaded = _this$getProperties.isLoaded,
          isLoading = _this$getProperties.isLoading;

      if (isLoaded && !isLoading && !force) {
        return Ember.RSVP.resolve(this);
      }

      return this.get('queue').schedule({
        name: 'document/load',
        reuse: function reuse(operations) {
          return operations.findBy('name', 'document/load');
        },
        invoke: function invoke() {
          _this.willLoad();

          var ref = _this.get('ref.ref');

          return ref.get({
            source: source
          });
        },
        didResolve: function didResolve(snapshot) {
          return _this.didLoad(snapshot);
        },
        didReject: function didReject(err) {
          return _this.loadDidFail(err, opts);
        }
      });
    },
    reload: function reload(opts) {
      return this.load(Ember.assign({
        force: true
      }, opts));
    },
    willSave: function willSave(data, opts) {
      var token = opts.token;

      if (token) {
        data[_token] = this.get('token');
      }

      (0, _setChangedProperties.default)(this, {
        isSaving: true,
        isError: false,
        error: null
      });
    },
    didSave: function didSave(raw) {
      this.onData(raw, false);
      (0, _setChangedProperties.default)(this, {
        isNew: false,
        isSaving: false,
        isLoaded: true,
        exists: true
      });
      return this;
    },
    saveDidFail: function saveDidFail(err, opts) {
      var optional = opts.optional;

      if (err.code === 'not-found' && optional) {
        (0, _setChangedProperties.default)(this, {
          isSaving: false,
          exists: false
        });
        return this;
      } else {
        (0, _setChangedProperties.default)(this, {
          isSaving: false,
          isError: true,
          error: err
        });

        this._onDocumentError('save', err, opts);

        return Ember.RSVP.reject(err);
      }
    },
    _save: function _save(ref, data, opts) {
      var type = opts.type,
          merge = opts.merge;

      if (type === 'set') {
        return ref.set(data, {
          merge: merge
        }).then(function () {
          return data;
        });
      } else if (type === 'update') {
        return ref.update(data).then(function () {
          return data;
        });
      }

      (false && !(false) && Ember.assert("unsupported set type '".concat(type, "'"), false));
    },
    _normalizeSaveOptions: function _normalizeSaveOptions() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return Ember.assign({
        type: 'set',
        merge: false,
        optional: false
      }, opts);
    },
    _shouldSkipSave: function _shouldSkipSave(opts) {
      var _this$getProperties2 = this.getProperties('isDirty', 'isNew'),
          isDirty = _this$getProperties2.isDirty,
          isNew = _this$getProperties2.isNew;

      return opts.optional && opts.type === 'set' && !isDirty && !isNew;
    },
    save: function save(opts) {
      var _this2 = this;

      opts = this._normalizeSaveOptions(opts);
      return this.get('queue').schedule({
        name: 'document/save',
        invoke: function invoke() {
          if (_this2._shouldSkipSave(opts)) {
            return;
          }

          var ref = _this2.get('ref.ref');

          var data = _this2.get('data').serialize('raw');

          _this2.willSave(data, opts);

          return _this2._save(ref, data, opts);
        },
        didResolve: function didResolve(raw) {
          if (!raw) {
            return _this2;
          }

          return _this2.didSave(raw);
        },
        didReject: function didReject(err) {
          return _this2.saveDidFail(err, opts);
        }
      });
    },
    willDelete: function willDelete() {
      (0, _setChangedProperties.default)(this, {
        isSaving: true,
        isError: false,
        error: null
      });
    },
    didDelete: function didDelete() {
      (0, _setChangedProperties.default)(this, {
        isNew: false,
        isSaving: false,
        isLoaded: true,
        exists: false
      });
      return this;
    },
    deleteDidFail: function deleteDidFail(err) {
      (0, _setChangedProperties.default)(this, {
        isSaving: false,
        isError: true,
        error: err
      });

      this._onDocumentError('delete', err);

      return Ember.RSVP.reject(err);
    },
    delete: function _delete() {
      var _this3 = this;

      return this.get('queue').schedule({
        name: 'document/delete',
        invoke: function invoke() {
          var ref = _this3.get('ref.ref');

          _this3.willDelete();

          return ref.delete();
        },
        didResolve: function didResolve() {
          return _this3.didDelete();
        },
        didReject: function didReject(err) {
          return _this3.deleteDidFail(err);
        }
      });
    },
    //
    willObserve: function willObserve() {
      var _this$getProperties3 = this.getProperties('isLoading', 'isLoaded'),
          isLoading = _this$getProperties3.isLoading,
          isLoaded = _this$getProperties3.isLoaded;

      if (!isLoaded && !isLoading) {
        this.set('isLoading', true);
      }
    },
    onSnapshotError: function onSnapshotError(err) {
      this._onDocumentError('snapshot', err);
    },
    _subscribeRefOnSnapshot: function _subscribeRefOnSnapshot() {
      var _this4 = this;

      var ref = this.get('ref.ref');
      return ref.onSnapshot({
        includeMetadataChanges: true
      }, function (snapshot) {
        return (0, _actions.default)(function () {
          if (_this4.isDestroying) {
            return;
          }

          _this4.onSnapshot(snapshot);
        });
      }, function (err) {
        return (0, _actions.default)(function () {
          return _this4.onSnapshotError(err);
        });
      });
    },
    observers: (0, _computed.default)({
      parent: 'store',
      start: function start(state) {
        this.willObserve();
        state._cancel = this._subscribeRefOnSnapshot();
      },
      stop: function stop(state) {
        state._cancel();
      }
    }),
    resolveObservers: function resolveObservers() {
      this.get('observers').resolve(this.model(true));
    },
    observe: function observe() {
      var store = this.get('store');
      var doc = this;
      return this.get('observers').add('zuglet:observer/document/internal', {
        store: store,
        doc: doc
      });
    },
    reset: function reset() {
      var data = this.data;
      data.rollback();
    },
    //
    loadInTransaction: function loadInTransaction(transaction) {
      var _this5 = this;

      return this.get('queue').schedule({
        name: 'document/load/transaction',
        invoke: function invoke() {
          _this5.willLoad();

          var ref = _this5.get('ref.ref');

          return transaction.instance.get(ref);
        },
        didResolve: function didResolve(snapshot) {
          return _this5.didLoad(snapshot);
        },
        didReject: function didReject(err) {
          return _this5.loadDidFail(err);
        }
      });
    },
    saveInContext: function saveInContext(instance, opts) {
      opts = this._normalizeSaveOptions(opts);
      var _opts = opts,
          type = _opts.type,
          merge = _opts.merge;
      var ref = this.get('ref.ref');
      var data = this.get('data').serialize('raw');

      if (type === 'set') {
        return instance.set(ref, data, {
          merge: merge
        });
      } else if (type === 'update') {
        return instance.update(ref, data);
      }

      (false && !(false) && Ember.assert("unsupported set type '".concat(type, "'"), false));
    },
    saveInTransaction: function saveInTransaction(transaction, opts) {
      this.saveInContext(transaction.instance, opts);
    },
    saveInBatch: function saveInBatch(batch, opts) {
      this.saveInContext(batch.instance, opts);
    },
    deleteInContext: function deleteInContext(instance) {
      var ref = this.get('ref.ref');
      instance.delete(ref);
    },
    deleteInTransaction: function deleteInTransaction(transaction) {
      this.deleteInContext(transaction.instance);
    },
    deleteInBatch: function deleteInBatch(batch) {
      this.deleteInContext(batch.instance);
    }
  });

  _exports.default = _default;
});