define("ember-cli-zuglet/-private/lifecycle/models/property", ["exports", "ember-cli-zuglet/-private/util/destroyable"], function (_exports, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var get = function get(internal) {
    return internal.model(true);
  };

  var reusable = function reusable() {
    return true;
  };

  var create = function create(opts) {
    return function (key) {
      var parent = this;
      var owner = Ember.getOwner(this);
      return owner.factoryFor('zuglet:lifecycle/models/internal').create({
        parent: parent,
        key: key,
        opts: opts
      });
    };
  };

  var _default = function _default(opts) {
    return (0, _destroyable.default)({
      create: create(opts),
      reusable: reusable,
      get: get
    });
  };

  _exports.default = _default;
});