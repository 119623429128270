define("ember-cli-zuglet/-private/storage/task/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/util/set-changed-properties", "firebase", "ember-cli-zuglet/-private/util/actions"], function (_exports, _internal, _setChangedProperties, _firebase, _actions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATE_CHANGED = _firebase.default.storage.TaskEvent.STATE_CHANGED;

  var _default = _internal.default.extend({
    ref: null,
    type: null,
    task: null,
    isCompleted: false,
    isRunning: Ember.computed.not('isCompleted').readOnly(),
    isError: false,
    error: null,
    percent: Ember.computed('snapshot', function () {
      var snapshot = this.get('snapshot');

      if (!snapshot) {
        return 0;
      }

      var bytesTransferred = snapshot.bytesTransferred,
          totalBytes = snapshot.totalBytes;
      return Math.floor(bytesTransferred / totalBytes * 100);
    }).readOnly(),
    createModel: function createModel() {
      return this.ref.factoryFor('zuglet:storage/task').create({
        _internal: this
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var task = this.get('task');
      this.promise = Ember.RSVP.resolve(task);
      this.set('snapshot', task.snapshot);
      this.startObservingTask();
    },
    taskDidFinish: function taskDidFinish() {
      this.stopObservingTask();
    },
    onSnapshot: function onSnapshot(snapshot) {
      this.set('snapshot', snapshot);
    },
    onError: function onError(error) {
      (0, _setChangedProperties.default)(this, {
        isCompleted: true,
        isError: true,
        error: error
      });
      this.taskDidFinish();
    },
    onCompleted: function onCompleted() {
      (0, _setChangedProperties.default)(this, {
        isCompleted: true
      });
      this.ref.onTaskCompleted(this);
      this.taskDidFinish();
    },
    startObservingTask: function startObservingTask() {
      var _this = this;

      this._taskObserver = this.task.on(STATE_CHANGED, function (snapshot) {
        return (0, _actions.default)(function () {
          return _this.onSnapshot(snapshot);
        });
      }, function (err) {
        return (0, _actions.default)(function () {
          return _this.onError(err);
        });
      }, function () {
        return (0, _actions.default)(function () {
          return _this.onCompleted();
        });
      });
    },
    stopObservingTask: function stopObservingTask() {
      this._taskObserver && this._taskObserver();
    },
    willDestroy: function willDestroy() {
      this.stopObservingTask();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});