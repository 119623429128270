define("ember-cli-zuglet/-private/storage/reference/metadata/internal", ["exports", "ember-cli-zuglet/-private/storage/reference/base/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    ref: null,
    _metadata: null,
    createModel: function createModel() {
      return this.factoryFor('zuglet:storage/reference/metadata').create({
        _internal: this
      });
    },
    onMetadata: function onMetadata(_metadata) {
      this.onLoad({
        _metadata: _metadata
      });
    },
    didLoad: function didLoad(metadata) {
      this.onMetadata(metadata);
    },
    load: function load() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.ref.load(Ember.assign({
        metadata: true
      }, opts));
    },
    _load: function _load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!this.shouldLoad()) {
        return Ember.RSVP.resolve();
      }

      return this.get('queue').schedule({
        name: 'storage/reference/metadata/load',
        reuse: function reuse(operations) {
          return operations.findBy('name', 'storage/reference/metadata/load');
        },
        invoke: function invoke() {
          _this.willLoad();

          return _this.get('ref.ref').getMetadata();
        },
        didResolve: function didResolve(metadata) {
          return _this.didLoad(metadata);
        },
        didReject: function didReject(err) {
          return _this.loadDidFail(err, opts);
        }
      });
    },
    //
    didUpdate: function didUpdate(metadata) {
      this.onMetadata(metadata);
    },
    updateDidFail: function updateDidFail(err) {
      return this.onError(err, false);
    },
    update: function update(metadata) {
      var _this2 = this;

      return this.get('queue').schedule({
        name: 'storage/reference/metadata/update',
        reuse: function reuse(operations) {
          return operations.findBy('name', 'storage/reference/metadata/update');
        },
        invoke: function invoke() {
          return _this2.get('ref.ref').updateMetadata(metadata);
        },
        didResolve: function didResolve(metadata) {
          return _this2.didUpdate(metadata);
        },
        didReject: function didReject(err) {
          return _this2.updateDidFail(err);
        }
      });
    },
    onDeleted: function onDeleted() {
      this._super({
        _metadata: null
      });
    }
  });

  _exports.default = _default;
});