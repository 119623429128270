define("ember-cli-sketch/components/ui-block/sketch/stage/nodes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K4uxFFIA",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"each\",[[24,[\"model\",\"node\",\"nodes\",\"visible\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/stage/node\",null,[[\"model\"],[[23,1,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/nodes/template.hbs"
    }
  });

  _exports.default = _default;
});