define("ember-cli-sketch/-private/node/nodes/-base", ["exports", "ember-cli-sketch/-private/node/frame/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(condition) {
    return Ember.Object.extend({
      nodes: null,
      parent: Ember.computed.readOnly('nodes.parent'),
      stage: Ember.computed.readOnly('parent.stage'),
      all: Ember.computed.filterBy('nodes.all', condition, true),
      visible: Ember.computed.filterBy('all', 'isVisible', true),
      selectable: Ember.computed.filterBy('all', 'isSelectable', true),
      frame: (0, _base.frame)('nodes')
    });
  };

  _exports.default = _default;
});