define("ember-cli-zuglet/-private/observers/observer/observer", ["exports", "ember-cli-zuglet/-private/internal/model-mixin"], function (_exports, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    isCancelled: Ember.computed.readOnly('_internal.isCancelled'),
    promise: Ember.computed.readOnly('_internal.promise'),
    load: function load() {
      return this._internal.load();
    },
    cancel: function cancel() {
      return this._internal.cancel();
    }
  });

  _exports.default = _default;
});