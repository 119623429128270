define("ember-cli-zuglet/-private/store/store", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _modelMixin, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_modelMixin.default, {
    identifier: Ember.computed.readOnly('_internal.identifier'),
    ready: Ember.computed(function () {
      var _this = this;

      return this.get('_internal.ready').then(function () {
        return _this;
      });
    }).readOnly(),
    models: Ember.computed(function () {
      return this.get('_internal.models').model(true);
    }).readOnly(),
    auth: Ember.computed(function () {
      return this.get('_internal.auth').model(true);
    }).readOnly(),
    storage: Ember.computed(function () {
      return this.get('_internal.storage').model(true);
    }).readOnly(),
    functions: function functions(region) {
      return this._internal.functions(region).model(true);
    },
    observed: Ember.computed.readOnly('_internal.observedProxy'),
    collection: (0, _invoke.invokeReturningModel)('collection'),
    collectionGroup: (0, _invoke.invokeReturningModel)('collectionGroup'),
    doc: (0, _invoke.invokeReturningModel)('doc'),
    object: (0, _invoke.invokeReturningModel)('object'),
    array: (0, _invoke.invokeReturningModel)('array'),
    serverTimestamp: (0, _invoke.invokeReturningModel)('serverTimestamp'),
    transaction: (0, _invoke.invokePromiseReturningThis)('transaction'),
    batch: (0, _invoke.invoke)('batch'),
    settle: (0, _invoke.invokePromiseReturningModel)('settle'),
    restore: function restore() {},
    restoreUser: function restoreUser() {},
    onError: function onError() {},
    // document & query errors
    find: function find() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var string = args.map(function (arg) {
        return typeof arg === 'string' ? "\"".concat(arg, "\"") : arg;
      }).join(', ');
      console.warn("ember-cli-zuglet: store.find(".concat(string, ")"));
    }
  });

  _exports.default = _default;
});