define("ember-cli-zuglet/-private/lifecycle/models/index", ["exports", "ember-cli-zuglet/-private/lifecycle/models/property", "ember-cli-zuglet/-private/lifecycle/util/inline"], function (_exports, _property, _inline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var compact = function compact(array) {
    return Ember.A(array).compact();
  }; // foo('docs')
  // foo('type', owner => owner.type)


  var source = function source(args) {
    args = compact(args);

    if (args.length === 1) {
      return {
        dependencies: args,
        key: args[0]
      };
    } else if (args.length > 1) {
      var last = args[args.length - 1];

      if (typeof last === 'function') {
        var key = args.pop();
        return {
          dependencies: args,
          key: key
        };
      }
    }

    return undefined;
  };

  var build = function build(opts) {
    var nested = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    opts = Ember.assign({}, opts, nested);
    var prop = (0, _property.default)(opts);

    prop.owner = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return build(opts, {
        parent: compact(args)
      });
    };

    prop.object = function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return build(opts, {
        object: compact(args)
      });
    };

    prop.inline = function () {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      return build(opts, {
        inline: (0, _inline.default)(args)
      });
    };

    prop.named = function (arg) {
      return build(opts, {
        named: arg
      });
    };

    prop.mapping = function (arg) {
      return build(opts, {
        mapping: arg
      });
    };

    prop.source = function () {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      return build(opts, {
        source: source(args)
      });
    };

    return prop;
  };

  var _default = function _default() {
    var _build;

    var opts = {
      source: undefined,
      parent: [],
      object: [],
      inline: undefined,
      named: undefined,
      mapping: undefined
    };
    return (_build = build(opts, {})).source.apply(_build, arguments);
  };

  _exports.default = _default;
});