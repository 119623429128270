define("design/components/ui-input/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vsT7cVqw",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"selected\"],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"item\",\"component\",\"selected\"],[[24,[\"selected\"]],[28,\"component\",[\"ui-input/dropdown/item\"],[[\"item\",\"selected\",\"chevron\",\"select\"],[[24,[\"item\"]],true,true,[28,\"action\",[[23,0,[]],\"toggle\"],null]]]],true]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dropdown\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"visibleItems\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"hash\",null,[[\"item\",\"component\",\"selected\"],[[23,1,[]],[28,\"component\",[\"ui-input/dropdown/item\"],[[\"item\",\"selected\",\"select\"],[[23,1,[]],[28,\"eq\",[[23,1,[]],[24,[\"selected\"]]],null],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],[28,\"eq\",[[23,1,[]],[24,[\"selected\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});