define("ember-cli-sketch/-private/stage/tools/stage/drag", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    cursor: 'pointer',
    guidelines: true,
    update: function update(_ref) {
      var delta = _ref.delta;
      var frame = this.stage.frame.deltaToFrame(delta);
      this.stage.update(frame);
    },
    onMouseDown: function onMouseDown() {
      this.dragging = true;
    },
    onMouseUp: function onMouseUp() {
      this.dragging = false;
    },
    onMouseMove: function onMouseMove(_ref2) {
      var delta = _ref2.delta;

      if (!this.dragging) {
        return;
      }

      var zoom = this.zoom;
      delta.x = delta.x / zoom;
      delta.y = delta.y / zoom;
      this.update({
        delta: delta
      });
      return false;
    },
    onMouseWheel: function onMouseWheel(_ref3) {
      var direction = _ref3.direction,
          value = _ref3.value;
      this.update({
        delta: _defineProperty({}, direction, -value * 25)
      });
    },
    deactivate: function deactivate() {
      this.dragging = false;
    }
  });

  _exports.default = _default;
});