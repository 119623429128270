define("ember-cli-zuglet/-private/store/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/queue/computed", "ember-cli-zuglet/-private/util/settle", "ember-cli-zuglet/-private/util/destroy-cached", "ember-cli-zuglet/-private/firebase/instantiate", "ember-cli-zuglet/-private/util/firestore-types"], function (_exports, _internal, _computed, _settle2, _destroyCached, _instantiate, _firestoreTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var splitReference = function splitReference(ref, parentRef) {
    var refs = [];

    while (ref) {
      if (ref instanceof parentRef.constructor && parentRef.isEqual(ref)) {
        return refs.reverse();
      } else {
        refs.push(ref);
      }

      ref = ref.parent;
    }
  };

  var _default = _internal.default.extend({
    stores: null,
    identifier: null,
    factory: null,
    app: null,
    observed: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),
    observedProxy: Ember.computed(function () {
      var content = this.get('observed');
      return this.factoryFor('zuglet:store/observed').create({
        content: content
      });
    }).readOnly(),
    _deferred: Ember.computed(function () {
      return Ember.RSVP.defer();
    }).readOnly(),
    _functions: Ember.computed(function () {
      return Object.create(null);
    }).readOnly(),
    ready: Ember.computed.readOnly('_deferred.promise'),
    queue: (0, _computed.default)('concurrent'),
    factoryFor: function factoryFor(name) {
      return this.stores.factoryFor(name);
    },
    prepareFirebase: function prepareFirebase(model) {
      var _this = this;

      var identifier = this.get('identifier');
      var options = model.get('options');
      (false && !(!!identifier) && Ember.assert("identifier is required", !!identifier));
      (false && !(_typeof(options) === 'object') && Ember.assert("options must be object", _typeof(options) === 'object'));
      this.allocator = (0, _instantiate.default)(this, identifier, options);
      return this.allocator.promise.then(function (app) {
        return _this.app = app;
      });
    },
    prepareAuth: function prepareAuth() {
      return this.get('auth').prepare();
    },
    prepareModel: function prepareModel(model) {
      return Ember.RSVP.resolve(model.restore());
    },
    didCreateModel: function didCreateModel(model) {
      var _this2 = this;

      Ember.RSVP.resolve().then(function () {
        return _this2.prepareFirebase(model);
      }).then(function () {
        return _this2.prepareAuth(model);
      }).then(function () {
        return _this2.prepareModel(model);
      }).then(function () {
        return _this2.get('_deferred').resolve();
      });
    },
    createModel: function createModel() {
      return this.factory.create({
        _internal: this
      });
    },
    _createInternalReferenceForNestedReference: function _createInternalReferenceForNestedReference(ref, internal) {
      var _this3 = this;

      (false && !(!!ref) && Ember.assert("ref is required", !!ref));
      (false && !(!!internal) && Ember.assert("parent is required", !!internal));
      var refs = splitReference(ref, internal.ref);
      (false && !(!!refs) && Ember.assert("ref parent must be ".concat(internal), !!refs));
      return refs.reduce(function (parent, ref) {
        if ((0, _firestoreTypes.isFirestoreDocumentReference)(ref)) {
          return _this3._createInternalDocumentReferenceForReference(ref, parent);
        } else if ((0, _firestoreTypes.isFirestoreCollectionReference)(ref)) {
          return _this3._createInternalCollectionReferenceForReference(ref, parent);
        }

        (false && !(false) && Ember.assert("ref must be a document or collection reference", false));
      }, internal);
    },
    _createInternalDocumentReferenceForReference: function _createInternalDocumentReferenceForReference(ref, _parent) {
      return this.factoryFor('zuglet:reference/document/internal').create({
        store: this,
        ref: ref,
        _parent: _parent
      });
    },
    createInternalDocumentReferenceForReference: function createInternalDocumentReferenceForReference(ref, parent) {
      if (parent) {
        return this._createInternalReferenceForNestedReference(ref, parent);
      }

      return this._createInternalDocumentReferenceForReference(ref);
    },
    _createInternalCollectionReferenceForReference: function _createInternalCollectionReferenceForReference(ref, _parent) {
      return this.factoryFor('zuglet:reference/collection/internal').create({
        store: this,
        ref: ref,
        _parent: _parent
      });
    },
    createInternalCollectionReferenceForReference: function createInternalCollectionReferenceForReference(ref, parent) {
      if (parent) {
        return this._createInternalReferenceForNestedReference(ref, parent);
      }

      return this._createInternalCollectionReferenceForReference(ref);
    },
    createInternalQueryReferenceForReference: function createInternalQueryReferenceForReference(ref, _parent, info) {
      return this.factoryFor('zuglet:reference/query/internal').create({
        store: this,
        ref: ref,
        _parent: _parent,
        info: info
      });
    },
    internalQueryFactoryNameForType: function internalQueryFactoryNameForType(type, sender) {
      if (type === 'array') {
        return 'zuglet:query/array/internal';
      } else if (type === 'first') {
        return 'zuglet:query/first/internal';
      }

      (false && !(false) && Ember.assert("query ".concat(sender, " must be 'array' or 'first'"), false));
    },
    createInternalQueryWithReference: function createInternalQueryWithReference(query) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      opts = Ember.assign({
        type: 'array',
        doc: null
      }, opts);
      var _opts = opts,
          type = _opts.type,
          doc = _opts.doc;
      var factoryName = this.internalQueryFactoryNameForType(type, 'opts.type');
      return this.factoryFor(factoryName).create({
        store: this,
        query: query,
        opts: {
          doc: doc
        }
      });
    },
    createInternalDocumentWithRef: function createInternalDocumentWithRef(ref) {
      var data = this.get('dataManager').createRootInternalObject();
      return this.factoryFor('zuglet:document/internal').create({
        store: this,
        ref: ref,
        data: data
      });
    },
    createNewInternalDocumentWithRef: function createNewInternalDocumentWithRef(ref, props) {
      var internal = this.createInternalDocumentWithRef(ref);
      internal.onNew(props);
      return internal;
    },
    createExistingInternalDocumentWithRef: function createExistingInternalDocumentWithRef(ref) {
      var internal = this.createInternalDocumentWithRef(ref);
      return internal;
    },
    createInternalDocumentForSnapshot: function createInternalDocumentForSnapshot(snapshot) {
      var ref = this.createInternalDocumentReferenceForReference(snapshot.ref);
      var internal = this.createInternalDocumentWithRef(ref);
      internal.onSnapshot(snapshot);
      return internal;
    },
    updateInternalDocumentForSnapshot: function updateInternalDocumentForSnapshot(internal, snapshot) {
      internal.onSnapshot(snapshot);
      return internal;
    },
    //
    collection: function collection(path) {
      var collection = this.app.firestore().collection(path);
      return this.createInternalCollectionReferenceForReference(collection);
    },
    collectionGroup: function collectionGroup(identifier) {
      var collection = this.app.firestore().collectionGroup(identifier);
      return this.createInternalCollectionReferenceForReference(collection); // TODO: collectionGroupReference
    },
    doc: function doc(path) {
      var ref = this.app.firestore().doc(path);
      return this.createInternalDocumentReferenceForReference(ref);
    },
    //
    dataManager: Ember.computed(function () {
      return this.factoryFor('zuglet:data/manager').create({
        store: this
      });
    }),
    object: function object() {
      var _this$get;

      return (_this$get = this.get('dataManager')).createInternalObject.apply(_this$get, arguments);
    },
    array: function array() {
      var _this$get2;

      return (_this$get2 = this.get('dataManager')).createInternalArray.apply(_this$get2, arguments);
    },
    serverTimestamp: function serverTimestamp() {
      return this.get('dataManager').createInternalServerTimestamp();
    },
    //
    models: Ember.computed(function () {
      return this.factoryFor('zuglet:models/internal').create({
        store: this
      });
    }).readOnly(),
    auth: Ember.computed(function () {
      return this.factoryFor('zuglet:auth/internal').create({
        store: this
      });
    }).readOnly(),
    storage: Ember.computed(function () {
      return this.factoryFor('zuglet:storage/internal').create({
        store: this
      });
    }).readOnly(),
    functions: function functions(region) {
      region = region || undefined;
      var internals = this.get('_functions');
      var internal = internals[region];

      if (!internal) {
        internal = this.factoryFor('zuglet:functions/internal').create({
          store: this,
          region: region
        });
        internals[region] = internal;
      }

      return internal;
    },
    //
    transaction: function transaction(fn) {
      return this.factoryFor('zuglet:transaction/internal').create({
        store: this,
        fn: fn
      }).run();
    },
    batch: function batch() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return this.factoryFor('zuglet:batch/internal').create({
        store: this,
        args: args
      }).run();
    },
    //
    registerObservedInternal: function registerObservedInternal(internal) {
      var observed = this.get('observed');
      (false && !(!observed.includes(internal)) && Ember.assert("observed already has ".concat(internal, " registered"), !observed.includes(internal)));
      observed.pushObject(internal);
    },
    unregisterObservedInternal: function unregisterObservedInternal(internal) {
      var observed = this.get('observed');
      (false && !(observed.includes(internal)) && Ember.assert("observed does not have ".concat(internal, " registered"), observed.includes(internal)));
      observed.removeObject(internal);
    },
    //
    onDocumentError: function onDocumentError(internal, operation, err, opts) {
      var model = internal.model(false);
      this.model(true).onError({
        type: 'document',
        model: model,
        operation: operation,
        err: err,
        opts: opts
      });
    },
    onQueryError: function onQueryError(internal, operation, err, opts) {
      var model = internal.model(false);
      this.model(true).onError({
        type: 'query',
        model: model,
        operation: operation,
        err: err,
        opts: opts
      });
    },
    //
    settle: function settle() {
      var _this4 = this;

      return (0, _settle2.default)(function () {
        return _toConsumableArray(_this4.get('queue').promises());
      });
    },
    willDestroy: function willDestroy() {
      (0, _destroyCached.default)(this, 'dataManager');
      (0, _destroyCached.default)(this, 'auth');
      (0, _destroyCached.default)(this, 'storage');
      this.get('observed').map(function (internal) {
        return internal.destroy();
      });
      this.allocator && this.allocator.destroy();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});