define("design/components/ui-block/dialogs/component", ["exports", "design/components/ui-block/dialogs/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    dialogs: Ember.inject.service(),
    label: null,
    model: Ember.computed('dialogs.models.firstObject', function () {
      var model = this.dialogs.models.firstObject;

      if (!model || model.presenter !== this) {
        return;
      }

      return model;
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.dialogs.registerPresenter(this);
    },
    willDestroyElement: function willDestroyElement() {
      this.dialogs.unregisterPresenter(this);

      this._super.apply(this, arguments);
    },
    actions: {
      cancel: function cancel() {
        this.model.cancel();
      }
    },
    toStringExtension: function toStringExtension() {
      return this.label;
    }
  });

  _exports.default = _default;
});