define("ember-cli-zuglet/-private/storage/storage/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/queue/computed"], function (_exports, _internal, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    store: null,
    queue: (0, _computed.default)('concurrent', 'store.queue'),
    factoryFor: function factoryFor(name) {
      return this.store.factoryFor(name);
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:storage').create({
        _internal: this
      });
    },
    storage: Ember.computed(function () {
      return this.store.app.storage();
    }).readOnly(),
    tasks: Ember.computed(function () {
      var content = this.get('queue.operations');
      return this.factoryFor('zuglet:storage/tasks').create({
        content: content
      });
    }).readOnly(),
    //
    refFromOptions: function refFromOptions() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (typeof opts === 'string') {
        opts = {
          path: opts
        };
      }

      var _opts = opts,
          path = _opts.path,
          url = _opts.url;
      (false && !(path || url) && Ember.assert("path or url is requied", path || url));
      var storage = this.get('storage');

      if (path) {
        return storage.ref(path);
      }

      return storage.refFromURL(url);
    },
    createInternalReference: function createInternalReference(ref) {
      return this.factoryFor('zuglet:storage/reference/internal').create({
        storage: this,
        ref: ref
      });
    },
    createInternalReferenceWithOptions: function createInternalReferenceWithOptions(opts) {
      var ref = this.refFromOptions(opts);
      return this.createInternalReference(ref);
    }
  });

  _exports.default = _default;
});