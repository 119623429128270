define("ember-cli-zuglet/-private/transaction/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    store: null,
    fn: null,
    instance: null,
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:transaction').create({
        _internal: this
      });
    },
    runTransaction: function runTransaction() {
      var _this = this;

      return this.store.app.firestore().runTransaction(function (instance) {
        if (_this.isDestroying) {
          return Ember.RSVP.resolve();
        }

        _this.instance = instance;
        var fn = _this.fn;

        var model = _this.model(true);

        return Ember.RSVP.resolve(fn(model));
      });
    },
    run: function run() {
      return this.get('store.queue').schedule({
        name: 'transaction',
        promise: this.runTransaction()
      }).then(function () {
        return undefined;
      });
    },
    //
    load: function load(internal, opts) {
      return internal.loadInTransaction(this, opts);
    },
    save: function save(internal, opts) {
      internal.saveInTransaction(this, opts);
    },
    delete: function _delete(internal) {
      internal.deleteInTransaction(this);
    }
  });

  _exports.default = _default;
});