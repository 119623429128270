define("ember-cli-zuglet/-private/storage/reference/url/internal", ["exports", "ember-cli-zuglet/-private/storage/reference/base/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    ref: null,
    value: undefined,
    createModel: function createModel() {
      return this.factoryFor('zuglet:storage/reference/url').create({
        _internal: this
      });
    },
    load: function load() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.ref.load(Ember.assign({
        url: true
      }, opts));
    },
    didLoad: function didLoad(value) {
      this.onLoad({
        value: value
      });
    },
    _load: function _load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!this.shouldLoad()) {
        return Ember.RSVP.resolve();
      }

      return this.get('queue').schedule({
        name: 'storage/reference/url/load',
        reuse: function reuse(operations) {
          return operations.findBy('name', 'storage/reference/url/load');
        },
        invoke: function invoke() {
          _this.willLoad();

          return _this.get('ref.ref').getDownloadURL();
        },
        didResolve: function didResolve(url) {
          return _this.didLoad(url);
        },
        didReject: function didReject(err) {
          return _this.loadDidFail(err, opts);
        }
      });
    },
    onDeleted: function onDeleted() {
      this._super({
        value: undefined
      });
    }
  });

  _exports.default = _default;
});