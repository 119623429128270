define("ember-cli-sketch/-private/computed/node", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNode = _exports.default = void 0;

  var _this = void 0;

  var defaults = {
    stage: 'stage',
    parent: 'parent',
    nodes: 'nodes',
    type: 'type',
    visible: 'visible',
    selectable: 'selectable'
  };

  var normalize = function normalize() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var properties = Ember.assign({}, defaults, opts.properties);
    return Ember.assign({
      properties: properties
    }, opts);
  };

  var _default = function _default(opts) {
    return (0, _computed.factory)(function (factory, model) {
      return factory.node(model, normalize(opts));
    });
  };

  _exports.default = _default;

  var getNode = function getNode(model) {
    var node = model.node;
    (false && !(!!node) && Ember.assert("node is required for ".concat(_this), !!node));
    return node;
  };

  _exports.getNode = getNode;
});