define("ember-cli-sketch/-private/computed/guidelines", ["exports", "ember-cli-sketch/-private/util/sketches"], function (_exports, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default() {
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'basic';
    var opts = arguments.length > 1 ? arguments[1] : undefined;
    return Ember.computed(function () {
      var factory = (0, _sketches.default)(this).factory;
      return factory.nodeGuidelines(this.node, type, opts);
    }).readOnly();
  };

  _exports.default = _default;
});