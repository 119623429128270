define("design/components/ui-input/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4hWFcYoN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/icon\",[\"check\"],[[\"class\"],[\"checkmark\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});