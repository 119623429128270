define("design/util/runloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.next = void 0;

  var next = function next() {
    return new Promise(function (resolve) {
      return Ember.run.next(resolve);
    });
  };

  _exports.next = next;
});