define("ember-cli-sketch/components/ui-block/sketch/stage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i7P5BykS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/stage/content\",null,[[\"stage\"],[[24,[\"stage\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/hovers\",null,[[\"stage\"],[[24,[\"stage\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/selections\",null,[[\"stage\"],[[24,[\"stage\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/stage/guidelines\",null,[[\"stage\"],[[24,[\"stage\"]]]]],false],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[24,[\"isReady\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui-block-sketch-stage-ready\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/template.hbs"
    }
  });

  _exports.default = _default;
});