define("ember-cli-sketch/-private/computed/attr", ["exports", "ember-cli-sketch/-private/computed/node", "ember-cli-sketch/-private/computed/prop"], function (_exports, _node, _prop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.__sketch_attribute__ = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var __sketch_attribute__ = '__sketch_attribute__';
  _exports.__sketch_attribute__ = __sketch_attribute__;

  var attributes = function attributes(model) {
    return (0, _node.getNode)(model).attributes;
  };

  var _default = function _default(target) {
    var _Ember, _Ember$computed$apply;

    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    opts = Ember.assign({
      target: target,
      type: 'noop'
    }, opts);
    var deps = (0, _prop.hashToDeps)(opts);
    return (_Ember = Ember).computed.apply(_Ember, [opts.target].concat(_toConsumableArray(deps), [{
      get: function get(key) {
        return attributes(this).getValue(key);
      },
      set: function set(key, value) {
        return attributes(this).setValue(key, value);
      }
    }])).meta((_Ember$computed$apply = {}, _defineProperty(_Ember$computed$apply, __sketch_attribute__, true), _defineProperty(_Ember$computed$apply, "opts", opts), _Ember$computed$apply));
  };

  _exports.default = _default;
});