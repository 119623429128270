define("ember-cli-sketch/components/ui-block/sketch/stage/node/rect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "glKqqZsO",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-sketch/components/ui-block/sketch/stage/node/rect/template.hbs"
    }
  });

  _exports.default = _default;
});