define("ember-cli-sketch/components/ui-block/sketch/stage/guidelines/guideline/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/-computed", "ember-cli-sketch/-private/util/math"], function (_exports, _computed, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-stage-guidelines-guideline', 'guideline.approx:approx'],
    attributeBindings: ['style'],
    style: (0, _computed.style)('guideline.{direction,x,y,length}', function () {
      var _this$guideline = this.guideline,
          direction = _this$guideline.direction,
          x = _this$guideline.x,
          y = _this$guideline.y,
          length = _this$guideline.length;
      var width;
      var height;

      if (direction === 'vertical') {
        width = 1;
        height = length;
      } else if (direction === 'horizontal') {
        width = length;
        height = 1;
      } else {
        return;
      }

      x = (0, _math.round)(x, 0);
      y = (0, _math.round)(y, 0);
      return {
        transform: "translate(".concat(x, "px, ").concat(y, "px)"),
        width: "".concat(width, "px"),
        height: "".concat(height, "px")
      };
    }).readOnly()
  });

  _exports.default = _default;
});