define("ember-cli-zuglet/-private/observers/observer/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    observers: null,
    isCancelled: false,
    state: null,
    promise: Ember.computed('state.promise', function () {
      var _this = this;

      var promise = this.get('state.promise');
      return promise.then(function () {
        return _this.model(true);
      });
    }).readOnly(),
    cancel: function cancel() {
      var cancel = this.get('state.cancel');
      cancel(this);
    },
    load: function load() {
      return this.get('promise');
    },
    willDestroy: function willDestroy() {
      this.cancel();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});