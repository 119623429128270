define("ember-cli-sketch/-private/util/sketches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(owner) {
    return owner.sketches || Ember.getOwner(owner).lookup('sketch:sketches');
  };

  _exports.default = _default;
});