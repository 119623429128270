define("ember-cli-zuglet/-private/internal/model-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _internal: null,
    willDestroy: function willDestroy() {
      this._internal.modelWillDestroy();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});