define("ember-cli-zuglet/-private/data/array/array", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/data/internal/model-mixin", "ember-cli-zuglet/-private/util/normalize-array"], function (_exports, _modelMixin, _modelMixin2, _normalizeArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.MutableArray, _modelMixin.default, _modelMixin2.default, {
    length: Ember.computed.readOnly('_internal.content.length'),
    objectAt: function objectAt(idx) {
      return this._internal.getModelValue(idx);
    },
    replace: function replace(idx, amt, objects) {
      this._internal.replaceModelValues(idx, amt, (0, _normalizeArray.default)(objects));

      return this;
    }
  });

  _exports.default = _default;
});