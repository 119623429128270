define("ember-cli-zuglet/-private/lifecycle/models/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/lifecycle/models/runtime"], function (_exports, _internal, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    parent: null,
    key: null,
    opts: null,
    runtime: function runtime(create) {
      var runtime = this._runtime;

      if (!runtime && create) {
        var _this$getProperties = this.getProperties('parent', 'key', 'opts'),
            parent = _this$getProperties.parent,
            key = _this$getProperties.key,
            opts = _this$getProperties.opts;

        runtime = new _runtime.default(parent, key, opts);
        this._runtime = runtime;
      }

      return runtime;
    },
    createModel: function createModel() {
      var content = this.runtime(true).content;
      return Ember.getOwner(this.parent).factoryFor('zuglet:lifecycle/models').create({
        _internal: this,
        content: content
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._runtime && this._runtime.destroy();
    }
  });

  _exports.default = _default;
});