define("design/components/ui-block/tabs/tab/component", ["exports", "design/components/ui-block/tabs/tab/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':tab', 'isSelected:selected'],
    isSelected: Ember.computed('id', 'selected', function () {
      var id = this.id,
          selected = this.selected;
      return id === selected;
    }).readOnly(),
    click: function click() {
      if (this.isSelected) {
        return;
      }

      this.select(this.id);
    }
  });

  _exports.default = _default;
});