define("design/components/ui-input/checkbox/component", ["exports", "design/components/ui-input/checkbox/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-input-checkbox', '_color', '_size', '_type', 'disabled:disabled:enabled', 'checked:checked'],
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    checked: false,
    disabled: false,
    click: function click() {
      var disabled = this.disabled,
          checked = this.checked;

      if (disabled) {
        return;
      }

      this.update && this.update(!checked);
    }
  });

  _exports.default = _default;
});