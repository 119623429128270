define("ember-cli-zuglet/-private/internal/internal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    _model: null,
    didCreateModel: function didCreateModel() {},
    model: function model(create) {
      var model = this._model;

      if (!model && create) {
        model = this.createModel();
        this.didCreateModel(model);
        this._model = model;
      }

      return model;
    },
    modelWillDestroy: function modelWillDestroy() {
      this.destroy();
    },
    willDestroy: function willDestroy() {
      this._model && this._model.destroy();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});