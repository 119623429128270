define("ember-cli-sketch/-private/node/attributes/attribute/string", ["exports", "ember-cli-sketch/-private/node/attributes/attribute/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    transformValue: function transformValue(value) {
      if (value === undefined) {
        value = this.opts.initial || '';
      }

      if (typeof value !== 'string') {
        value = String(value);
      }

      return value;
    }
  });

  _exports.default = _default;
});