define("ember-cli-sketch/-private/node/frame/-base", ["exports", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/util/math"], function (_exports, _computed, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.model = _exports.frame = _exports.default = void 0;

  var frame = function frame(type) {
    return (0, _computed.factory)(function (factory, node) {
      return factory.frame(type, node);
    });
  };

  _exports.frame = frame;

  var model = function model(key) {
    return Ember.computed.readOnly("model.".concat(key));
  };

  _exports.model = model;
  var keys = Object.keys;

  var _default = Ember.Object.extend({
    parent: null,
    model: Ember.computed.readOnly('parent.model'),
    zoom: Ember.computed.readOnly('parent.stage.zoom'),
    deltaToFrame: function deltaToFrame(props) {
      var _this = this;

      var values = {};
      keys(props).forEach(function (key) {
        values[key] = _this[key] + props[key];
      });
      return values;
    },
    frameToDelta: function frameToDelta(props) {
      var _this2 = this;

      var values = {};
      keys(props).forEach(function (key) {
        values[key] = props[key] - _this2[key];
      });
      return values;
    },
    convertPoint: function convertPoint(point, frameKey) {
      var frame = this[frameKey];
      (false && !(!!frame) && Ember.assert("frame ".concat(frameKey, " not declared for ").concat(this), !!frame));
      return {
        x: point.x - frame.x,
        y: point.y - frame.y
      };
    },
    convertPointFromAbsolute: function convertPointFromAbsolute(point) {
      return this.convertPoint(point, 'absolute');
    },
    convertFrameFromAbsolute: function convertFrameFromAbsolute(frame) {
      return Ember.assign({}, frame, this.convertPointFromAbsolute(frame));
    },
    center: function center(props) {
      var _this3 = this;

      props = Ember.assign({}, props);

      var calc = function calc(dim, size) {
        if (props[size] && !props[dim]) {
          props[dim] = _this3[dim] + (_this3[size] - props[size]) / 2;
        }
      };

      calc('y', 'height');
      calc('x', 'width');
      return props;
    },
    _frame: function _frame(frameKey) {
      var frame = this[frameKey];
      (false && !(!!frame) && Ember.assert("frame ".concat(frameKey, " not declared for ").concat(this), !!frame));
      return frame;
    },
    rotatedPosition: function rotatedPosition(_ref, frameKey) {
      var x = _ref.x,
          y = _ref.y;

      var frame = this._frame(frameKey);

      var rotation = -frame.rotation;
      return (0, _math.rotatePosition)({
        x: x,
        y: y
      }, frame, rotation);
    },
    // point is inside this frame
    containsPosition: function containsPosition(_ref2, frameKey) {
      var x = _ref2.x,
          y = _ref2.y;

      var frame = this._frame(frameKey);

      return frame.x <= x && frame.y <= y && frame.x + frame.width >= x && frame.y + frame.height >= y;
    },
    // frame is completely out of this frame
    excludesFrame: function excludesFrame(_ref3, frameKey) {
      var x = _ref3.x,
          y = _ref3.y,
          width = _ref3.width,
          height = _ref3.height;

      var frame = this._frame(frameKey);

      return x + width < frame.x || x > frame.x + frame.width || y + height < frame.y || y > frame.y + frame.height;
    },
    // frame is fully inside this frame
    containsFrame: function containsFrame(_ref4, frameKey) {
      var x = _ref4.x,
          y = _ref4.y,
          width = _ref4.width,
          height = _ref4.height;

      var frame = this._frame(frameKey);

      return x >= frame.x && y >= frame.y && x + width <= frame.x + frame.width && y + height <= frame.y + frame.height;
    },
    // frame is at least partially inside this frame
    overlapsFrame: function overlapsFrame(_ref5, frameKey) {
      var x = _ref5.x,
          y = _ref5.y,
          width = _ref5.width,
          height = _ref5.height;

      var frame = this._frame(frameKey);

      return x < frame.x + frame.width && x + width > frame.x && y < frame.y + frame.height && y + height > frame.y;
    }
  });

  _exports.default = _default;
});