define("design/components/ui-input/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vZYV0pYi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"icon\",\"label\"],[[28,\"component\",[\"ui-block/icon\"],[[\"class\"],[\"icon\"]]],[28,\"component\",[\"ui-div\"],[[\"class\"],[\"label\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/button/template.hbs"
    }
  });

  _exports.default = _default;
});