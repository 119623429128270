define("design/components/ui-block/flow/steps/step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "If3sUd+h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"number\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[22,\"number\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"number\"],[8],[1,[28,\"ui-block/icon\",[\"lock\"],[[\"class\"],[\"disabled\"]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[[24,[\"title\"]]],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"step\",\"badge\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"badge\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"badge\",\"nt\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"badge\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[[24,[\"badge\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/flow/steps/step/template.hbs"
    }
  });

  _exports.default = _default;
});