define("ember-cli-sketch/-private/stage/tools/stage/zoom", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    delta: null,
    cursor: Ember.computed('delta', function () {
      return this.delta < 0 ? 'zoom-out' : 'zoom-in';
    }).readOnly(),
    update: function update(_ref) {
      var delta = _ref.delta;
      this.stage.perform('zoom', {
        delta: delta
      });
    },
    begin: function begin() {
      this.down = true;
    },
    reset: function reset() {
      this.setProperties({
        down: false,
        delta: null
      });
    },
    onMouseDown: function onMouseDown() {
      this.begin();
    },
    onMouseUp: function onMouseUp() {
      this.reset();
    },
    onMouseMove: function onMouseMove(_ref2) {
      var delta = _ref2.delta;

      if (!this.down) {
        return;
      }

      this.update({
        delta: delta.y / 200
      });
    },
    onMouseWheel: function onMouseWheel(_ref3) {
      var value = _ref3.value;
      this.begin();
      this.update({
        delta: value / 10
      });
      this.reset();
    },
    deactivate: function deactivate() {
      this.reset();
    }
  });

  _exports.default = _default;
});