define("ember-cli-sketch/components/ui-block/sketch/stage/deferred/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    promise: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var promise = this.promise;

      if (!promise) {
        return;
      }

      var sketch = (0, _component.getSketchComponent)(this);

      if (!sketch) {
        return;
      }

      sketch.registerRenderPromise(promise);
    }
  });

  _exports.default = _default;
});