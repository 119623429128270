define("ember-cli-zuglet/-private/util/destroy-cached", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(owner, key) {
    var value = owner.cacheFor(key);

    if (!value) {
      return;
    }

    value.destroy();
  };

  _exports.default = _default;
});