define("ember-cli-sketch/util/object", ["exports", "ember-cli-sketch/-private/util/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "compact", {
    enumerable: true,
    get: function get() {
      return _object.compact;
    }
  });
  Object.defineProperty(_exports, "millimetersToPixels", {
    enumerable: true,
    get: function get() {
      return _object.millimetersToPixels;
    }
  });
  Object.defineProperty(_exports, "reduce", {
    enumerable: true,
    get: function get() {
      return _object.reduce;
    }
  });
});