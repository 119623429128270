define("ember-cli-zuglet/-private/auth/user/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    auth: null,
    user: null,
    createModel: function createModel() {
      return this.auth.factoryFor('zuglet:auth/user').create({
        _internal: this
      });
    },
    delete: function _delete() {
      var user = this.get('user');
      return this.auth.withAuthReturningUser(function () {
        return user.delete().then(function () {
          return null;
        });
      });
    },
    token: function token() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _Ember$assign = Ember.assign({
        type: 'string',
        refresh: false
      }, opts),
          type = _Ember$assign.type,
          refresh = _Ember$assign.refresh;

      if (type === 'string') {
        return Ember.RSVP.resolve(this.user.getIdToken(refresh));
      } else if (type === 'json') {
        return Ember.RSVP.resolve(this.user.getIdTokenResult(refresh));
      }
    },
    link: function link(method) {
      var _method,
          _this = this;

      method = this.get("auth.methods").method(method);

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var credential = (_method = method).credential.apply(_method, args);

      return this.get('auth').withAuthReturningUser(function () {
        return _this.user.linkWithCredential(credential).then(function (_ref) {
          var user = _ref.user;
          return user;
        });
      });
    }
  });

  _exports.default = _default;
});