define("ember-cli-zuglet/-private/reference/queryable/mixin", ["exports", "ember-cli-zuglet/-private/internal/invoke", "ember-cli-zuglet/-private/reference/queryable/internal-mixin"], function (_exports, _invoke, _internalMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_internalMixin.keys.reduce(function (hash, key) {
    hash[key] = (0, _invoke.invokeReturningModel)(key);
    return hash;
  }, {}), {
    query: (0, _invoke.invokeReturningModel)('query'),
    load: function load(opts) {
      return this._internal.load(opts);
    },
    first: function first(opts) {
      return this._internal.first(opts);
    }
  });

  _exports.default = _default;
});