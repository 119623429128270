define("design/helpers/tt", ["exports", "ember-intl/helpers/t"], function (_exports, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _t.default.extend({
    format: function format(key, args) {
      var _args = args,
          options = _args.options;

      if (options) {
        delete options.htmlSafe;
      }

      args = Ember.assign({}, options, args);
      return this.intl.t(key, args);
    }
  });

  _exports.default = _default;
});