define("ember-cli-sketch/-private/stage/interactions/mediums/keyboard", ["exports", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/util/sketches"], function (_exports, _computed, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var properties = ['state', 'keys', 'isShift', 'isSpace', 'isMeta'];

  var is = function is(key) {
    return Ember.computed("keys.@each.".concat(key), function () {
      return !!this.keys.find(function (object) {
        return object[key];
      });
    }).readOnly();
  };

  var state = function state() {
    return Ember.computed('keys.[]', function () {
      return this.keys.length ? 'down' : 'up';
    }).readOnly();
  };

  var _default = Ember.Object.extend({
    keys: (0, _computed.array)(),
    state: state(),
    isShift: is('isShift'),
    isSpace: is('isSpace'),
    isMeta: is('isMeta'),
    // is stuck on command+tab
    isAlt: is('isAlt'),
    serialized: (0, _computed.serialized)(properties, function (hash) {
      hash.keys = hash.keys.map(function (key) {
        return key.serialized;
      });
      return hash;
    }),
    isKeyCode: function isKeyCode(keyCode) {
      return !!this.keys.find(function (object) {
        return object.keyCode === keyCode;
      });
    },
    existingKey: function existingKey(_ref) {
      var key = _ref.key,
          keyCode = _ref.keyCode;
      return this.keys.find(function (object) {
        return object.key === key && object.keyCode === keyCode;
      });
    },
    createKey: function createKey(opts) {
      return (0, _sketches.default)(this).factory.interationsKeyboardKey(opts);
    },
    addKey: function addKey(opts) {
      var key = this.existingKey(opts);

      if (!key) {
        key = this.createKey(opts);
        this.keys.pushObject(key);
      }

      return key;
    },
    removeKey: function removeKey(opts) {
      var object = this.existingKey(opts);

      if (!object) {
        return;
      }

      this.keys.removeObject(object);
      return object;
    },
    onKeyDown: function onKeyDown(opts) {
      return this.addKey(opts);
    },
    onKeyUp: function onKeyUp(opts) {
      return this.removeKey(opts);
    },
    reset: function reset() {
      this.keys.clear();
    }
  });

  _exports.default = _default;
});