define("ember-cli-sketch/components/ui-block/sketch/stage/-ready", ["exports", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/util/safe", "ember-cli-sketch/-private/util/runloop"], function (_exports, _computed, _safe, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _promises: (0, _computed.array)(),
    _isReady: false,
    isReady: Ember.computed.readOnly('_isReady'),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._cancelScheduleIsReady();
    },
    scheduleReady: (0, _safe.default)(function () {
      this.registerRenderPromise((0, _runloop.next)());
    }),
    _cancelScheduleIsReady: function _cancelScheduleIsReady() {
      Ember.run.cancel(this.__updateIsReady);
      Ember.run.cancel(this.__updateIsReadyActions);
    },
    _updateIsReady: (0, _safe.default)(function () {
      var resolved = this._promises.length === 0;
      var stage = this.stage;

      var _isReady = resolved && !!stage;

      if (this._isReady === _isReady) {
        return;
      }

      this.setProperties({
        _isReady: _isReady
      });
    }),
    _scheduleUpdateIsReady: function _scheduleUpdateIsReady() {
      var _this = this;

      this._cancelScheduleIsReady();

      this.__updateIsReady = Ember.run.schedule('afterRender', function () {
        Ember.run.cancel(_this.__updateIsReadyActions);
        _this.__updateIsReadyActions = Ember.run.later(function () {
          return _this._updateIsReady();
        }, 10);
      });
    },
    registerRenderPromise: (0, _safe.default)(function (promise) {
      var _this2 = this;

      promise = Ember.RSVP.resolve(promise).then(function () {
        return (0, _runloop.next)();
      });

      this._cancelScheduleIsReady();

      this._promises.pushObject(promise);

      this._updateIsReady();

      Ember.RSVP.resolve(promise).then(function () {}, function (err) {
        if (err && err.code === 'cancelled') {
          return;
        }

        console.error('Render promise rejection', err); // eslint-disable-line no-console
      }).finally(function () {
        if (_this2.isDestroying) {
          return;
        }

        _this2._promises.removeObject(promise);

        _this2._scheduleUpdateIsReady();
      });
    })
  });

  _exports.default = _default;
});