define("ember-cli-sketch/-private/node/frame/stage", ["exports", "ember-cli-sketch/-private/node/frame/-base", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/node/frame/-computed", "ember-cli-sketch/-private/util/math"], function (_exports, _base, _computed, _computed2, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = ['x', 'y'];

  var _default = _base.default.extend({
    x: (0, _base.model)('x'),
    y: (0, _base.model)('y'),
    properties: (0, _computed.serialized)(keys),
    absolute: Ember.computed.readOnly('properties'),
    _zoomed: (0, _computed2.zoomed)('properties'),
    rounded: (0, _computed2.rounded)('_zoomed'),
    hover: Ember.computed.readOnly('rounded'),
    guidelines: Ember.computed.readOnly('rounded'),
    selection: Ember.computed.readOnly('rounded'),
    convertPointFromScreen: function convertPointFromScreen(point) {
      var zoom = this.zoom;

      var value = function value(key) {
        return (0, _math.round)(point[key] / zoom, 0);
      };

      return {
        x: value('x'),
        y: value('y')
      };
    },
    pointForMouseEvent: function pointForMouseEvent(e) {
      var _this = this;

      var screen = this.parent.renderer.screenPointFromMouseEvent(e);

      if (!screen) {
        return;
      }

      var point = this.convertPointFromScreen(screen);

      var calc = function calc(prop) {
        return point[prop] - _this[prop];
      };

      return {
        x: calc('x'),
        y: calc('y')
      };
    }
  });

  _exports.default = _default;
});