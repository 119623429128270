define("design/components/ui-block/flow/steps/step/component", ["exports", "design/components/ui-block/flow/steps/step/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var step = function step(key) {
    return Ember.computed.readOnly("step.".concat(key));
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':step', 'current:current', 'enabled:enabled:disabled'],
    step: null,
    current: step('current'),
    enabled: step('enabled'),
    number: step('number'),
    title: step('title'),
    badge: step('badge'),
    click: function click() {
      this.step.transitionTo();
    }
  });

  _exports.default = _default;
});