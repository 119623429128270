define("ember-cli-zuglet/-private/reference/document/internal", ["exports", "ember-cli-zuglet/-private/reference/internal", "ember-cli-zuglet/-private/util/errors"], function (_exports, _internal, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDocumentInternalReference = _exports.default = _exports.assertDocumentInternalReference = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var isDocumentInternalReference = function isDocumentInternalReference(arg) {
    return _internal.default.detectInstance(arg);
  };

  _exports.isDocumentInternalReference = isDocumentInternalReference;

  var assertDocumentInternalReference = function assertDocumentInternalReference(name, arg) {
    (false && !(isDocumentInternalReference(arg)) && Ember.assert("".concat(name, " must be document internal reference not ").concat(arg), isDocumentInternalReference(arg)));
  };

  _exports.assertDocumentInternalReference = assertDocumentInternalReference;

  var _default = _internal.default.extend({
    id: Ember.computed.readOnly('ref.id'),
    path: Ember.computed.readOnly('ref.path'),
    type: 'document',
    createParentInternal: function createParentInternal(parent) {
      return this.store.createInternalCollectionReferenceForReference(parent);
    },
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:reference/document').create({
        _internal: this
      });
    },
    collection: function collection(path) {
      var ref = this.ref.collection(path);
      return this.store.createInternalCollectionReferenceForReference(ref, this);
    },
    doc: function doc(path) {
      (false && !(Ember.typeOf(path) === 'string') && Ember.assert("path must be string", Ember.typeOf(path) === 'string'));
      (false && !(!!path) && Ember.assert("path is required", !!path));

      var _path$split = path.split('/'),
          _path$split2 = _toArray(_path$split),
          first = _path$split2[0],
          remaining = _path$split2.slice(1);

      (false && !(remaining.find(function (string) {
        return string === '';
      }) !== '') && Ember.assert("nested document path cannot contain empty path components", remaining.find(function (string) {
        return string === '';
      }) !== ''));
      return this.collection(first).doc(remaining.join('/'));
    },
    didLoad: function didLoad(snapshot, opts) {
      if (!snapshot.exists && !opts.optional) {
        return Ember.RSVP.reject((0, _errors.documentMissingError)(opts));
      }

      return this.store.createInternalDocumentForSnapshot(snapshot);
    },
    load: function load() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var source = opts.source;
      return this.get('store.queue').schedule({
        name: 'reference/document/load',
        invoke: function invoke() {
          return _this.ref.get({
            source: source
          });
        },
        didResolve: function didResolve(snapshot) {
          return _this.didLoad(snapshot, opts);
        },
        didReject: function didReject(err) {
          return Ember.RSVP.reject(err);
        }
      });
    },
    delete: function _delete() {
      var _this2 = this;

      return this.get('store.queue').schedule({
        name: 'reference/document/delete',
        invoke: function invoke() {
          return _this2.ref.delete();
        },
        didResolve: function didResolve() {
          return _this2;
        },
        didReject: function didReject(err) {
          return Ember.RSVP.reject(err);
        }
      });
    },
    loadInTransaction: function loadInTransaction(transaction) {
      var _this3 = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.get('store.queue').schedule({
        name: 'reference/document/load/transaction',
        invoke: function invoke() {
          var ref = _this3.ref;
          return transaction.instance.get(ref);
        },
        didResolve: function didResolve(snapshot) {
          return _this3.didLoad(snapshot, opts);
        },
        didReject: function didReject(err) {
          return Ember.RSVP.reject(err);
        }
      });
    },
    deleteInContext: function deleteInContext(instance) {
      instance.delete(this.ref);
    },
    deleteInTransaction: function deleteInTransaction(transaction) {
      this.deleteInContext(transaction.instance);
    },
    deleteInBatch: function deleteInBatch(batch) {
      this.deleteInContext(batch.instance);
    },
    new: function _new(props) {
      return this.store.createNewInternalDocumentWithRef(this, props);
    },
    existing: function existing() {
      return this.store.createExistingInternalDocumentWithRef(this);
    },
    observe: function observe() {
      return this.existing().observe();
    }
  });

  _exports.default = _default;
});