define("ember-cli-sketch/-private/sketches/fonts/loader", ["exports", "ember-cli-sketch/-private/util/load-fonts", "ember-cli-sketch/-private/util/safe"], function (_exports, _loadFonts, _safe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    fonts: null,
    opts: null,
    isLoading: true,
    isLoaded: false,
    isError: false,
    error: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._previous = this.fonts._loaders.lastObject;
    },
    includes: function includes(mod, family) {
      mod = this.opts[mod];

      if (mod) {
        return mod.families.includes(family);
      }
    },
    onLoad: (0, _safe.default)(function () {
      this.setProperties({
        isLoading: true,
        isError: false,
        error: null
      });
    }),
    onLoaded: (0, _safe.default)(function () {
      this.setProperties({
        isLoading: false,
        isLoaded: true
      });
    }),
    onError: (0, _safe.default)(function (err) {
      this.setProperties({
        isLoading: false,
        isError: true,
        error: err
      });
    }),
    // https://github.com/typekit/webfontloader/issues/345
    __loadPrevious: function __loadPrevious() {
      var previous = this._previous;

      if (!previous) {
        return Ember.RSVP.resolve();
      }

      return previous.promise.then(function () {}, function () {});
    },
    __load: function __load() {
      var _this = this;

      return this.__loadPrevious().then(function () {
        var opts = _this.opts;
        return (0, _loadFonts.default)(opts);
      });
    },
    _load: function _load() {
      var _this2 = this;

      this.onLoad();
      return this.__load().then(function () {
        _this2.onLoaded();

        return _this2;
      }, function (err) {
        _this2.onError(err);

        return Ember.RSVP.reject(err);
      });
    },
    promise: Ember.computed(function () {
      return this._load();
    }).readOnly()
  });

  _exports.default = _default;
});