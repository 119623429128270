define("ember-cli-zuglet/-private/auth/methods/methods", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/auth/methods/internal"], function (_exports, _modelMixin, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TypesMixin = Ember.Mixin.create(_internal.types.reduce(function (hash, key) {
    hash[key] = Ember.computed(function () {
      return this._internal.method(key).model(true);
    }).readOnly();
    return hash;
  }, {}));

  var _default = Ember.Object.extend(_modelMixin.default, TypesMixin, {
    available: Ember.computed.readOnly('_internal.types')
  });

  _exports.default = _default;
});