define("ember-cli-zuglet/-private/data/object/serializer", ["exports", "ember-cli-zuglet/-private/data/internal/serializer", "ember-cli-zuglet/-private/data/internal/util"], function (_exports, _serializer, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isObject = function isObject(value) {
    return Ember.typeOf(value) === 'object';
  };

  var _default = _serializer.default.extend({
    supports: function supports(value) {
      return isObject(value);
    },
    matches: function matches(internal, value) {
      return isObject(value);
    },
    createInternal: function createInternal(props) {
      var internal = this.factoryFor('zuglet:data/object/internal').create({
        serializer: this
      });
      this.deserialize(internal, props);
      return internal;
    },
    getModelValue: function getModelValue(internal, key) {
      var value = internal.content[key];
      return (0, _util.toModel)(value);
    },
    setModelValue: function setModelValue(internal, key, value) {
      var _this = this;

      return internal.withPropertyChanges(true, function (changed) {
        var content = internal.content;
        var current = content[key];
        value = _this.manager.createInternal(value);

        if (current) {
          current.detach();
        }

        if (value) {
          value.attach(internal);
          content[key] = value;
        } else {
          delete content[key];
        }

        changed(key);
        return (0, _util.toModel)(value);
      });
    },
    serialize: function serialize(internal, type) {
      return (0, _util.map)(internal.content, function (key, value) {
        return value.serialize(type);
      });
    },
    deserialize: function deserialize(internal) {
      var values = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var content = internal.content;
      var remove = Ember.A(Object.keys(content));
      var manager = this.manager;
      internal.withPropertyChanges(true, function (changed) {
        (0, _util.map)(values, function (key, value) {
          remove.removeObject(key);
          var current = content[key];

          if (current && current.serializer.matches(current, value)) {
            var updated = current.serializer.deserialize(current, value);

            if (updated.replace) {
              updated.internal.attach(internal);
              content[key] = updated.internal;
              changed(key);
            }
          } else {
            var created = manager.createInternal(value);

            if (created) {
              created.attach(internal);
              content[key] = created;
            } else {
              delete content[key];
            }

            changed(key);
          }
        });
        remove.forEach(function (key) {
          content[key].detach();
          delete content[key];
          changed(key);
        });
      });
      return {
        replace: false,
        internal: internal
      };
    },
    isDirty: function isDirty(internal, raw) {
      var content = internal.content;
      raw = raw || {};
      var rawKeys = Object.keys(raw);
      var contentKeys = Object.keys(content);

      if (rawKeys.length !== contentKeys.length) {
        return true;
      }

      if (rawKeys.length === 0) {
        return false;
      }

      for (var _i = 0, _contentKeys = contentKeys; _i < _contentKeys.length; _i++) {
        var key = _contentKeys[_i];
        var _internal = content[key];
        var value = raw[key];

        if (_internal.serializer.isDirty(_internal, value)) {
          return true;
        }
      }

      return false;
    }
  });

  _exports.default = _default;
});