define("ember-cli-sketch/-private/node/attributes", ["exports", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/computed/attr"], function (_exports, _computed, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isAttribute = function isAttribute(meta) {
    return meta[_attr.__sketch_attribute__] === true;
  };

  var definitions = function definitions() {
    return Ember.computed(function () {
      var definitions = [];
      this.model.constructor.eachComputedProperty(function (key, meta) {
        if (!isAttribute(meta)) {
          return;
        }

        definitions.push(Ember.assign({
          key: key
        }, meta.opts));
      });
      return definitions;
    }).readOnly();
  };

  var attributes = function attributes() {
    return (0, _computed.factory)(function (factory, attributes) {
      return attributes.definitions.reduce(function (hash, definition) {
        var key = definition.key,
            type = definition.type;
        var attribute = factory.attribute(attributes, type, definition);
        hash[key] = attribute;
        return hash;
      }, {});
    });
  };

  var attribute = function attribute(fn) {
    return function (name) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return fn.apply(void 0, [this.attribute(name, true)].concat(args));
    };
  };

  var _default = function _default(_ref) {
    var modelClass = _ref.modelClass;
    var attrs = {};
    modelClass.eachComputedProperty(function (key, meta) {
      if (!isAttribute(meta)) {
        return;
      }

      attrs[key] = Ember.computed(function () {
        return this.attribute(key, true);
      }).readOnly();
    });
    return Ember.Object.extend(Ember.assign(attrs, {
      node: null,
      model: Ember.computed.readOnly('node.model'),
      definitions: definitions(),
      attributes: attributes(),
      attribute: function attribute(key) {
        var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var requiredType = arguments.length > 2 ? arguments[2] : undefined;
        var attribute = this.attributes[key];
        (false && !(attribute || !required) && Ember.assert("attribute '".concat(key, "' not defined for ").concat(this.model), attribute || !required));
        (false && !(!requiredType || attribute.type === requiredType) && Ember.assert("attribute ".concat(key, " must be ").concat(requiredType, " not ").concat(attribute.type), !requiredType || attribute.type === requiredType));
        return attribute;
      },
      getValue: function getValue(key) {
        return this.attribute(key).getValue();
      },
      setValue: function setValue(key, value) {
        return this.attribute(key).setValue(value);
      },
      changes: function changes(props) {
        var model = this.model;
        var result = {};

        for (var key in props) {
          var value = props[key];

          var _attribute = this.attribute(key, false);

          if (_attribute) {
            var _attribute$isChanged = _attribute.isChanged(value),
                changed = _attribute$isChanged.changed,
                transformed = _attribute$isChanged.transformed;

            if (changed) {
              result[key] = transformed;
            }
          } else {
            if (model[key] !== value) {
              result[key] = value;
            }
          }
        }

        return result;
      },
      clamp: attribute(function (attribute, value) {
        return attribute.clamp(value);
      }),
      clampDelta: attribute(function (attribute, delta) {
        return attribute.clampDelta(delta);
      })
    }));
  };

  _exports.default = _default;
});