define("design/components/ui-block/progress-bar/component", ["exports", "design/components/ui-block/progress-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-progress-bar'],
    layout: _template.default,
    normalizedValue: Ember.computed('value', function () {
      var value = this.value;

      if (typeof value !== 'number') {
        return;
      }

      var m = 6;
      return value / 100 * (100 - m) + m;
    }).readOnly(),
    valueStyle: Ember.computed('normalizedValue', function () {
      var normalizedValue = this.normalizedValue;
      return Ember.String.htmlSafe("width: ".concat(normalizedValue, "%"));
    }).readOnly()
  });

  _exports.default = _default;
});