define("design/components/ui-block/validation/validator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AG1zYSn4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"validator\",\"key\",\"value\",\"update\",\"isRequired\",\"isValid\",\"isBlank\",\"isTouched\",\"touch\",\"error\"],[[24,[\"validator\"]],[24,[\"key\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"update\"],null],[24,[\"isRequired\"]],[24,[\"isValid\"]],[24,[\"isBlank\"]],[24,[\"isTouched\"]],[28,\"action\",[[23,0,[]],\"touch\"],null],[24,[\"error\"]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/validation/validator/error\",null,[[\"error\"],[[24,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/validation/validator/template.hbs"
    }
  });

  _exports.default = _default;
});