define("ember-cli-sketch/-private/node/frame/-computed", ["exports", "ember-cli-sketch/-private/util/object", "ember-cli-sketch/-private/util/math"], function (_exports, _object, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoomed = _exports.rounded = _exports.rotated = _exports.absolute = void 0;
  var zoomableKeys = ['x', 'y', 'width', 'height'];

  var zoomed = function zoomed(frameKey) {
    var zoomKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'zoom';
    return Ember.computed(frameKey, zoomKey, function () {
      var zoom = this.get(zoomKey);
      var frame = this.get(frameKey);

      if (!frame) {
        return;
      }

      return (0, _object.reduce)(frame, function (key, value) {
        if (zoomableKeys.includes(key)) {
          value = value * zoom;
        }

        return value;
      });
    }).readOnly();
  };

  _exports.zoomed = zoomed;

  var rotated = function rotated(frameKey) {
    return Ember.computed(frameKey, function () {
      var frame = this.get(frameKey);
      return (0, _math.rotatedRectBounds)(frame);
    }).readOnly();
  };

  _exports.rotated = rotated;

  var absolute = function absolute(frameKey, parentFrameKey) {
    return Ember.computed(frameKey, parentFrameKey, function () {
      var parent = this.get(parentFrameKey);

      if (!parent) {
        return;
      }

      var frame = this.get(frameKey);

      if (!frame) {
        return;
      }

      var x = frame.x,
          y = frame.y,
          width = frame.width,
          height = frame.height,
          rotation = frame.rotation;

      var r = function r(value) {
        return (0, _math.round)(value, 0);
      };

      var result = {
        x: r(parent.x + x),
        y: r(parent.y + y),
        width: r(width),
        height: r(height),
        rotation: r(rotation)
      };
      return result;
    }).readOnly();
  };

  _exports.absolute = absolute;

  var rounded = function rounded(frameKey) {
    return Ember.computed(frameKey, function () {
      var frame = this.get(frameKey);

      if (!frame) {
        return;
      }

      return (0, _object.reduce)(frame, function (key, value) {
        return (0, _math.round)(value, 0);
      });
    }).readOnly();
  };

  _exports.rounded = rounded;
});