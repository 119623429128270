define("ember-cli-sketch/-private/actions/node/aspect-update", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    calculate: function calculate(node) {
      var _node$frame$propertie = node.frame.properties,
          width = _node$frame$propertie.width,
          height = _node$frame$propertie.height;

      if (!width || !height) {
        return;
      }

      return width / height;
    },
    perform: function perform(node) {
      var aspect = this.calculate(node);

      if (aspect) {
        node.update({
          aspect: aspect
        });
      }
    }
  });

  _exports.default = _default;
});