define("analytics/models/analytics/-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    isEnabled: false
  });

  _exports.default = _default;
});