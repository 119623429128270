define("ember-cli-sketch/-private/node/guidelines/basic", ["exports", "ember-cli-sketch/-private/node/guidelines"], function (_exports, _guidelines) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "points", {
    enumerable: true,
    get: function get() {
      return _guidelines.points;
    }
  });

  var _default = _guidelines.default.extend({
    points: (0, _guidelines.points)(function (_ref) {
      var x = _ref.x,
          y = _ref.y,
          width = _ref.width,
          height = _ref.height;
      return {
        vertical: [x, x + width / 2, x + width - 1],
        horizontal: [y, y + height / 2, y + height - 1]
      };
    })
  });

  _exports.default = _default;
});