define("ember-cli-zuglet/-private/lifecycle/route/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/lifecycle/util/model-factory"], function (_exports, _internal, _modelFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getInternal = _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var validate = function validate(opts, named) {
    (false && !(Ember.typeOf(opts) === 'object') && Ember.assert("opts must be object", Ember.typeOf(opts) === 'object'));

    if (!opts.named && !opts.inline) {
      opts.named = named;
    }
  };

  var __zuglet_route_internal = '__zuglet_route_internal';

  var getInternal = function getInternal(arg, route) {
    if (!arg) {
      return;
    }

    if (arg.__zuglet_route_internal !== true) {
      return;
    }

    var internal = arg._internal;

    if (!internal) {
      return;
    }

    if (internal.route !== route) {
      return;
    }

    return internal;
  };

  _exports.getInternal = getInternal;

  var keyFromRouteName = function keyFromRouteName(routeName) {
    return "route/".concat(routeName.replace(/\./g, '/'));
  };

  var _load = function load(model) {
    if (typeof model.load === 'function') {
      return model.load();
    }
  };

  var _default = _internal.default.extend({
    route: null,
    params: null,
    opts: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var _this$getProperties = this.getProperties('route', 'params', 'opts'),
          route = _this$getProperties.route,
          params = _this$getProperties.params,
          opts = _this$getProperties.opts;

      var key = keyFromRouteName(route.routeName);
      validate(opts, key);
      this.modelFactory = new _modelFactory.default({
        parent: route,
        key: key,
        inline: opts.inline,
        named: opts.named,
        mapping: opts.mapping,
        delegate: {
          props: function props() {
            var _ref;

            return _ref = {}, _defineProperty(_ref, __zuglet_route_internal, true), _defineProperty(_ref, "_internal", _this), _ref;
          },
          mapping: function mapping() {
            return [route, params];
          },
          named: function named() {
            return [route, params];
          }
        }
      });
    },
    createModel: function createModel() {
      var _this$modelFactory$cr = this.modelFactory.create(),
          model = _this$modelFactory$cr.model,
          promise = _this$modelFactory$cr.promise;

      var destroy = function destroy() {
        return model && model.destroy();
      };

      return {
        model: model,
        promise: promise,
        destroy: destroy
      };
    },
    load: function load() {
      var _this$model = this.model(true),
          model = _this$model.model,
          promise = _this$model.promise;

      (false && !(!!model) && Ember.assert("route model is required", !!model));
      return Ember.RSVP.resolve().then(function () {
        return promise;
      }).then(function () {
        return _load(model);
      }).then(function () {
        return model;
      });
    },
    cancelScheduleDestroy: function cancelScheduleDestroy() {
      var scheduleDestroy = this._scheduleDestroy;

      if (scheduleDestroy) {
        Ember.run.cancel(scheduleDestroy);
        this._scheduleDestroy = null;
      }
    },
    scheduleDestroy: function scheduleDestroy() {
      var _this2 = this;

      this.cancelScheduleDestroy();
      this._scheduleDestroy = Ember.run.next(function () {
        return _this2.destroy();
      });
    },
    willDestroy: function willDestroy() {
      this.cancelScheduleDestroy();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});