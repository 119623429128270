define("ember-cli-sketch/-private/util/object", ["exports", "ember-cli-sketch/-private/util/math"], function (_exports, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reduce = _exports.pick = _exports.millimetersToPixels = _exports.compact = void 0;

  var reduce = function reduce(hash, fn) {
    return Object.keys(hash || {}).reduce(function (reduced, key) {
      var value = fn(key, hash[key]);

      if (value !== undefined) {
        reduced[key] = value;
      }

      return reduced;
    }, {});
  };

  _exports.reduce = reduce;

  var compact = function compact(hash) {
    return reduce(hash, function (key, value) {
      return value;
    });
  };

  _exports.compact = compact;

  var millimetersToPixels = function millimetersToPixels(hash) {
    return reduce(hash, function (key, value) {
      return (0, _math.millimetersToPixels)(value);
    });
  };

  _exports.millimetersToPixels = millimetersToPixels;

  var pick = function pick(hash, keys) {
    return keys.reduce(function (reduced, key) {
      var value = hash[key];

      if (value !== undefined) {
        reduced[key] = value;
      }

      return reduced;
    }, {});
  };

  _exports.pick = pick;
});