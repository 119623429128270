define("ember-cli-zuglet/-private/lifecycle/observed/internal/dynamic", ["exports", "ember-cli-zuglet/-private/lifecycle/observed/internal", "ember-cli-zuglet/-private/lifecycle/util/object-observer"], function (_exports, _internal, _objectObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._dirty = true;

      var _this$getProperties = this.getProperties('parent', 'opts'),
          parent = _this$getProperties.parent,
          opts = _this$getProperties.opts;

      this.parentObserver = new _objectObserver.default({
        object: parent,
        observe: opts.parent,
        delegate: {
          updated: function updated(object, key) {
            return _this.onParentPropertyUpdated(object, key);
          }
        }
      });
    },
    getObservable: function getObservable() {
      if (this._dirty) {
        this._dirty = false;
        var observable = this.createObservable();
        this.setObservable(observable);
      }

      return this._super.apply(this, arguments);
    },
    createObservable: function createObservable() {
      var _this$getProperties2 = this.getProperties('parent', 'opts'),
          parent = _this$getProperties2.parent,
          content = _this$getProperties2.opts.content;

      return content(parent) || null;
    },
    dirty: function dirty(notify) {
      this.setObservable(null);
      this._dirty = true;

      if (notify) {
        this.notifyPropertyChange();
      }
    },
    notifyPropertyChange: function notifyPropertyChange() {
      var _this$getProperties3 = this.getProperties('parent', 'key'),
          parent = _this$getProperties3.parent,
          key = _this$getProperties3.key;

      if (parent.isDestroying) {
        return;
      }

      parent.notifyPropertyChange(key);
    },
    onParentPropertyUpdated: function onParentPropertyUpdated() {
      this.dirty(true);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.parentObserver.destroy();
    }
  });

  _exports.default = _default;
});