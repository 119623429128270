define("design/components/ui-input/dropdown/item/pair/component", ["exports", "design/components/ui-input/dropdown/item/pair/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':pair'],
    layout: _template.default
  });

  _exports.default = _default;
});