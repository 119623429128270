define("ember-cli-zuglet/-private/query/query", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/internal/read-only-props-mixin", "ember-cli-zuglet/-private/util/serialized", "ember-cli-zuglet/-private/internal/invoke", "ember-cli-zuglet/-private/query/internal", "ember-cli-zuglet/-private/observers/computed"], function (_exports, _modelMixin, _readOnlyPropsMixin, _serialized, _invoke, _internal, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var StateMixin = (0, _readOnlyPropsMixin.default)(_internal.state);
  var MetaMixin = (0, _readOnlyPropsMixin.default)(_internal.meta);

  var type = function type(value) {
    return Ember.computed.equal('type', value).readOnly();
  };

  var _default = Ember.Object.extend(_modelMixin.default, StateMixin, MetaMixin, {
    isQuery: true,
    type: Ember.computed.readOnly('_internal.type'),
    ref: Ember.computed('_internal.normalizedQuery', function () {
      return this.get('_internal.normalizedQuery').model(true);
    }).readOnly(),
    isArray: type('array'),
    isFirst: type('first'),
    serialized: (0, _serialized.default)([].concat(_toConsumableArray(_internal.state), _toConsumableArray(_internal.meta))),
    load: (0, _invoke.invokePromiseReturningThis)('load'),
    reload: (0, _invoke.invokePromiseReturningThis)('reload'),
    observe: (0, _invoke.invokeReturningModel)('observe'),
    observers: (0, _computed.observers)(),
    toStringExtension: function toStringExtension() {
      var query = this.get('ref.string');
      return "".concat(query);
    }
  });

  _exports.default = _default;
});