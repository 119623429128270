define("ember-cli-zuglet/-private/storage/reference/base/model", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/storage/reference/base/internal", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _modelMixin, _internal, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StatePropertiesMixin = Ember.Mixin.create(_internal.state.reduce(function (hash, key) {
    hash[key] = Ember.computed.readOnly("_internal.".concat(key));
    return hash;
  }, {}));

  var _default = Ember.Object.extend(_modelMixin.default, StatePropertiesMixin, {
    ref: Ember.computed(function () {
      return this._internal.ref.model(true);
    }).readOnly(),
    // { optional }
    load: (0, _invoke.invokePromiseReturningThis)('load')
  });

  _exports.default = _default;
});