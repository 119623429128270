define("ember-cli-sketch/-private/node/attributes/attribute/-base", ["exports", "ember-cli-sketch/-private/computed/prop"], function (_exports, _prop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    attributes: null,
    opts: null,
    model: Ember.computed.readOnly('attributes.model'),
    init: function init() {
      this._super.apply(this, arguments);

      this._createProps();
    },
    _createProps: function _createProps() {
      var opts = this.opts;

      for (var key in opts) {
        var value = opts[key];

        if ((0, _prop.isProp)(value)) {
          Ember.defineProperty(this, key, Ember.computed.readOnly("model.".concat(value.key)));
        } else {
          this.set(key, value);
        }
      }
    },
    getPrimitiveValue: function getPrimitiveValue() {
      return this.model.get(this.opts.target);
    },
    setPrimitiveValue: function setPrimitiveValue(value) {
      return this.model.set(this.opts.target, value);
    },
    //
    isChanged: function isChanged(value) {
      var transformed = this.transformValue(value);
      var changed = this.getValue() !== transformed;
      return {
        changed: changed,
        transformed: transformed
      };
    },
    _invokeChanged: function _invokeChanged(value) {
      var changed = this.opts.changed;

      if (!changed) {
        return;
      }

      var deps = this.getProperties(Object.keys(this.opts));
      var model = this.model;
      return changed.call(this.model, value, deps, model);
    },
    didSetValue: function didSetValue(value) {
      var deps = this._invokeChanged(value);

      if (!deps) {
        return;
      }

      for (var key in deps) {
        var attribute = this.attributes.attribute(key);

        var _attribute$isChanged = attribute.isChanged(deps[key]),
            changed = _attribute$isChanged.changed,
            transformed = _attribute$isChanged.transformed;

        if (changed) {
          attribute.setValue(transformed, true);
        }
      }
    },
    //
    getValue: function getValue() {
      return this.transformValue(this.getPrimitiveValue());
    },
    setValue: function setValue(value, skipNotify) {
      if (this.immutable) {
        return this.getValue();
      }

      value = this.setPrimitiveValue(this.transformValue(value));

      if (!skipNotify) {
        this.didSetValue(value);
      }

      return value;
    }
  });

  _exports.default = _default;
});