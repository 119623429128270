define("ember-cli-sketch/-private/sketches/fonts/loader/compound", ["exports", "ember-cli-sketch/-private/util/load-fonts"], function (_exports, _loadFonts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    fonts: null,
    loaders: null,
    isLoading: Ember.computed('loaders.@each.isLoading', function () {
      return !!this.loaders.findBy('isLoading', true);
    }).readOnly(),
    isLoaded: Ember.computed('loaders.@each.isLoaded', function () {
      return !this.loaders.findBy('isLoaded', false);
    }).readOnly(),
    isError: Ember.computed('loaders.@each.isError', function () {
      return !!this.loaders.findBy('isError', true);
    }).readOnly(),
    error: Ember.computed('loaders.@each.error', function () {
      var errors = Ember.A(this.loaders.map(function (loader) {
        return loader.error;
      })).compact();

      if (!errors.length) {
        return;
      }

      return (0, _loadFonts.err)(errors);
    }).readOnly()
  });

  _exports.default = _default;
});