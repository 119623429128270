define("ember-cli-zuglet/-private/observers/query/internal", ["exports", "ember-cli-zuglet/-private/observers/observer/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    query: null,
    createModel: function createModel() {
      return this.store.factoryFor('zuglet:observer/query').create({
        _internal: this
      });
    }
  });

  _exports.default = _default;
});