define("design/util/computed", ["exports", "design/util/array", "design/util/object"], function (_exports, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validationClassName = _exports.t = _exports.style = _exports.keys = _exports.className = _exports.chunk = _exports.array = void 0;

  var className = function className(opts) {
    opts = Ember.assign({
      prefix: "".concat(opts.key, "-")
    }, opts);
    var _opts = opts,
        key = _opts.key,
        prefix = _opts.prefix,
        value = _opts.value,
        recompute = _opts.recompute;
    return Ember.computed(key, function () {
      var string = this.get(key);

      if (string === undefined) {
        string = value;
      }

      if (recompute) {
        string = recompute.call(this, string);
      }

      if (!string) {
        return;
      }

      return "".concat(prefix).concat(string);
    }).readOnly();
  };

  _exports.className = className;

  var validationClassName = function validationClassName(opts) {
    var _Ember;

    opts = Ember.assign({
      key: 'valid',
      prefix: "validation-",
      touched: 'touched'
    }, opts);
    var _opts2 = opts,
        key = _opts2.key,
        prefix = _opts2.prefix,
        touched = _opts2.touched;
    var deps = [key];

    if (touched) {
      deps.push(touched);
    }

    return (_Ember = Ember).computed.apply(_Ember, deps.concat([function () {
      var value = this.get(key);

      if (typeof value !== 'boolean') {
        return;
      }

      if (touched && !this.get(touched)) {
        return;
      }

      var string = value ? 'valid' : 'invalid';
      return "".concat(prefix).concat(string);
    }])).readOnly();
  };

  _exports.validationClassName = validationClassName;

  var chunk = function chunk(key, size) {
    return Ember.computed("".concat(key, ".[]"), function () {
      var array = this.get(key);
      return (0, _array.chunk)(array, size);
    }).readOnly();
  };

  _exports.chunk = chunk;

  var array = function array() {
    return Ember.computed(function () {
      return Ember.A();
    }).readOnly();
  };

  _exports.array = array;

  var style = function style() {
    var _Ember2;

    for (var _len = arguments.length, deps = new Array(_len), _key = 0; _key < _len; _key++) {
      deps[_key] = arguments[_key];
    }

    var fn = deps.pop();
    return (_Ember2 = Ember).computed.apply(_Ember2, deps.concat([function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var props = fn.call.apply(fn, [this].concat(args));
      return (0, _object.style)(props);
    }])).readOnly();
  };

  _exports.style = style;

  var keys = function keys(prop) {
    return Ember.computed(prop, function () {
      var value = this.get(prop);

      if (!value) {
        return;
      }

      return Object.keys(value);
    }).readOnly();
  };

  _exports.keys = keys;
  var t = {
    static: function _static(string) {
      return Ember.computed('intl.locale', {
        get: function get(key) {
          var value = this["_".concat(key)];

          if (!value) {
            value = this.intl.t(string);
          }

          return value;
        },
        set: function set(key, value) {
          this["_".concat(key)] = value;
          return value;
        }
      });
    }
  };
  _exports.t = t;
});