define("ember-cli-sketch/task", ["exports", "ember-cli-sketch/-private/task"], function (_exports, _task) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "destroyTasks", {
    enumerable: true,
    get: function get() {
      return _task.destroyTasks;
    }
  });
  Object.defineProperty(_exports, "task", {
    enumerable: true,
    get: function get() {
      return _task.task;
    }
  });
  var _default = _task.default;
  _exports.default = _default;
});