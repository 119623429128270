define("ember-cli-sketch/-private/stage/tools/node/remove", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    perform: function perform() {
      var stage = this.stage,
          selection = this.selection;
      selection = selection.copy();

      var perform = function perform(filter) {
        var array = selection;

        if (filter) {
          array = array.filter(function (node) {
            return filter(node.model);
          });
        }

        array.forEach(function (node) {
          return node.remove();
        });
      };

      var nodes = selection.map(function (node) {
        return node.model;
      });
      stage.handle({
        type: 'remove-nodes',
        nodes: nodes,
        perform: perform
      });
    },
    activate: function activate() {
      if (this.selection.any) {
        this.perform();
      }

      this.done();
    }
  });

  _exports.default = _default;
});