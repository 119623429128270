define("design/components/ui-block/card/component", ["exports", "design/components/ui-block/card/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-card', '_type'],
    layout: _template.default,
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    })
  });

  _exports.default = _default;
});