define("design/util/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.widow = void 0;

  var widow = function widow(text) {
    if (Ember.typeOf(text) !== 'string') {
      return text;
    }

    text = text.trim();

    if (text.length === 0) {
      return text;
    }

    var words = text.split(' ');

    if (words.length === 1) {
      return words[0];
    }

    var last = words.pop();
    words = words.join(' ').trim();
    return "".concat(words, "\xA0").concat(last);
  };

  _exports.widow = widow;
});