define("ember-cli-zuglet/-private/queue/concurrent-queue", ["exports", "ember-cli-zuglet/-private/queue/queue"], function (_exports, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _queue.default.extend({
    didCreateOperation: function didCreateOperation(operation) {
      operation.invoke().catch(function () {});

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});