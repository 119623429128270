define("design/components/ui-block/left-right/component", ["exports", "design/components/ui-block/left-right/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-left-right', 'left:has-left', 'right:has-right'],
    layout: _template.default,
    actions: {
      left: function left() {
        this.left && this.left();
      },
      right: function right() {
        this.right && this.right();
      }
    }
  });

  _exports.default = _default;
});