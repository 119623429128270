define("design/validation/index", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validation = _exports.string = _exports.property = _exports.number = _exports.instance = _exports.email = _exports.custom = void 0;

  var property = function property(type, opts) {
    return Ember.assign({
      type: type,
      required: true
    }, opts);
  };

  _exports.property = property;

  var string = function string(opts) {
    return property('string', opts);
  };

  _exports.string = string;

  var number = function number(opts) {
    return property('number', opts);
  };

  _exports.number = number;

  var email = function email(opts) {
    return property('email', opts);
  };

  _exports.email = email;

  var instance = function instance(opts) {
    return property('instance', opts);
  };

  _exports.instance = instance;

  var custom = function custom(opts) {
    return property('custom', opts);
  };

  _exports.custom = custom;

  var validation = function validation(opts) {
    return (0, _lifecycle.model)().named('validation/validation').mapping(function (owner) {
      return {
        owner: owner,
        opts: opts
      };
    });
  };

  _exports.validation = validation;
});