define("ember-cli-sketch/-private/stage/selection", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    all: (0, _computed.array)(),
    attached: Ember.computed.filterBy('all', 'isAttached', true),
    selectable: Ember.computed.filterBy('all', 'isSelectable', true),
    any: Ember.computed.gt('attached.length', 0),
    copy: function copy() {
      return this.attached.slice();
    },
    clear: function clear() {
      this.all.clear();
    },
    includes: function includes(node) {
      return this.attached.includes(node);
    },
    addNode: function addNode(node) {
      this.all.addObject(node);
    },
    addNodes: function addNodes(nodes) {
      this.all.addObjects(nodes);
    },
    removeNode: function removeNode(node) {
      this.all.removeObject(node);
    },
    removeNodes: function removeNodes(nodes) {
      this.all.removeObjects(nodes);
    },
    removeExcept: function removeExcept(node) {
      var nodes = this.filter(function (selection) {
        return selection !== node;
      });
      this.removeNodes(nodes);
      return nodes;
    },
    replace: function replace(next) {
      var all = this.all;
      all.replace(0, all.length, next);
    },
    find: function find() {
      var _this$attached;

      return (_this$attached = this.attached).find.apply(_this$attached, arguments);
    },
    filter: function filter() {
      var _this$attached2;

      return (_this$attached2 = this.attached).filter.apply(_this$attached2, arguments);
    },
    reset: function reset() {
      this.clear();
    }
  });

  _exports.default = _default;
});