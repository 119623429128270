define("design/components/ui-block/tooltip/overlay/component", ["exports", "design/components/ui-block/tooltip/overlay/template", "design/util/hover-mixin"], function (_exports, _template, _hoverMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hoverMixin.default, {
    classNameBindings: [':overlay', 'isHover:visible:hidden'],
    attributeBindings: ['style'],
    layout: _template.default,
    isHover: Ember.computed.or('parent.hover', 'hover'),
    _calculatePosition: function _calculatePosition() {
      var _this$parent$element$ = this.parent.element.getBoundingClientRect(),
          left = _this$parent$element$.left,
          top = _this$parent$element$.top,
          width = _this$parent$element$.width,
          height = _this$parent$element$.height;

      return {
        top: Math.floor(top + height / 2),
        left: Math.ceil(left + width)
      };
    },
    style: Ember.computed('isHover', function () {
      if (!this.isHover) {
        return;
      }

      var _this$_calculatePosit = this._calculatePosition(),
          top = _this$_calculatePosit.top,
          left = _this$_calculatePosit.left;

      return Ember.String.htmlSafe("top: ".concat(top, "px; left: ").concat(left, "px"));
    }).readOnly()
  });

  _exports.default = _default;
});