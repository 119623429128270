define("ember-cli-zuglet/-private/lifecycle/observed/property", ["exports", "ember-cli-zuglet/-private/util/destroyable"], function (_exports, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var reusable = function reusable() {
    return true;
  };

  var get = function get(internal) {
    return internal.getObservable();
  };

  var set = function set(internal, value) {
    return internal.setObservable(value);
  };

  var factoryName = function factoryName(opts) {
    var type = opts.type;
    return "zuglet:lifecycle/observed/".concat(type, "/internal");
  };

  var create = function create(opts) {
    var factory = factoryName(opts);
    return function (key) {
      var parent = this;
      var owner = Ember.getOwner(this);
      var instance = owner.factoryFor(factory).create({
        parent: parent,
        key: key,
        opts: opts
      });
      return instance;
    };
  };

  var _default = function _default(opts) {
    opts.type = opts.content ? 'dynamic' : 'writable';
    return (0, _destroyable.default)({
      reusable: reusable,
      create: create(opts),
      get: get,
      set: opts.type === 'writable' ? set : null
    });
  };

  _exports.default = _default;
});