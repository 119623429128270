define("analytics/services/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var model = function model(name) {
    return Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:store').models.create("analytics/".concat(name), {
        name: name
      });
    }).readOnly();
  };

  var routeNameToPath = function routeNameToPath(name) {
    return ['/', name.replace(/\./g, '/')].join('');
  };

  var _default = Ember.Service.extend({
    ga: model('ga'),
    hotjar: model('hotjar'),
    tawk: model('tawk'),
    suppressed: false,
    sendPageView: function sendPageView(suppressed, opts) {
      var url = opts.url,
          path = opts.path,
          routeName = opts.routeName;

      if (!path && routeName) {
        path = routeNameToPath(routeName);
      }

      this.ga.sendPageView({
        url: url,
        path: path
      });

      if (!suppressed) {
        this.tawk.sendPageView({
          url: url,
          path: path
        });
      }
    },
    onRouteDidChange: function onRouteDidChange(suppressed, _ref) {
      var _this = this;

      var url = _ref.url,
          routeName = _ref.routeName;
      this.set('suppressed', suppressed);
      this.sendPageView(suppressed, {
        url: url,
        routeName: routeName
      });
      Ember.run.cancel(this._tawk);

      if (!suppressed) {
        this._tawk = Ember.run.later(function () {
          return _this.tawk.show();
        }, 100);
      }
    },
    onRouteWillChange: function onRouteWillChange() {},
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._tawk);
    }
  });

  _exports.default = _default;
});