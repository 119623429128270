define("ember-cli-zuglet/-private/lifecycle/model/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/lifecycle/model/runtime"], function (_exports, _internal, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    parent: null,
    key: null,
    opts: null,
    runtime: function runtime(create) {
      var _this = this;

      var runtime = this._runtime;

      if (!runtime && create) {
        var _this$getProperties = this.getProperties('parent', 'key', 'opts'),
            parent = _this$getProperties.parent,
            key = _this$getProperties.key,
            opts = _this$getProperties.opts;

        runtime = new _runtime.default(parent, key, opts, {
          updated: function updated() {
            return _this.notifyPropertyChange();
          }
        });
        this._runtime = runtime;
      }

      return runtime;
    },
    notifyPropertyChange: function notifyPropertyChange() {
      var parent = this.parent,
          key = this.key;

      if (parent.isDestroying) {
        return;
      }

      parent.notifyPropertyChange(key);
    },
    model: function model(create) {
      var runtime = this.runtime(create);
      return runtime && runtime.content(create);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._runtime && this._runtime.destroy();
    }
  });

  _exports.default = _default;
});