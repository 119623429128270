define("ember-cli-zuglet/-private/internal/with-property-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(internal, notify, cb, done) {
    var model;

    if (notify) {
      model = internal.model(false);
    }

    var enabled = !!model;

    if (enabled) {
      model.beginPropertyChanges();
    }

    var changes = [];

    var changed = function changed(key) {
      if (enabled && !changes.includes(key)) {
        if (key) {
          model.notifyPropertyChange(key);
        }
      }

      changes.push(key);
    };

    Object.defineProperty(changed, 'any', {
      get: function get() {
        return changes.length > 0;
      }
    });

    try {
      var result = cb(changed);
      done && done(changed, result);
      return result;
    } finally {
      if (enabled) {
        model.endPropertyChanges();
      }
    }
  };

  _exports.default = _default;
});