define("design/components/ui-dialog/alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PbVvhDkw",
    "block": "{\"symbols\":[\"act\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"\\n  \"],[1,[28,\"widow\",[[24,[\"dialog\",\"message\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"left\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isBusy\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/icon\",[\"refresh\"],[[\"spin\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"dialog\",\"actions\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"class\",\"type\",\"color\",\"value\",\"disabled\",\"action\"],[\"small\",\"action\",[28,\"if\",[[23,1,[\"type\"]],[23,1,[\"type\"]],\"outline\"],null],[23,1,[\"color\"]],[23,1,[\"label\"]],[24,[\"isBusy\"]],[28,\"action\",[[23,0,[]],\"invoke\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-dialog/alert/template.hbs"
    }
  });

  _exports.default = _default;
});