define("design/util/drag-over-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isDraggingOver: false,
    dragEnter: function dragEnter() {
      this._super.apply(this, arguments);

      this.set('isDraggingOver', true);
    },
    dragLeave: function dragLeave() {
      this._super.apply(this, arguments);

      this.set('isDraggingOver', false);
    },
    drop: function drop() {
      this._super.apply(this, arguments);

      this.set('isDraggingOver', false);
    }
  });

  _exports.default = _default;
});