define("ember-cli-zuglet/-private/util/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(curr, next) {
    if (!curr) {
      return false;
    }

    return curr.isEqual(next);
  };

  _exports.default = _default;
});