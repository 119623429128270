define("ember-cli-sketch/-private/node/frame/sized", ["exports", "ember-cli-sketch/-private/node/frame/-base", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/node/frame/-computed"], function (_exports, _base, _computed, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = ['x', 'y', 'width', 'height', 'rotation'];

  var rotation = function rotation() {
    return Ember.computed("model.rotation", 'parent.isContainer', function () {
      if (this.parent.isContainer) {
        return 0;
      }

      return this.model.rotation;
    }).readOnly();
  };

  var _default = _base.default.extend({
    x: (0, _base.model)('x'),
    y: (0, _base.model)('y'),
    width: (0, _base.model)('width'),
    height: (0, _base.model)('height'),
    rotation: rotation(),
    properties: (0, _computed.serialized)(keys),
    rotated: (0, _computed2.rotated)('properties'),
    absolute: (0, _computed2.absolute)('properties', 'parent.parent.frame.absolute'),
    absoluteBounds: (0, _computed2.rotated)('absolute'),
    _zoomed: (0, _computed2.zoomed)('properties'),
    _zoomedRotated: (0, _computed2.zoomed)('rotated'),
    rounded: (0, _computed2.rounded)('_zoomed'),
    hover: (0, _computed2.absolute)('_zoomed', 'parent.parent.frame.hover'),
    guidelines: (0, _computed2.absolute)('_zoomedRotated', 'parent.parent.frame.guidelines'),
    selection: (0, _computed2.absolute)('_zoomed', 'parent.parent.frame.selection')
  });

  _exports.default = _default;
});