define("design/models/flow/flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var delta = function delta(value) {
    return Ember.computed('steps.[]', 'current', function () {
      var current = this.current,
          steps = this.steps;
      var index = steps.indexOf(current) + value;

      if (index < 0 || index > steps.length - 1) {
        return;
      }

      return steps.objectAt(index);
    }).readOnly();
  };

  var _default = Ember.Object.extend({
    owner: null,
    opts: null,
    steps: Ember.computed(function () {
      var _this = this;

      var models = this.store.models,
          steps = this.opts.steps,
          owner = this.owner;
      return Ember.A(Object.keys(steps).map(function (id) {
        return models.create('flow/step', {
          id: id,
          opts: steps[id],
          flow: _this,
          owner: owner
        });
      }));
    }).readOnly(),
    initial: Ember.computed.readOnly('steps.firstObject'),
    current: Ember.computed('steps.@each.current', function () {
      var steps = this.steps;
      return steps.find(function (step) {
        return step.current === true;
      });
    }).readOnly(),
    prev: delta(-1),
    next: delta(+1),
    step: function step(id) {
      return this.steps.findBy('id', id);
    }
  });

  _exports.default = _default;
});