define("ember-cli-zuglet/-private/data/timestamp/internal", ["exports", "ember-cli-zuglet/-private/data/internal/internal", "ember-cli-zuglet/-private/util/firestore-types", "luxon"], function (_exports, _internal, _firestoreTypes, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    content: null,
    isTimestamp: true,
    isServerTimestamp: Ember.computed('content', function () {
      return (0, _firestoreTypes.isFirestoreServerTimestamp)(this.get('content'));
    }).readOnly(),
    date: Ember.computed('content', function () {
      var content = this.get('content');

      if (Ember.typeOf(content) === 'date') {
        return content;
      }

      return null;
    }).readOnly(),
    dateTime: Ember.computed('date', function () {
      var date = this.get('date');

      if (!date) {
        return date;
      }

      return _luxon.DateTime.fromJSDate(date);
    }).readOnly(),
    createModel: function createModel() {
      return this.factoryFor('zuglet:data/timestamp').create({
        _internal: this
      });
    }
  });

  _exports.default = _default;
});