define("ember-cli-zuglet/-private/util/normalize-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isFrozen = Object.isFrozen; // This fixes "Cannot add property _super, object is not extensible" for A(array)
  // for frozen EMPTY_ARRAY used in Ember.js ArrayMixin on removeAt()

  var _default = function _default(array) {
    if (array && isFrozen(array) && array.length === 0) {
      return [];
    }

    return array;
  };

  _exports.default = _default;
});