define("ember-cli-zuglet/-private/observers/computed", ["exports", "ember-cli-zuglet/-private/util/destroyable"], function (_exports, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.observers = _exports.default = void 0;

  var _default = function _default(opts) {
    return (0, _destroyable.default)({
      create: function create() {
        return Ember.getOwner(this).factoryFor("zuglet:observers/internal").create({
          owner: this,
          opts: opts
        });
      }
    });
  };

  _exports.default = _default;

  var observers = function observers() {
    var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'observers';
    return Ember.computed(function () {
      var internal = this.get("_internal.".concat(key));
      return internal && internal.model(true);
    }).readOnly();
  };

  _exports.observers = observers;
});