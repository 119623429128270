define("design/components/ui-block/segmented-button/segment/component", ["exports", "design/components/ui-block/segmented-button/segment/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':segment', 'selected:selected'],
    click: function click() {
      this.action && this.action();
    }
  });

  _exports.default = _default;
});