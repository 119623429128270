define("ember-cli-sketch/-private/stage/renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    stage: null,
    _component: null,
    isAttached: Ember.computed.bool('_component'),
    size: Ember.computed.readOnly('_component.elementSize'),
    attach: function attach(_component) {
      (false && !(!this._component) && Ember.assert("Stage ".concat(this, " is already attached to ").concat(this._component), !this._component));
      this.setProperties({
        _component: _component
      });
    },
    detach: function detach() {
      this.setProperties({
        _component: null
      });
    },
    isAttachedTo: function isAttachedTo(component) {
      return this._component === component;
    },
    recalculateSize: function recalculateSize() {
      var isAttached = this.isAttached,
          _component = this._component;

      if (!isAttached) {
        return;
      }

      _component.recalculateSize();
    },
    focus: function focus() {
      var isAttached = this.isAttached,
          _component = this._component;

      if (!isAttached) {
        return;
      }

      _component.focus();
    },
    screenPointFromMouseEvent: function screenPointFromMouseEvent(e) {
      var isAttached = this.isAttached,
          _component = this._component;

      if (!isAttached) {
        return;
      }

      return _component.screenPointFromMouseEvent(e);
    }
  });

  _exports.default = _default;
});