define("ember-cli-sketch/-private/node/frame/nodes", ["exports", "ember-cli-sketch/-private/node/frame/-base", "ember-cli-sketch/-private/node/frame/-computed", "ember-cli-sketch/-private/node/frame/-bounds-mixin"], function (_exports, _base, _computed, _boundsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BoundsMixin = (0, _boundsMixin.default)('properties', 'parent.visible', '_rotatedFrame');

  var _default = _base.default.extend(BoundsMixin, {
    absolute: (0, _computed.absolute)('properties', 'parent.parent.frame.absolute'),
    absoluteBounds: (0, _computed.rotated)('absolute'),
    rotated: (0, _computed.rotated)('properties'),
    _zoomedRotated: (0, _computed.zoomed)('rotated'),
    hover: (0, _computed.absolute)('_zoomedRotated', 'parent.parent.frame.hover'),
    guidelines: (0, _computed.absolute)('_zoomedRotated', 'parent.parent.frame.guidelines', false),
    selection: (0, _computed.absolute)('_zoomedRotated', 'parent.parent.frame.selection')
  });

  _exports.default = _default;
});