define("ember-cli-zuglet/-private/observers/query/observer", ["exports", "ember-cli-zuglet/-private/observers/observer/observer"], function (_exports, _observer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _observer.default.extend({
    content: Ember.computed(function () {
      return this.get('_internal.query').model(true);
    }).readOnly(),
    toStringExtension: function toStringExtension() {
      return this.get('content.ref.string');
    }
  });

  _exports.default = _default;
});