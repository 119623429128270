define("ember-cli-sketch/-private/actions/stage/selectable", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    perform: function perform(stage, _ref) {
      var value = _ref.value;

      if (stage.isSelectable === value) {
        return;
      }

      if (!value) {
        stage.selection.clear();
      }

      stage.update({
        selectable: value
      });
    }
  });

  _exports.default = _default;
});