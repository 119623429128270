define("design/models/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var status = function status(value) {
    return Ember.computed.equal('status', value).readOnly();
  };

  var _default = Ember.Object.extend({
    dialogs: null,
    presenter: null,
    dialog: null,
    promise: null,
    status: null,
    error: null,
    isConfirmed: status('confirmed'),
    isRejected: status('rejected'),
    isCancelled: status('cancelled'),
    componentName: Ember.computed('dialog', function () {
      var dialog = this.dialog;
      return "ui-dialog/".concat(dialog);
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this._deferred = Ember.RSVP.defer();
      this.promise = this._deferred.promise;
    },
    _done: function _done() {
      this.dialogs._remove(this);
    },
    resolve: function resolve(props) {
      this.setProperties(props);

      this._deferred.resolve(this);

      this._done();
    },
    reject: function reject(err) {
      this.setProperties({
        status: 'rejected',
        error: err
      });

      this._deferred.reject(err);

      this._done();
    },
    confirm: function confirm() {
      this.resolve({
        status: 'confirmed'
      });
    },
    cancel: function cancel() {
      this.resolve({
        status: 'cancelled'
      });
    },
    toStringExtension: function toStringExtension() {
      return this.dialog;
    }
  });

  _exports.default = _default;
});