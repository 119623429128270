define("design/components/ui-input/button/component", ["exports", "design/components/ui-input/button/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: [':ui-input-button', '_size', '_color', '_type', 'disabled:disabled:enabled', 'hover:hover'],
    attributeBindings: ['disabled'],
    tagName: 'button',
    _size: (0, _computed.className)({
      key: 'size',
      value: 'regular'
    }),
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    value: null,
    click: function click() {
      if (this.disabled) {
        return;
      }

      this.action && this.action();
    }
  });

  _exports.default = _default;
});