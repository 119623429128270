define("ember-cli-sketch/-private/actions/node/aspect-fit", ["exports", "ember-cli-sketch/-private/actions/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    calculateSize: function calculateSize(node, aspect) {
      var _node$frame$propertie = node.frame.properties,
          width = _node$frame$propertie.width,
          height = _node$frame$propertie.height;
      var current = height / width;

      if (current > aspect) {
        return {
          width: width,
          height: width / aspect
        };
      } else {
        return {
          height: height,
          width: height * aspect
        };
      }
    },
    calculateFrame: function calculateFrame(node, size) {
      return node.frame.center(size);
    },
    perform: function perform(node, _ref) {
      var aspect = _ref.aspect;

      if (!aspect) {
        return;
      }

      var size = this.calculateSize(node, aspect);
      var frame = this.calculateFrame(node, size);
      node.update(frame);
    }
  });

  _exports.default = _default;
});