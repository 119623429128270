define("ember-cli-zuglet/-private/firebase/prepare", ["exports", "ember-cli-zuglet/-private/util/fastboot"], function (_exports, _fastboot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var noop = function noop() {};

  var workaroundWindowEventListenersInFastBoot = function workaroundWindowEventListenersInFastBoot() {
    if (!window.addEventListener) {
      window.addEventListener = noop;
    }

    if (!window.removeEventListener) {
      window.removeEventListener = noop;
    }
  };

  var _default = function _default(sender) {
    if ((0, _fastboot.isFastBoot)(sender)) {
      workaroundWindowEventListenersInFastBoot();
    }
  };

  _exports.default = _default;
});