define("ember-cli-sketch/components/ui-block/sketch/stage/node/text/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/node/-component", "ember-cli-sketch/components/ui-block/sketch/stage/node/text/template", "ember-cli-sketch/components/ui-block/sketch/stage/node/-computed", "ember-cli-sketch/-private/util/math"], function (_exports, _component, _template, _computed, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var px = function px(value, zoom) {
    if (!value) {
      return;
    }

    value = (0, _math.round)(value * zoom, 4);
    return "".concat(value, "px");
  };

  var _default = _component.default.extend({
    layout: _template.default,
    classNameBindings: ['align', 'verticalAlign', 'fontStyle', 'isLoading:loading:loaded', 'isEditing:editing:rendering'],
    loader: (0, _computed.fontLoader)('model.fontFamily', function () {
      var fontFamily = this.model.fontFamily;

      if (!fontFamily) {
        return;
      }

      return {
        google: {
          families: [fontFamily]
        }
      };
    }),
    strings: Ember.computed.readOnly('model.node.stage.strings'),
    isLoading: Ember.computed.readOnly('loader.isLoading'),
    isEditing: (0, _computed.editing)('model'),
    align: (0, _computed.className)('model.align', 'align'),
    verticalAlign: (0, _computed.className)('model.verticalAlign', 'vertical'),
    fontStyle: (0, _computed.className)('model.fontStyle', 'style'),
    text: Ember.computed.readOnly('model.text'),
    style: (0, _computed.style)('model.{fill,color,opacity,fontFamily,fontWeight,fontSize,lineHeight,padding}', 'zoom', function () {
      var _this$model = this.model,
          background = _this$model.fill,
          color = _this$model.color,
          opacity = _this$model.opacity,
          fontFamily = _this$model.fontFamily,
          fontWeight = _this$model.fontWeight,
          fontSize = _this$model.fontSize,
          lineHeight = _this$model.lineHeight,
          padding = _this$model.padding,
          zoom = this.zoom;
      return {
        background: background,
        opacity: opacity,
        color: color,
        fontWeight: fontWeight,
        lineHeight: lineHeight ? px(lineHeight, zoom) : 1,
        fontFamily: "\"".concat(fontFamily, "\""),
        fontSize: px(fontSize, zoom),
        padding: px(padding, zoom)
      };
    }),
    editable: Ember.computed('text', {
      get: function get() {
        return this.text;
      },
      set: function set(key, text) {
        this.model.update({
          text: text
        });
        return text;
      }
    })
  });

  _exports.default = _default;
});