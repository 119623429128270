define("ember-cli-zuglet/-private/data/array/internal", ["exports", "ember-cli-zuglet/-private/data/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.content = Ember.A();
    },
    createModel: function createModel() {
      return this.factoryFor('zuglet:data/array').create({
        _internal: this
      });
    },
    getModelValue: function getModelValue(idx) {
      return this.serializer.getModelValue(this, idx);
    },
    replaceModelValues: function replaceModelValues(idx, amt, values) {
      return this.serializer.replaceModelValues(this, idx, amt, values);
    },
    withArrayContentChanges: function withArrayContentChanges(notify, cb) {
      var _this = this;

      return this.withPropertyChanges(notify, function (changed) {
        var builder = function builder(idx, adding, removing, runtime) {
          var model = _this.model(false);

          if (model) {
            model.arrayContentWillChange(idx, removing, adding);
          }

          var result = runtime(changed);

          if (model) {
            model.arrayContentDidChange(idx, removing, adding);
          }

          if (adding > 0 || removing > 0) {
            changed();
          }

          return result;
        };

        return cb(builder);
      });
    }
  });

  _exports.default = _default;
});