define("ember-cli-sketch/components/ui-block/sketch/stage/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/template", "ember-cli-sketch/components/ui-block/sketch/stage/-events-mixin", "ember-cli-sketch/components/ui-block/sketch/stage/-ready", "ember-cli-sketch/components/ui-block/sketch/stage/-computed"], function (_exports, _template, _eventsMixin, _ready, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSketchComponent = _exports.default = void 0;

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var isSketchComponent = '__isSketchComponent';

  var getSketchComponent = function getSketchComponent(component) {
    if (!component || component[isSketchComponent]) {
      return component;
    }

    return getSketchComponent(component.parentView);
  };

  _exports.getSketchComponent = getSketchComponent;

  var _default = Ember.Component.extend(_eventsMixin.default, _ready.default, (_Ember$Component$exte = {
    classNameBindings: [':ui-block-sketch-stage', 'fill'],
    attributeBindings: ['style'],
    layout: _template.default
  }, _defineProperty(_Ember$Component$exte, isSketchComponent, true), _defineProperty(_Ember$Component$exte, "size", null), _defineProperty(_Ember$Component$exte, "fill", (0, _computed.className)('stage.fill', 'fill')), _defineProperty(_Ember$Component$exte, "elementSize", null), _defineProperty(_Ember$Component$exte, "stage", Ember.computed({
    get: function get() {
      return this._stage;
    },
    set: function set(_, value) {
      var current = this._stage;

      if (current !== value) {
        if (current) {
          this.detachStage(current);
        }

        if (value) {
          this.attachStage(value);
        }

        this._stage = value;
      }

      return value;
    }
  })), _defineProperty(_Ember$Component$exte, "cursor", Ember.computed.readOnly('stage.node.cursor.value')), _defineProperty(_Ember$Component$exte, "style", (0, _computed.style)('cursor', 'size', function () {
    var cursor = this.cursor,
        size = this.size;
    var props = {
      cursor: cursor
    };

    if (size) {
      var px = function px(key) {
        return "".concat(Math.ceil(size[key]), "px");
      };

      props.width = px('width');
      props.height = px('height');
    }

    return props;
  })), _defineProperty(_Ember$Component$exte, "disableSpellcheck", function disableSpellcheck() {
    var _document = document,
        body = _document.body;

    if (!body) {
      return;
    }

    if (typeof body.setAttribute !== 'function') {
      return;
    }

    document.body.setAttribute('spellcheck', false);
  }), _defineProperty(_Ember$Component$exte, "didInsertElement", function didInsertElement() {
    this._super.apply(this, arguments);

    this.disableSpellcheck();
    this.focus();
    this.notifyReady(this.stage);
  }), _defineProperty(_Ember$Component$exte, "willDestroyElement", function willDestroyElement() {
    this._super.apply(this, arguments);

    this.detachStage(this._stage);
  }), _defineProperty(_Ember$Component$exte, "isStageAttachedToSelf", function isStageAttachedToSelf(stage) {
    return stage && stage.node.renderer.isAttachedTo(this);
  }), _defineProperty(_Ember$Component$exte, "notifyReady", function notifyReady(stage) {
    if (!this.isStageAttachedToSelf(stage)) {
      return;
    }

    var ready = this.ready;

    if (ready) {
      ready(stage);
    }

    this.scheduleReady();
  }), _defineProperty(_Ember$Component$exte, "detachStage", function detachStage(stage) {
    stage && stage.node.detach();
  }), _defineProperty(_Ember$Component$exte, "attachStage", function attachStage(stage) {
    try {
      stage.node.attach(this);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console

      return;
    }

    if (this.element) {
      this.notifyReady(stage);
    }
  }), _defineProperty(_Ember$Component$exte, "focus", function focus() {
    var element = document.activeElement;

    if (element) {
      document.activeElement.blur();
    }
  }), _defineProperty(_Ember$Component$exte, "recalculateSize", function recalculateSize() {
    this.elementSizeDidChange();
  }), _Ember$Component$exte));

  _exports.default = _default;
});