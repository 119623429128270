define("ember-cli-zuglet/-private/firebase/instantiate", ["exports", "ember-cli-zuglet/-private/firebase/initializer"], function (_exports, _initializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(sender, identifier, opts) {
    return new _initializer.default(sender, identifier, opts);
  };

  _exports.default = _default;
});