define("ember-cli-zuglet/-private/internal/invoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeReturningModel = _exports.invokePromiseReturningUndefined = _exports.invokePromiseReturningThis = _exports.invokePromiseReturningModel = _exports.invoke = void 0;

  var invoke = function invoke(name, fn) {
    return function () {
      var _internal$name;

      var internal = this._internal;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var result = (_internal$name = internal[name]).call.apply(_internal$name, [internal].concat(args));

      if (fn) {
        result = fn(result, this);
      }

      return result;
    };
  };

  _exports.invoke = invoke;

  var invokeReturningModel = function invokeReturningModel(name) {
    return invoke(name, function (internal) {
      return internal && internal.model(true);
    });
  };

  _exports.invokeReturningModel = invokeReturningModel;

  var invokePromiseReturningThis = function invokePromiseReturningThis(name) {
    return invoke(name, function (promise, owner) {
      return promise.then(function () {
        return owner;
      });
    });
  };

  _exports.invokePromiseReturningThis = invokePromiseReturningThis;

  var invokePromiseReturningUndefined = function invokePromiseReturningUndefined(name) {
    return invoke(name, function (promise) {
      return promise.then(function () {
        return undefined;
      });
    });
  };

  _exports.invokePromiseReturningUndefined = invokePromiseReturningUndefined;

  var invokePromiseReturningModel = function invokePromiseReturningModel(name) {
    return invoke(name, function (promise) {
      return promise.then(function (internal) {
        return internal && internal.model(true);
      });
    });
  };

  _exports.invokePromiseReturningModel = invokePromiseReturningModel;
});