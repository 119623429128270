define("ember-cli-zuglet/-private/auth/methods/method/internal", ["exports", "ember-cli-zuglet/-private/internal/internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _internal.default.extend({
    methods: null,
    type: null,
    auth: Ember.computed.readOnly('methods.auth'),
    createModel: function createModel() {
      var type = this.get('type');
      return this.methods.factoryFor("zuglet:auth/method/".concat(type)).create({
        _internal: this
      });
    },
    withAuth: function withAuth(fn) {
      return this.get('auth').withAuth(fn);
    },
    withAuthReturningUser: function withAuthReturningUser(fn) {
      return this.get('auth').withAuthReturningUser(fn);
    }
  });

  _exports.default = _default;
});