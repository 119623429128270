define("ember-cli-zuglet/-private/auth/user/user", ["exports", "ember-cli-zuglet/-private/internal/model-mixin", "ember-cli-zuglet/-private/internal/invoke", "ember-cli-zuglet/-private/util/serialized"], function (_exports, _modelMixin, _invoke, _serialized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = ['uid', 'isAnonymous', 'displayName', 'email', 'emailVerified', 'phoneNumber', 'photoURL', 'providerId'];
  var UserPropertiesMixin = Ember.Mixin.create(keys.reduce(function (hash, key) {
    hash[key] = Ember.computed('_internal.user', function () {
      return this._internal.user[key];
    }).readOnly();
    return hash;
  }, {}));

  var _default = Ember.Object.extend(_modelMixin.default, UserPropertiesMixin, {
    token: (0, _invoke.invoke)('token'),
    delete: (0, _invoke.invokePromiseReturningUndefined)('delete'),
    link: (0, _invoke.invokePromiseReturningModel)('link'),
    serialized: (0, _serialized.default)(keys)
  });

  _exports.default = _default;
});