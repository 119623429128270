define("ember-cli-zuglet/-private/reference/document/document", ["exports", "ember-cli-zuglet/-private/reference/reference", "ember-cli-zuglet/-private/internal/invoke"], function (_exports, _reference, _invoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reference.default.extend({
    id: Ember.computed.readOnly('_internal.id'),
    path: Ember.computed.readOnly('_internal.path'),
    collection: (0, _invoke.invokeReturningModel)('collection'),
    doc: (0, _invoke.invokeReturningModel)('doc'),
    load: (0, _invoke.invokePromiseReturningModel)('load'),
    delete: (0, _invoke.invokePromiseReturningModel)('delete'),
    new: (0, _invoke.invokeReturningModel)('new'),
    existing: (0, _invoke.invokeReturningModel)('existing'),
    observe: (0, _invoke.invokeReturningModel)('observe')
  });

  _exports.default = _default;
});