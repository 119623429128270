define("design/mixins/child-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    withComponentOwner: function withComponentOwner(fn) {
      var owner = this.componentOwner;
      owner && !owner.isDestroying && fn(owner);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.withComponentOwner(function (owner) {
        return owner.registerChildComponent(_this);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.withComponentOwner(function (owner) {
          return owner.unregisterChildComponent(_this2);
        });
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});