define("ember-cli-sketch/-private/stage/cursor", ["exports", "ember-cli-sketch/-private/util/computed", "ember-cli-sketch/-private/util/math"], function (_exports, _computed, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var angles = Object.freeze({
    'top-left': -45,
    'top-middle': 0,
    'top-right': 45,
    'middle-right': -90,
    'middle-left': 90,
    'bottom-left': -135,
    'bottom-middle': 180,
    'bottom-right': 135
  });

  var _default = Ember.Object.extend({
    stage: null,
    _edge: (0, _computed.findBy)('stage.selection.attached', '_hasEdge', true),
    _rotation: Ember.computed.readOnly('_edge.edge.node.frame.rotation'),
    edge: Ember.computed('_edge.edge.serialized', '_rotation', function () {
      var edge = this.get('_edge.edge.serialized');

      if (!edge) {
        return;
      }

      var horizontal = edge.horizontal,
          vertical = edge.vertical;
      var id = "".concat(vertical, "-").concat(horizontal);
      var rad = (0, _math.degToRad)(this.get('_rotation')) + (0, _math.degToRad)(angles[id]);
      var rotation = ((0, _math.radToDeg)(rad) % 360 + 360) % 360;

      var inRange = function inRange(a, b) {
        return rotation >= a && rotation <= b;
      };

      if (inRange(315 + 22.5, 360) || inRange(0, 22.5)) {
        return 'ns-resize';
      } else if (inRange(45 - 22.5, 45 + 22.5)) {
        return 'nesw-resize';
      } else if (inRange(90 - 22.5, 90 + 22.5)) {
        return 'ew-resize';
      } else if (inRange(135 - 22.5, 135 + 22.5)) {
        return 'nwse-resize';
      } else if (inRange(180 - 22.5, 180 + 22.5)) {
        return 'ns-resize';
      } else if (inRange(225 - 22.5, 225 + 22.5)) {
        return 'nesw-resize';
      } else if (inRange(270 - 22.5, 270 + 22.5)) {
        return 'ew-resize';
      } else if (inRange(315 - 22.5, 315 + 22.5)) {
        return 'nwse-resize';
      }

      return 'cursor';
    }).readOnly(),
    tool: Ember.computed.readOnly('stage.tools.selected.cursor'),
    value: Ember.computed.or('tool', 'edge')
  });

  _exports.default = _default;
});