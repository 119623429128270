define("ember-cli-sketch/-private/stage/interactions/handlers", ["exports", "ember-cli-sketch/-private/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var all = function all() {
    return (0, _computed.factory)(function (factory, handlers) {
      return Ember.A(handlers.types.map(function (type) {
        return factory.interactionHandler(type, handlers);
      }));
    });
  };

  var _default = Ember.Object.extend({
    interactions: null,
    types: null,
    stage: Ember.computed.readOnly('interations.stage'),
    mouse: Ember.computed.readOnly('interactions.mouse'),
    keyboard: Ember.computed.readOnly('interactions.keyboard'),
    all: all(),
    onEvent: function onEvent(event) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.all.find(function (handler) {
        var _handler$event;

        var result = (_handler$event = handler[event]).call.apply(_handler$event, [handler].concat(args));

        if (result === false) {
          return true;
        }
      });
    }
  });

  _exports.default = _default;
});