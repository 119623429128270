define("ember-cli-zuglet/-private/util/random-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  var _default = function _default() {
    var len = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;
    var string = '';

    for (var i = 0; i < len; i++) {
      string += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return string;
  };

  _exports.default = _default;
});