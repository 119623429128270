define("design/components/ui-input/slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Joe7FJ1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"input\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"update\"],[[\"value\"],[\"target.value\"]]]],[11,\"min\",[22,\"min\"]],[11,\"max\",[22,\"max\"]],[11,\"step\",[22,\"step\"]],[11,\"value\",[22,\"value\"]],[10,\"type\",\"range\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-input/slider/template.hbs"
    }
  });

  _exports.default = _default;
});