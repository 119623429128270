define("ember-cli-sketch/-private/stage/tools/node/add", ["exports", "ember-cli-sketch/-private/stage/tools/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    resizing: false,
    model: null,
    delegate: null,
    guidelines: true,
    cursor: 'none',
    update: function update() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          delta = _ref.delta;

      var node = this.model.node,
          absolute = this.mouse.absolute,
          zoom = this.zoom;
      var parent = node.parent.frame.absolute;
      var frame = node.frame.properties;
      var x = frame.x,
          y = frame.y,
          width = frame.width,
          height = frame.height;

      if (delta) {
        width += delta.x / zoom;
        height += delta.y / zoom;
      } else {
        var calc = function calc(dimension, size) {
          return absolute[dimension] - parent[dimension] - frame[size] / 2;
        };

        x = calc('x', 'width');
        y = calc('y', 'height');
      }

      node.update({
        x: x,
        y: y,
        width: width,
        height: height,
        visible: true
      });
    },
    commit: function commit() {
      var model = this.model,
          node = this.model.node,
          delegate = this.delegate;
      node.perform('move-to-container');
      node.select();
      delegate.commit && delegate.commit(model);
      this.reset();
      this.done();
    },
    _resizing: function _resizing() {
      this.set('resizing', true);
      this.model.node.select();
    },
    onMouseDown: function onMouseDown(_ref2) {
      var button = _ref2.button;

      if (button !== 0) {
        this.done();
        return;
      }

      this._resizing();
    },
    onMouseMove: function onMouseMove(_ref3) {
      var delta = _ref3.delta;

      if (this.resizing) {
        this.update({
          delta: delta
        });
      } else {
        this.update();
      }
    },
    onMouseUp: function onMouseUp() {
      this.commit();
    },
    activate: function activate(_ref4) {
      var model = _ref4.model,
          delegate = _ref4.delegate;
      var stage = this.stage;
      stage.focus();
      stage.selection.reset();
      this.setProperties({
        model: model,
        delegate: delegate
      });
      this.update();
      model.node.hover();
    },
    deactivate: function deactivate() {
      var delegate = this.delegate,
          model = this.model;

      if (delegate) {
        model.node.deselect();
        delegate.cancel && delegate.cancel(model);
      }

      this.reset();
    },
    reset: function reset() {
      this.setProperties({
        model: null,
        delegate: null,
        resizing: false
      });
    }
  });

  _exports.default = _default;
});