define("design/components/ui-block/shape/component", ["exports", "design/components/ui-block/shape/template", "design/util/computed"], function (_exports, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var random = function random(min, max) {
    return Math.floor(min + Math.random() * max);
  };

  var circles = function circles(_ref) {
    var count = _ref.count;
    var array = [];

    for (var i = 0; i < count; i++) {
      var left = random(10, 80);
      var top = random(10, 80);
      var size = random(2, 4) * 25;
      var style = Ember.String.htmlSafe("left: ".concat(left, "%; top:").concat(top, "%; width: ").concat(size, "px; height: ").concat(size, "px"));
      array.push({
        left: left,
        top: top,
        size: size,
        style: style
      });
    }

    return array;
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-shape', '_type', '_skew'],
    layout: _template.default,
    _type: (0, _computed.className)({
      key: 'type',
      value: 'default'
    }),
    _skew: (0, _computed.className)({
      key: 'skew',
      value: 'default'
    }),
    count: 15,
    circles: Ember.computed('count', function () {
      var count = this.count;

      if (!count) {
        return;
      }

      return circles({
        count: count
      });
    })
  });

  _exports.default = _default;
});