define("ember-cli-zuglet/-private/storage/reference/base/internal", ["exports", "ember-cli-zuglet/-private/internal/internal", "ember-cli-zuglet/-private/queue/computed", "ember-cli-zuglet/-private/util/set-changed-properties"], function (_exports, _internal, _computed, _setChangedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.state = _exports.default = void 0;
  var state = ['isLoading', 'isLoaded', 'isError', 'exists', 'error'];
  _exports.state = state;

  var _default = _internal.default.extend({
    queue: (0, _computed.default)('serialized', 'ref.storage.queue'),
    isLoading: false,
    isLoaded: false,
    isError: false,
    exists: undefined,
    error: null,
    factoryFor: function factoryFor(name) {
      return this.ref.factoryFor(name);
    },
    shouldLoad: function shouldLoad() {
      var _this$getProperties = this.getProperties('isLoaded', 'isLoading'),
          isLoaded = _this$getProperties.isLoaded,
          isLoading = _this$getProperties.isLoading;

      if (isLoaded && !isLoading) {
        return false;
      }

      return true;
    },
    willLoad: function willLoad() {
      (0, _setChangedProperties.default)(this, {
        isLoading: true,
        isError: false,
        error: null
      });
    },
    onLoad: function onLoad(props) {
      (0, _setChangedProperties.default)(this, Ember.assign({
        isLoading: false,
        isLoaded: true,
        exists: true
      }, props));
    },
    onError: function onError(error, optional) {
      if (error.code === 'storage/object-not-found') {
        if (optional) {
          (0, _setChangedProperties.default)(this, {
            isLoading: false,
            isLoaded: true,
            exists: false
          });
          return;
        } else {
          (0, _setChangedProperties.default)(this, {
            isLoading: false,
            exists: false,
            isLoaded: true,
            isError: true,
            error: error
          });
        }
      } else {
        (0, _setChangedProperties.default)(this, {
          isLoading: false,
          isError: true,
          error: error
        });
      }

      return Ember.RSVP.reject(error);
    },
    loadDidFail: function loadDidFail(error, opts) {
      return this.onError(error, opts.optional);
    },
    onDeleted: function onDeleted(props) {
      (0, _setChangedProperties.default)(this, Ember.assign({
        isLoading: false,
        isLoaded: false,
        isError: false,
        exists: undefined,
        error: null
      }, props));
    }
  });

  _exports.default = _default;
});