define("design/components/ui-block/tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "am12ITi7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tabs\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"tab\"],[[28,\"component\",[\"ui-block/tabs/tab\"],[[\"selected\",\"select\"],[[24,[\"selected\"]],[24,[\"select\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"tab\"],[[28,\"component\",[\"ui-block/tabs/content\"],[[\"selected\",\"select\"],[[24,[\"selected\"]],[24,[\"select\"]]]]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design/components/ui-block/tabs/template.hbs"
    }
  });

  _exports.default = _default;
});